import * as actionTypes from 'redux/actionTypes'

const initialState = { showLoginForm: false, error: null, message: '' }

export default function loginFormReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_LOGIN_FORM:
      return { ...state, ...action.payload }
    case actionTypes.SET_ERROR_LOGIN_FORM:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
