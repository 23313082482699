import { message } from 'antd'
import { useQuery } from 'react-query'

import { getFinanceLogs } from 'utils/apis'

export const useFinanceLogs = ({ options = {} } = {}) => {
  return useQuery(
    ['financeLogs'],
    async () => {
      const {
        data: { data },
      } = await getFinanceLogs()
      return data
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}
