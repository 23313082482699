import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { RollbackOutlined } from '@ant-design/icons'

function Unarchive({ title, ...props }) {
  const { formatMessage } = useIntl()
  return (
    <Button disabled={props.loading} {...props}>
      {title || formatMessage({ id: 'financeProduct.unarchive' })}
    </Button>
  )
}

Unarchive.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Unarchive.defaultProps = {
  type: 'default',
  icon: <RollbackOutlined />,
  onClick: () => {},
  id: 'btn-delete',
}

export default Unarchive
