import React from 'react'
import { Dropdown } from 'antd'
import { injectIntl } from 'react-intl'
import {
  DownOutlined,
  SolutionOutlined,
  PlayCircleOutlined,
  BookOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

const HelpDropdown = ({
  intl,
  onClickVideo,
  onClickArticle,
  onClickTour,
  isMobileView,
  ...props
}) => {
  const [visible, setVisible] = React.useState(false)

  const visibleChangeHandler = (flag) => setVisible(flag)
  const toggleHandler = () => setVisible(!visible)
  const clickVideoHandler = () => {
    setVisible(false)
    if (typeof onClickVideo === 'function') {
      onClickVideo()
    }
  }
  const clickTourHandler = () => {
    setVisible(false)
    if (typeof onClickTour === 'function') {
      onClickTour()
    }
  }
  const clickArticleHandler = () => {
    setVisible(false)
    if (typeof onClickArticle === 'function') {
      onClickArticle()
    }
  }

  const items = []
  if (typeof onClickTour === 'function') {
    items.push({
      key: '1',
      icon: <SolutionOutlined />,
      onClick: clickTourHandler,
      label: intl.formatMessage({ id: 'button.tour' }),
    })
  }
  if (typeof onClickVideo === 'function') {
    items.push({
      key: '2',
      icon: <PlayCircleOutlined />,
      onClick: clickVideoHandler,
      label: intl.formatMessage({ id: 'button.video' }),
    })
  }
  if (typeof onClickArticle === 'function') {
    items.push({
      key: '3',
      icon: <BookOutlined />,
      onClick: clickArticleHandler,
      label: intl.formatMessage({ id: 'button.article' }),
    })
  }

  const menu = items
  return (
    <Dropdown.Button
      icon={isMobileView ? <QuestionCircleOutlined /> : <DownOutlined />}
      menu={{items: menu}}
      trigger={['click']}
      onOpenChange={visibleChangeHandler}
      onClick={toggleHandler}
      open={visible}
      {...props}
    >
      {!isMobileView && (
        <>
          <QuestionCircleOutlined /> {intl.formatMessage({ id: 'button.guide' })}
        </>
      )}
    </Dropdown.Button>
  )
}

const Component = injectIntl(HelpDropdown)
export default React.memo(Component)
