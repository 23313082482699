import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {
  onboarding_checklist: null,
}

const setOnboardingChecklist = (state, payload) => {
  return updateObject(state, {
    onboarding_checklist: payload,
  })
}

export default function onboardingChecklistReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ONBOARDING_CHECKLIST:
      return setOnboardingChecklist(state, action.payload)
    default:
      return state
  }
}
