import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { transType } from 'utils/static'

export const getUrl = (
  transTypeId,
  data,
  permissions = [],
  isCashBank = false,
  force = false,
  props,
) => {
  let url = ''
  let name = ''
  if (!isCashBank) {
    if (transTypeId === transType.REPORT_SALES_PER_SALES_PERSON) {
      url = '/reports/sales-per-sales-person'
      name = 'report_sales_per_sales_person'
    }
    if (transTypeId === transType.CLOSING) {
      if (data.id) {
        url = `/closing/detail/${data.id}`
      } else {
        url = `/closing`
      }
      name = 'accounts'
    }
    if (transTypeId === transType.QUOTE) {
      if (data.id) {
        url = `/sales/quotes/detail/${data.id}`
      } else {
        url = `/sales/quotes`
      }
      name = 'quotes'
    }
    if (transTypeId === transType.PURCHASE_ORDER) {
      if (data.id) {
        url = `/purchases/purchaseOrders/detail/${data.id}`
      } else {
        url = `/purchases/purchaseOrders`
      }
      name = 'purchase_orders'
    }
    if (transTypeId > transType.INVOICE && transTypeId < transType.REVERSAL) {
      if (data.id) {
        url = `/banks/${data.accountId}/transaction/detail/${data.id}`
      } else {
        url = `/banks/detail/${data.accountId}/transactions`
      }
      name = 'cash_bank'
    }
    if (transTypeId === transType.ORDER) {
      if (data.id) {
        url = `/sales/orders/detail/${data.id}`
      } else {
        url = `/sales/orders`
      }
      name = 'orders'
    }
    if (transTypeId === transType.DELIVERY) {
      if (data.id) {
        url = `/sales/deliveries/detail/${data.id}`
      } else {
        url = `/sales/deliveries`
      }
      name = 'deliveries'
    }
    if (transTypeId === transType.EXPENSE) {
      if (data.id) {
        url = `/expenses/detail/${data.id}`
      } else {
        url = `/expenses`
      }
      name = 'expenses'
    }
    if (transTypeId === transType.PURCHASE_ORDER) {
      if (data.id) {
        url = `/purchases/purchaseorders/detail/${data.id}`
      } else {
        url = `/purchases/purchaseorders`
      }
      name = 'purchase_orders'
    }
    if (transTypeId === transType.PURCHASE_INVOICE) {
      if (data.id) {
        url = `/purchases/purchaseinvoices/detail/${data.id}`
      } else {
        url = `/purchases/purchaseinvoices`
      }
      name = 'purchase_invoices'
    }
    if (transTypeId === transType.PURCHASE_QUOTE) {
      if (data.id) {
        url = `/purchases/quotes/detail/${data.id}`
      } else {
        url = `/purchases/quotes`
      }
      name = 'purchase_quotes'
    }
    if (transTypeId === transType.PURCHASE_DELIVERY) {
      if (data.id) {
        url = `/purchases/deliveries/detail/${data.id}`
      } else {
        url = `/purchases/deliveries`
      }
      name = 'purchase_deliveries'
    }
    if (transTypeId === transType.INVOICE) {
      if (data.id) {
        url = `/sales/invoices/detail/${data.id}`
      } else {
        url = `/sales/invoices`
      }
      name = 'invoices'
    }
    if (transTypeId === transType.REVERSAL) {
      if (data.id) {
        url = `/accounts/conversionbalances/reversal_detail/${data.id}`
      } else {
        url = `/accounts/conversionbalances`
      }
      name = 'conversion_balance'
    }
    if (transTypeId === transType.OPENING_BALANCE) {
      if (data.id) {
        url = `/accounts/conversionbalances/opening_balance/${data.id}`
      } else {
        url = `/accounts/conversionbalances`
      }
      name = 'conversion_balances'
    }
    if (transTypeId === transType.MANUAL_JOURNAL) {
      if (data.id) {
        url = `/manual-journal/detail/${data.id}`
      } else {
        url = `/manual-journal`
      }
      name = 'manual_journal'
    }
    if (
      transTypeId === transType.CREATE_FIXED_ASSET ||
      transTypeId === transType.INITIAL_DEPRECIATION ||
      transTypeId === transType.DEPRECIATION ||
      transTypeId === transType.DISPOSAL
    ) {
      if (data.id) {
        url = `/fixed-assets/transaction/${data.id}`
      } else {
        url = `/fixed-assets`
      }
      name = 'fixed_assets'
    }
    if (transTypeId === transType.WAREHOUSE_TRANSFER) {
      if (data.id) {
        url = `/warehouses/${data.warehouseId}/transfer/detail/${data.id}`
      } else {
        url = `/warehouses/detail/${data.warehouseId}`
      }
      name = 'warehouse_transfer'
    }
    if (transTypeId === transType.SEND_TRANSFER) {
      if (data.id) {
        url = `/banks/${data.bank_account_id}/transaction/detail/${data.id}`
      } else {
        url = `/banks/detail/${data.bank_account_id}/transactions`
      }
      name = 'cash_bank'
    }
    if (transTypeId === transType.USER) {
      url = `/settings/users`
      name = 'users'
    }
    if (transTypeId === transType.TRANSLATION) {
      url = `/settings/translation`
      name = 'translation'
    }
    if (transTypeId === transType.ROLE) {
      if (data.id) {
        url = `/settings/roles/edit/${data.id}`
        name = 'roles_edit'
      } else {
        url = '/settings/roles'
        name = 'roles'
      }
    }
    if (transTypeId === transType.OPTION) {
      url = `/settings/company`
      name = 'company'
    }
    if (transTypeId === transType.STOCK_ADJUSTMENT) {
      if (data.id) {
        url = `/warehouses/${data.warehouseId}/stock-adjustment/detail/${data.id}`
      } else {
        url = `/warehouses/detail/${data.warehouseId}`
      }
      name = 'stock_adjustment'
    }
    if (transTypeId === transType.TERM) {
      url = `/settings/expenses/terms`
      name = 'terms'
    }
    if (transTypeId === transType.TAX) {
      url = `/settings/taxes`
      name = 'taxes'
    }
    if (transTypeId === transType.WAREHOUSE) {
      if (data.id) {
        if (
          props?.warehouses_has_role?.find(
            (warehouse) => warehouse.id.toString() === data.id.toString(),
          )
        ) {
          url = `/warehouses/detail/${data.id}`
          name = 'inventory'
        }
      } else {
        url = `/warehouses`
        name = 'inventory'
      }
    }
    if (transTypeId === transType.PRODUCT) {
      if (data.id) {
        url = `/products/detail/${data.id}`
      } else {
        url = `/products`
      }
      name = 'products'
    }
    if (transTypeId === transType.NUMBER) {
      url = `/settings/automatic-sequencing`
      name = 'automatic_sequencing'
    }
    if (transTypeId === transType.LAYOUT_INVOICE) {
      url = `/settings/layout-invoice`
      name = 'layout_invoice'
    }
    if (transTypeId === transType.FIXED_ASSET) {
      if (data.id) {
        url = `/fixed-assets/detail/${data.id}`
      } else {
        url = `/fixed-assets`
      }
      name = 'fixed_assets'
    }
    if (transTypeId === transType.CONVERSION_BALANCE) {
      url = `/accounts/conversionbalances`
      name = 'conversion_balances'
    }
    if (transTypeId === transType.CONTACT) {
      url = `/contacts`
      if (data.isDetail) {
        url = `/contacts/detail/${data.id}`
      }
      name = 'contacts'
    }
    if (transTypeId === transType.ACCOUNT) {
      url = `/reports/account-transaction/${data.userId}`
      name = 'reports'
    }
    if (transTypeId === transType.BILLING) {
      url = `/settings/billing`
      name = 'billing'
    }
    if (
      transTypeId === transType.CREDIT_MEMO_PAYMENT ||
      transTypeId === transType.RECEIVE_SALES_ORDER_PAYMENT ||
      transTypeId === transType.DEBIT_MEMO_PAYMENT ||
      transTypeId === transType.PURCHASE_ORDER_PAYMENT ||
      transTypeId === transType.BANK_DEBIT_MEMO ||
      transTypeId === transType.BANK_CREDIT_MEMO
    ) {
      if (data.id) {
        url = `/banks/${data.accountId}/transaction/detail/${data.id}`
      } else {
        url = `/banks/detail/${data.accountId}/transactions`
      }
      name = 'cash_bank'
    }
    if (transTypeId === transType.CREDIT_MEMO) {
      if (data.id) {
        url = `/credit-memo/detail/${data.id}`
      } else {
        url = `/contacts`
      }
      name = 'contacts'
    }
    if (transTypeId === transType.DEBIT_MEMO) {
      if (data.id) {
        url = `/debit-memo/detail/${data.id}`
      } else {
        url = `/contacts`
      }
      name = 'contacts'
    }
    if (transTypeId === transType.RETURN) {
      url = `/sales/returns/detail/${data.id}`
      name = 'invoices'
    }
    if (transTypeId === transType.PURCHASE_RETURN) {
      url = `/purchases/returns/detail/${data.id}`
      name = 'purchase_invoices'
    }
    if (transTypeId === transType.PRODUCT_CONVERSION) {
      url = `/products/conversions/detail/${data.id}`
      name = 'products'
    }
    if (transTypeId === transType.ORDER) {
      if (data.id) {
        url = `/sales/orders/detail/${data.id}`
      } else {
        url = `/sales/orders`
      }
      name = 'orders'
    }
    if (transTypeId === transType.RECURRING_INVOICE) {
      url = `/sales/invoices/recurring/edit/${data.id}?transTypeId=${transType.INVOICE}`
      name = 'invoices'
    }
    if (transTypeId === transType.RECURRING_ORDER) {
      url = `/sales/orders/recurring/edit/${data.id}?transTypeId=${transType.ORDER}`
      name = 'orders'
    }
    if (transTypeId === transType.RECURRING_PURCHASEINVOICE) {
      url = `/purchases/purchaseinvoices/recurring/edit/${data.id}?transTypeId=${transType.PURCHASE_INVOICE}`
      name = 'purchase_invoices'
    }
    if (transTypeId === transType.RECURRING_PURCHASEORDER) {
      url = `/purchases/purchaseorders/recurring/edit/${data.id}?transTypeId=${transType.PURCHASE_ORDER}`
      name = 'purchase_orders'
    }
    if (transTypeId === transType.RECURRING_EXPENSE) {
      url = `/expenses/recurring/edit/${data.id}?transTypeId=${transType.EXPENSE}`
      name = 'expenses'
    }
    if (transTypeId === transType.RECURRING_MANUALJOURNAL) {
      url = `/manual-journal/recurring/edit/${data.id}?transTypeId=${transType.MANUAL_JOURNAL}`
      name = 'manual_journal'
    }
    if (transTypeId === transType.RECURRING_CASHBANK) {
      url = `/banks/${data.accountId}/recurring/edit/${data.id}?transTypeId=${transType.CASH_BANK}`
      name = 'cash_bank'
    }
    if (transTypeId === transType.TAG) {
      if (data.id) {
        url = `/settings/tags/detail/${data.id}`
      } else {
        url = `/settings/tags`
      }
      name = 'tags'
    }
    if (transTypeId === transType.INVOICE_PAYMENT_CREDIT_MEMO) {
      if (data.id) {
        url = `/invoice-payment-credit-memo/detail/${data.id}`
      }
    }
    if (transTypeId === transType.PURCHASE_INVOICE_PAYMENT_DEBIT_MEMO) {
      if (data.id) {
        url = `/purchaseinvoice-payment-debit-memo/detail/${data.id}`
      }
    }
    if (transTypeId === transType.UNREALIZED_GAIN) {
      if (data.id) {
        url = `/unrealized-gain/detail/${data.id}`
      }
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (transTypeId === transType.EXPENSE_PAYMENT) {
      if (data.id) {
        url = `/expenses/detail/${data.id}`
      } else {
        url = `/expenses`
      }
      name = 'expenses'
    } else if (
      transTypeId === transType.PURCHASE_PAYMENT ||
      transTypeId === transType.PURCHASE_INVOICE_WITHOLDING ||
      transTypeId === transType.PURCHASE_SHIPPING_COSTS
    ) {
      if (data.id) {
        url = `/purchases/purchaseinvoices/detail/${data.id}`
      } else {
        url = `/purchases/purchaseinvoices`
      }
      name = 'purchase_invoices'
    } else if (transTypeId === transType.PURCHASE_ORDER_PAYMENT) {
      if (data.id) {
        url = `/purchases/purchaseorders/detail/${data.id}`
      } else {
        url = `/purchases/purchaseorders`
      }
      name = 'purchase_orders'
    } else if (
      transTypeId === transType.RECEIVE_INVOICE_PAYMENT ||
      transTypeId === transType.INVOICE_WITHOLDING ||
      transTypeId === transType.SALES_SHIPPING_COSTS
    ) {
      if (data.id) {
        url = `/sales/invoices/detail/${data.id}`
      } else {
        url = `/sales/invoices`
      }
      name = 'invoices'
    } else if (
      transTypeId === transType.RECEIVE_MONEY ||
      transTypeId === transType.SPEND_MONEY ||
      transTypeId === transType.EXPENSE ||
      transTypeId === transType.MANUAL_JOURNAL ||
      transTypeId === transType.PRODUCT
    ) {
      url = `/reports/account-transaction/${data.id}?date_from=${data.trans_date}&date_to=${
        data.date_to || data.trans_date
      }`
      name = 'reports'
    } else if (transTypeId === transType.REGISTER_FIXED_ASSET) {
      if (data.id) {
        url = `/fixed-assets/detail/${data.id}`
      } else {
        url = `/fixed-assets`
      }
      name = 'fixed_assets'
    } else if (
      transTypeId === transType.CREDIT_MEMO_PAYMENT ||
      transTypeId === transType.BANK_CREDIT_MEMO
    ) {
      if (data.id) {
        url = `/credit-memo/detail/${data.id}`
      } else {
        url = `/contacts`
      }
      name = 'contacts'
    } else if (
      transTypeId === transType.DEBIT_MEMO_PAYMENT ||
      transTypeId === transType.BANK_DEBIT_MEMO
    ) {
      if (data.id) {
        url = `/debit-memo/detail/${data.id}`
      } else {
        url = `/contacts`
      }
      name = 'contacts'
    } else if (transTypeId === transType.RECEIVE_SALES_ORDER_PAYMENT) {
      if (data.id) {
        url = `/sales/orders/detail/${data.id}`
      } else {
        url = `/sales/orders`
      }
      name = 'orders'
    }
  }

  if (name) {
    const findPermissionRecursive = (row) => {
      return row.child.find((child) => {
        if (child.name === name) return child.name === name
        if (_.isArray(child.child)) return findPermissionRecursive(child)
        return false
      })
    }

    const findPermission = permissions.find((row) => {
      if (row.name === name) return row.name === name
      if (_.isArray(row.child) && props?.recursive) return findPermissionRecursive(row)
      return false
    })

    if (!findPermission && !force) url = ''
  }

  if (url) return `${url}${props?.suffixUrl ?? ''}`

  return url
}

function LinkTransType({
  title,
  transTypeId,
  data,
  innerRef,
  permissions,
  isCashBank = false,
  goTo,
  force,
  ...props
}) {
  const uri = getUrl(transTypeId, data, permissions, isCashBank, force, props)
  let currentTitle = title

  if (typeof title === 'function') {
    currentTitle = title()
  }

  if (uri === '') {
    return <span>{currentTitle}</span>
  }

  const { warehouses_has_role, suffixUrl, recursive, ...spreadProps } = props

  // console.log(currentTitle)
  return (
    <Link className="text-primary" ref={innerRef} to={uri} {...spreadProps}>
      {currentTitle}
    </Link>
  )
}

LinkTransType.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.node.isRequired,
  transTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // eslint-disable-next-line react/require-default-props
  isCashBank: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const {
    warehouse: { warehouses_has_role: warehousesRole },
  } = state.finance
  return {
    permissions: state.user.permissions || [],
    warehouses_has_role: warehousesRole || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    goTo: (path) => dispatch(push(path)),
  }
}

const Component = React.memo(connect(mapStateToProps, mapDispatchToProps)(LinkTransType))

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
