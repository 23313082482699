import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Modal, Typography } from 'antd'
import { UpCircleOutlined } from '@ant-design/icons'
import { Cancel, Upgrade } from 'components/UI'
import { isFeatureChampion, isFeatureElite } from 'utils/helper'
import { billingPosSelector, billingSelector } from 'redux/selectors'
import useMemoizeCheckPermission from 'utils/hooks/useMemoizeCheckPermission'
import './style.scss'

const UpgradeBilling = ({ label, isPos }) => {
  const { formatMessage } = useIntl()

  const isHasAccess = useMemoizeCheckPermission({ permission: 'billing' })
  const dispatch = useDispatch()

  const onPush = (path) => dispatch(push(path))

  return (
    <div className="text-center text-wrapper">
      <Typography.Title level={5} className="text-center mb-3">
        {label}
      </Typography.Title>
      {isPos && (
        <Cancel
          title={formatMessage({ id: 'billing.renew_pos' })}
          onClick={() => onPush('/settings/billing/pos?isRenew=1')}
          icon={<UpCircleOutlined />}
        />
      )}
      {isHasAccess && !isPos && (
        <Upgrade
          title={formatMessage({ id: 'billing.upgrade_now' })}
          onClick={() => onPush('/settings/billing/upgrade')}
          className="ml-3"
        />
      )}
    </div>
  )
}

// Disable hidden feature
const HideFeature = (props) => {
  const {
    code = '',
    feature = '',
    currentCount,
    maxCode,
    maxFeature = '',
    forceHiddenFeature,
    isFeatureOnlyAccess,
    asModal,
    modalProps = {},
    children = null,
    center,
    isPos,
    byPassCanBeAddoned = false, // Sementara untuk kebutuhan POS
  } = props
  const { formatMessage } = useIntl()
  const billing = useSelector(billingSelector)
  const billingPos = useSelector(billingPosSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isPos && billingPos?.status?.id === 0) {
      dispatch(push('/pos'))
    }
  }, [billingPos, dispatch, isPos])

  if (forceHiddenFeature) return children

  const canBeAdOned = byPassCanBeAddoned || billing.can_be_addoned

  let label = formatMessage(
    {
      id: isFeatureOnlyAccess
        ? 'billing.feature_can_only_be_use_on_pro_plan'
        : 'billing.feature_available_in_pro_plan',
    },
    { feature },
  )
  let modalTitle = formatMessage({ id: 'billing.pro_plan_features' })

  if (_.isEmpty(billing)) return children

  if (canBeAdOned && maxCode && billing[maxCode] <= currentCount) {
    label = formatMessage(
      { id: 'billing.quota_is_exhausted_add_an_addon' },
      { feature: maxFeature },
    )
  } else if (billing.hidden_feature_trial?.includes(code)) {
    label = formatMessage({ id: 'billing.feature_can_only_be_accessed_on_paid_plan' }, { feature })
    modalTitle = formatMessage({ id: 'billing.paid_plan_features' })
  } else if (isFeatureChampion({ billing, code })) {
    label = formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_champion_plan'
          : 'billing.feature_available_in_champion_plan',
      },
      { feature },
    )
    modalTitle = formatMessage({ id: 'billing.champion_plan_features' })
  } else if (isFeatureElite({ billing, code })) {
    label = formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_elite_plan'
          : 'billing.feature_available_in_elite_plan',
      },
      { feature },
    )
    modalTitle = formatMessage({ id: 'billing.elite_plan_features' })
  } else if (billing.hidden_feature?.filter((e) => e === code).length === 0) {
    return children
  }

  if (asModal) {
    return (
      <Modal
        title={modalTitle}
        footer={[<Cancel key={0} onClick={() => modalProps?.onCancel?.()} />]}
        {...modalProps}
      >
        <UpgradeBilling label={label} />
      </Modal>
    )
  }

  return (
    <div className="hide-feature-container">
      {children}
      <div className={`wrapper ${center && 'wrapper-center'}`}>
        <UpgradeBilling label={label} />
      </div>
    </div>
  )
}

export default HideFeature
