import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import {
  deleteFinanceNumberTrans,
  getFinanceNumberDetailLog,
  getFinanceNumberNext,
  getFinanceNumberTrans,
} from 'utils/apis'

export function useNumberNext(payload, options = {}) {
  const { name, ...restPayload } = payload
  return useQuery(
    ['numberNext', payload],
    async () => {
      let response
      try {
        const { data } = await getFinanceNumberNext(name, restPayload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!name,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useFinanceNumberTrans({ options = {} } = {}) {
  return useQuery(
    ['financeNumberTrans'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceNumberTrans()
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useDeleteFinanceNumberTrans = ({ options = {} } = {}) => {
  return useMutation((payload) => deleteFinanceNumberTrans(payload), options)
}

export function useFinanceNumberDetailLog({
  options = {},
  payload = {},
  justGetOnce = false,
} = {}) {
  return useQuery(
    ['financeNumberDetailLog', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceNumberDetailLog(payload.id, payload.params, justGetOnce)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}
