import React, { useMemo } from 'react'
import _ from 'lodash'

import { qtyToCurrency } from 'utils/formatting'
import { isValidJson } from 'utils/helper'
import styles from './changes.module.scss'

const ChangeItems = ({ data, isChildren }) => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const values = Object.values(data)

  return values.map((item, index) => {
    const isLastItem = index === values.length - 1
    return (
      <small
        key={item.label}
        className={[styles.changeItem, !isChildren ? 'd-block' : ''].join(' ')}
      >
        <span>{[]}</span>
        {item.label}:{' '}
        <span className={styles.changeBefore}>
          {_.isObject(item.before) ? JSON.stringify(item.before) : item.before}
        </span>{' '}
        {item.children ? (
          <>
            ( <ChangeItems data={item.children} isChildren /> )
          </>
        ) : (
          <>
            <span className={styles.changeArrow}>&rarr;</span>{' '}
            <span>
              {_.isObject(item.after) ? JSON.stringify(item.after) : item.after}
              {isChildren && !isLastItem && ', '}
            </span>
          </>
        )}
      </small>
    )
  })
}

const Changes = ({ changes, desc }) => {
  const formattedData = useMemo(() => {
    if (!changes || !isValidJson(changes)) {
      return []
    }

    const parsedData = JSON.parse(changes)

    const newData = {}

    Object.entries(parsedData).forEach(([key, value]) => {
      const before = typeof value.before === 'number' ? qtyToCurrency(value.before) : value.before
      const after = typeof value.after === 'number' ? qtyToCurrency(value.after) : value.after

      if (/^items_line_#(\d+)_/.test(key)) {
        const [, numberLine, fieldName] = key.split(/^items_line_#(\d+)_/)
        const dataKey = `items_line_#${numberLine}`

        if (newData[dataKey]?.children) {
          newData[dataKey].children[fieldName] = {
            label: fieldName,
            before,
            after,
          }
        } else {
          newData[dataKey] = {
            label: `Line#${numberLine} ${desc.action}`,
            children: {
              [fieldName]: {
                label: fieldName,
                before,
                after,
              },
            },
          }
        }

        return
      }
      newData[key] = {
        label: key,
        before,
        after,
      }
    })

    return newData
  }, [changes, desc])

  if (!changes) return null

  return (
    <>
      <span className="d-block">Detail</span>
      <ChangeItems data={formattedData} />
    </>
  )
}

export default Changes
