import axios from "axios";

export const getOnboarding = () => {
  return axios.get('/onboardings')
}

export const getOnboardingStatus = () => {
  return axios.get('/onboardings/status')
}

export const updateOnboardingStatus = (id) => {
  return axios.post(`onboardings/${id}/check`)
}
