import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import { getBackupDatabase, prepareBackupDatabase } from 'utils/apis'

export const useBackupDatabase = ({ options = {} }) => {
  return useQuery(
    [`backupDatabase`],
    async () => {
      const {
        data: { data },
      } = await getBackupDatabase()
      return data
    },
    {
      onError: (error) => message.error((error && error.message) || 'Failed data from server!'),
      ...options,
    },
  )
}

export const usePrepareBackupDatabase = () => {
  return useMutation((payload) => prepareBackupDatabase(payload))
}
