import axios from 'axios'
import { stringify } from 'query-string'

// export const getCreditMemos = (params) => {
//   const query = stringify(params)
//   return getOnce(`/finance/creditMemos?${query}`)
// }

export const addCreditMemo = (data) => {
  return axios.post('/finance/creditMemos', data)
}

export const editCreditMemo = (data) => {
  return axios.put(`/finance/creditMemos/${data.id}`, data)
}

export const deleteOnAddCreditMemoAttachment = (url) => {
  return axios.delete('/finance/creditMemos/attachments', { data: { url } })
}

export const deleteOnEditCreditMemoAttachment = (id, url) => {
  return axios.delete(`/finance/creditMemos/${id}/attachments`, { data: { url } })
}

export const getCreditMemo = (id) => {
  return axios.get(`/finance/creditMemos/${id}`)
}

export const deleteCreditMemo = (id) => {
  return axios.delete(`/finance/creditMemos/${id}`)
}

export const getCreditMemoLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/creditMemos/${id}/logs${query}`)
}
