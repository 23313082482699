import theme from './theme'

export const styledComponentsTheme = {
  ...theme,
  responsive: {
    xxlMinWidth: '1600px',
    xxlMaxWidth: '1599px',
    xlMinWidth: '1200px',
    xlMaxWidth: '1199px',
    mdMinWidth: '992px',
    mdMaxWidth: '991px',
    smMinWidth: '768px',
    smMaxWidth: '767px',
    xsMinWidth: '576px',
    xsMaxWidth: '575px',
  },
}
