import axios from 'axios'

export const getFinancePaymentConnectSetting = (id) => {
  return axios.get(`/finance/paymentConnectSetting/${id}`)
}

export const testConnectionFinancePaymentConnectSetting = (payload) => {
  return axios.post('/finance/paymentConnectSetting/testConnection', payload)
}

export const updatePaymentConnectSetting = (payload) => {
  return axios.put('/finance/paymentConnectSetting', payload)
}

export const setDefaultPaymentConnectSetting = (id, data) => {
  return axios.post(`/finance/paymentConnectSetting/${id}/default`, data)
}
