import axios from 'axios'
import { stringify } from 'query-string'

export const getFaqsByName = (name) => axios.get(`/faqs/${name}`)

export const getFaqCategory = () => axios.get('/faqs/category')

export const getFaqs = (payload) => {
  const query = stringify(payload)
  return axios.get(`/faqs?${query}`)
}

export const getFaqDetail = (id) => axios.get(`/faqs/${id}/detail`)

export const likeFaq = (id) => axios.post(`/faqs/${id}/like`)

export const dislikeFaq = (id) => axios.post(`/faqs/${id}/dislike`)
