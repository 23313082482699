import { ADD_OUTLET, DELETE_OUTLET, EDIT_OUTLET, SET_OUTLETS } from 'redux/actionTypes'

export const setOutlets = (payload) => {
  return {
    type: SET_OUTLETS,
    payload,
  }
}

export const addOutlet = (payload) => {
  return {
    type: ADD_OUTLET,
    payload,
  }
}

export const editOutlet = (payload) => {
  return {
    type: EDIT_OUTLET,
    payload,
  }
}

export const deleteOutlet = (payload) => {
  return {
    type: DELETE_OUTLET,
    payload,
  }
}
