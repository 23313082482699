import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'
import {
  addPurchaseInvoicePaymentDebitMemo,
  getPurchaseInvoicePaymentDebitMemo,
  editPurchaseInvoicePaymentDebitMemo,
  deletePurchaseInvoicePaymentDebitMemo,
} from 'utils/apis/purchaseInvoicePaymentDebitMemo'

export function usePurchaseInvoicePaymentDebitMemo({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['purchaseInvoicePaymentDebitMemo', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getPurchaseInvoicePaymentDebitMemo(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export const useEditPurchaseInvoicePaymentDebitMemo = () => {
  return useMutation((payload) => editPurchaseInvoicePaymentDebitMemo(payload))
}

export const useDeletePurchaseInvoicePaymentDebitMemo = () => {
  return useMutation((payload) => deletePurchaseInvoicePaymentDebitMemo(payload.id))
}

export const useAddPurchaseInvoicePaymentDebitMemo = () => {
  return useMutation((payload) => addPurchaseInvoicePaymentDebitMemo(payload))
}
