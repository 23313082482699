import axios from 'axios'
import { stringify } from 'query-string'

import { getOnce } from 'utils/helper'

export const getNews = (params) => {
  const query = stringify(params)
  return getOnce(`/notification/news?${query}`)
}

export const markAsRead = (id) => {
  return axios.put(`/notification/broadcast/${id}/markAsRead`)
}
