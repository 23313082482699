import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { injectIntl } from 'react-intl'
import { DatePicker as DateAntd } from 'antd'
import useFormatting from 'utils/hooks/useFormatting'

const DatePicker = ({ intl, ...props }) => {
  const { formatDateText } = useFormatting()
  const presets = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'date.today' }),
        value: dayjs(),
      },
      {
        label: intl.formatMessage({ id: 'date.yesterday' }),
        value: dayjs().add(-1, 'd'),
      },
      {
        label: intl.formatMessage({ id: 'date.last_week' }),
        value: dayjs().add(-7, 'd'),
      },
      {
        label: intl.formatMessage({ id: 'date.last_month' }),
        value: dayjs().add(-1, 'month'),
      },
      {
        label: intl.formatMessage({ id: 'date.last_year' }),
        value: dayjs().add(-1, 'year'),
      },
    ]
  }, [intl])

  return <DateAntd format={formatDateText} presets={presets} {...props} />
}

export default injectIntl(DatePicker)
