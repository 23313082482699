import { appMessage } from 'utils/formatting';

const routes = [
  { path: '/banks/detail/:id/transactions', breadcrumb: appMessage('transactions')},
  { path: '/banks/detail/:id/bankstatements', breadcrumb: appMessage('bankstatements')},
  { path: '/banks/detail/:id/reconcile', breadcrumb: appMessage('reconcile')},
  { path: '/banks/detail/:id/waiting_approval', breadcrumb: appMessage('approvals.waiting_for_approval')},
  { path: '/banks/detail/:id/rejected', breadcrumb: appMessage('approvals.rejected')},
];

export default routes;
