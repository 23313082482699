import { store } from 'index'

const flowMenus = [
  { menuName: 'deliveries', optionName: 'invoice_delivery' },
  { menuName: 'orders', optionName: 'invoice_order' },
  { menuName: 'quotes', optionName: 'invoice_quote' },
  { menuName: 'purchase_deliveries', optionName: 'purchase_delivery' },
  { menuName: 'purchase_orders', optionName: 'purchase_order' },
  { menuName: 'purchase_quotes', optionName: 'purchase_quote' },
]
export const checkAlurFlow = (name) => {
  const { options } = store.getState().options
  const findMenu = flowMenus.find((menu) => menu.menuName === name)
  let isShow = true
  if (findMenu) {
    isShow = false
    if (options && options[findMenu.optionName]) {
      if (options[findMenu.optionName]) {
        isShow = true
      }
    }
  }

  return isShow
}
