import * as api from 'utils/apis/financeUnit'
import * as actionTypes from 'redux/actionTypes'

export const getCanDeletedUnitsRedux = (payload) => {
  return {
    type: actionTypes.GET_CAN_DELETED_FINANCE_UNIT,
    payload,
  }
}

export const getCanDeletedUnits = () => {
  return (dispatch) => {
    return api.getCanDeletedFinanceUnit().then((response) => {
      dispatch(getCanDeletedUnitsRedux(response.data.data))
      return response
    })
  }
}

export const setFinanceUnits = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_UNITS,
    payload,
  }
}

export const addFinanceUnitRedux = (payload) => {
  return {
    type: actionTypes.ADD_FINANCE_UNIT,
    payload,
  }
}

export const editFinanceUnitRedux = (payload) => {
  return {
    type: actionTypes.EDIT_FINANCE_UNIT,
    payload,
  }
}

export const addFinanceUnit = (payload) => {
  return (dispatch) => {
    return api.addFinanceUnit(payload).then((response) => {
      dispatch(addFinanceUnitRedux(response.data.data))
      return response
    })
  }
}

export const editFinanceUnit = (payload) => {
  return (dispatch) => {
    return api.editFinanceUnit(payload).then((response) => {
      dispatch(editFinanceUnitRedux(response.data.data))
      return response
    })
  }
}

export const moveFinanceUnitRedux = (payload) => {
  return {
    type: actionTypes.MOVE_FINANCE_UNIT,
    payload,
  }
}

export const moveFinanceUnit = (payload) => {
  return (dispatch) => {
    dispatch(moveFinanceUnitRedux(payload))

    return api
      .moveFinanceUnit(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveFinanceUnitRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const filterFinanceUnit = (payload) => {
  return {
    type: actionTypes.FILTER_FINANCE_UNIT,
    payload,
  }
}

export const deleteFinanceUnitRedux = (payload) => {
  return {
    type: actionTypes.DELETE_FINANCE_UNIT,
    payload,
  }
}

export const deleteFinanceUnit = (payload) => {
  return (dispatch) => {
    return api.deleteFinanceUnit(payload.id).then((response) => {
      dispatch(deleteFinanceUnitRedux(payload))
      return response
    })
  }
}
