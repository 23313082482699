import axios from 'axios'
import { stringify } from 'query-string'

import { getOnce } from 'utils/helper'

export const getFinanceNumbers = () => {
  return axios.get('/finance/numbers/format')
}

export const getFinanceNumberFormat = (id, params) => {
  return axios.get(`/finance/numbers/${id}`, { params })
}

export const editFinanceNumber = (id, data) => {
  return axios.put(`/finance/numbers/${id}`, data)
}

export const getFinanceNumberNext = (name, params) => {
  return axios.get(`/finance/numbers/next/${name}`, { params })
}

export const isFinanceNumberExist = (name, params) => {
  return axios.get(`/finance/numbers/exists/${name}`, { params })
}

export const getFinanceNumberTrans = () => {
  return axios.get('/finance/numbers/trans')
}

export const createFinanceNumberTrans = (data) => {
  return axios.post('/finance/numbers/trans', data)
}

export const editFinanceNumberTrans = (id, data) => {
  return axios.put(`/finance/numbers/trans/${id}`, data)
}

export const deleteFinanceNumberTrans = (id) => {
  return axios.delete(`/finance/numbers/trans/${id}`)
}

export const getFinanceNumberLog = (params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/numbers/logs${query}`)
}

export const getFinanceNumberDetailLog = (id, params, justGetOnce = false) => {
  const query = params ? `?${stringify(params)}` : ''
  if (justGetOnce) return getOnce(`/finance/numbers/logs/${id}${query}`)
  return axios.get(`/finance/numbers/logs/${id}?${query}`)
}
