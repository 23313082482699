import axios from 'axios'
import { stringify } from 'query-string'
import { getOnce } from 'utils/helper'

export const getCanDeletedFinanceExpenseTerm = () => {
  return axios.get('/finance/expenses/terms/canBeDeleted')
}

export const addFinanceExpenseTerm = (data) => {
  return axios.post('/finance/expenses/terms', data)
}

export const editFinanceExpenseTerm = (data) => {
  return axios.put(`/finance/expenses/terms/${data.id}`, data)
}

export const deleteFinanceExpenseTerm = (data) => {
  return axios.delete(`/finance/expenses/terms/${data.id}`)
}

export const moveFinanceExpenseTerm = (srcId, destId) => {
  return axios.patch(`/finance/expenses/terms/${srcId}/move`, { dest_id: destId })
}

export const activateFinanceExpenseTerm = (id) => {
  return axios.patch(`/finance/expenses/terms/${id}/activate`)
}

export const deactivateFinanceExpenseTerm = (id) => {
  return axios.patch(`/finance/expenses/terms/${id}/deactivate`)
}

export const getSuggestionFinanceExpenseTerm = (params, options) => {
  const query = stringify(params)

  return getOnce(`/finance/expenses/terms/suggestionPerPage?${query}`, options)
}
