import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import {
  editFinanceLayoutBarcode,
  getFinanceLayoutBarcode,
  resetFinanceLayoutBarcode,
} from 'utils/apis/financeLayoutBarcode'

export const useFinanceLayoutBarcode = ({ options = {} } = {}) => {
  return useQuery(
    ['layoutBarcode'],
    async () => {
      const {
        data: { data },
      } = await getFinanceLayoutBarcode()
      return data
    },
    {
      onError: (error) => message.error((error && error.message) || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useEditFinanceLayoutBarcode = ({ options = {} } = {}) => {
  return useMutation((data) => editFinanceLayoutBarcode({ data }), options)
}

export const useResetFinanceLayoutBarcode = ({ options = {} } = {}) => {
  return useMutation(() => resetFinanceLayoutBarcode(), options)
}
