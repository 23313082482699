import * as actionTypes from 'redux/actionTypes'

export const setTransactionFees = (payload) => {
  return {
    type: actionTypes.SET_TRANSACTION_FEES,
    payload,
  }
}

export const addTransactionFeeRedux = (payload) => {
  return {
    type: actionTypes.ADD_TRANSACTION_FEE,
    payload,
  }
}

export const editTransactionFeeRedux = (payload) => {
  return {
    type: actionTypes.EDIT_TRANSACTION_FEE,
    payload,
  }
}

export const deleteTransactionFeeRedux = (payload) => {
  return {
    type: actionTypes.DELETE_TRANSACTION_FEE,
    payload,
  }
}
