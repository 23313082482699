import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Timeline from 'components/apps/Notification/Timeline'
import HideFeature from 'containers/Billing/HideFeature'
import { isFeaturePro } from 'utils/helper'
import { billingSelector } from 'redux/selectors'
import { useFinanceLogs } from 'utils/queries'

const TimelineContainer = ({ onHideParent, isMobileView, intl }) => {
  const billing = useSelector(billingSelector)
  const isPaidFeature = isFeaturePro({ billing, code: 'settings.audit' })

  const { data = {}, status } = useFinanceLogs()

  return (
    <>
      <div className="card-body">
        <Timeline
          loading={status === 'loading'}
          data={data.data || []}
          onHideParent={onHideParent}
          isMobileView={isMobileView}
          isPaidFeature={isPaidFeature}
          onLoadMore={() => null}
          hasMore={false}
          loadingMore={false}
        />
      </div>
      {status !== 'loading' && (
        <HideFeature code="settings.audit" feature={intl.formatMessage({ id: 'audit.show_audit' })}>
          <div className="text-center mb-4">
            <Link
              className="text-capitalize text-primary"
              to="/settings/audit"
              onClick={onHideParent}
            >
              {intl.formatMessage({ id: 'link.more' })}
            </Link>
          </div>
        </HideFeature>
      )}
    </>
  )
}

export default injectIntl(TimelineContainer)
