import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { ImportOutlined } from '@ant-design/icons'

function Import(props) {
  const { formatMessage } = useIntl()
  const title = props.title === 'button.import' ? formatMessage({ id: props.title }) : props.title
  return (
    <Button disabled={props.loading} {...props} title={title}>
      {title}
    </Button>
  )
}

Import.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}

Import.defaultProps = {
  type: 'default',
  icon: <ImportOutlined />,
  title: 'button.import',
  onClick: () => {},
  id: 'btn-import',
}

export default Import
