import React from 'react'
import { Modal, Spin, Typography, Row, Col, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Text } = Typography

const BlockLoader = ({ visible, title, message, ...props }) => {
  return (
    <Modal
      title={null}
      open={visible}
      destroyOnClose
      width={180}
      closable={false}
      footer={null}
      {...props}
    >
      <Row align="middle" justify="center">
        <Col>
          <Space size="large" direction="vertical" style={{ alignItems: 'center' }}>
            <Text strong>{message}</Text>
            <Spin indicator={<LoadingOutlined />} size="large" />
          </Space>
        </Col>
      </Row>
    </Modal>
  )
}

export default BlockLoader
