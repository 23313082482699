import { Layout } from 'antd'
import React from 'react'
import dayjs from 'dayjs'
import styles from '../Login/style.module.scss'

const PublicLayout = ({ children }) => {
  return (
    <Layout>
      <Layout.Content>
        <div className={styles.layout}>
          <div className={styles.content}>{children}</div>
          <div aria-hidden="true" className={`${styles.footer} text-center`}>
            <div>
              Powered by
              <div className={styles.logoKledoFooter}>
                <a target="_blank" rel="noopener noreferrer" href="https://kledo.com">
                  <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
                </a>
              </div>
            </div>

            <p>&copy;{` ${dayjs().format('YYYY')} Kledo. All rights reserved.`}</p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default PublicLayout
