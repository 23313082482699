import { message } from 'antd'

export const getFileNameFromHeaders = (headers) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(headers['content-disposition'])
  if (matches?.[1]) return matches[1].replace(/['"]/g, '')

  return null
}

export const onExport = ({ event, payload, fetcher }) => {
  if (event?.preventDefault) event.preventDefault()

  // let loading = () => null

  // if (intl) loading = message.loading(intl.formatMessage({ id: 'report.downloading_data' }))

  fetcher(payload)
    .then((response) => {
      const downloadLink = document.createElement('a')
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      downloadLink.href = URL.createObjectURL(blob)

      downloadLink.download = getFileNameFromHeaders(response.headers)
      message.loading('Downloading data...')
      downloadLink.click()
    })
    .catch((error) => {
      // loading()

      try {
        message.error(JSON.parse(new TextDecoder().decode(error?.data)).message)
      } catch {
        message.error('Failed from server!')
      }
    })
}

const handleExport = ({ fetcher, intl }) => {
  return ({ event, payload }) =>
    onExport({
      event,
      fetcher,
      intl,
      payload,
    })
}

const useExport = ({ fetcher, intl }) => {
  return handleExport({ fetcher, intl })
}

export default useExport
