import _ from 'lodash'

import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {
  data: [],
  show: false,
}

const setBroadcast = (state, { payload }) => {
  return updateObject(state, {
    data: payload,
  })
}

const clearBroadcast = (state) => {
  return updateObject(state, {
    data: [],
  })
}

const showBroadcast = (state) => {
  return updateObject(state, {
    show: true,
  })
}

const hideBroadcast = (state) => {
  return updateObject(state, {
    show: false,
  })
}

const markAsReadBroadcast = (state, { payload }) => {
  return updateObject(state, {
    data: state.data.filter((row) => !_.includes(payload.data, row.id)),
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BROADCAST:
      return setBroadcast(state, action)
    case actionTypes.CLEAR_BROADCAST:
      return clearBroadcast(state, action)
    case actionTypes.SHOW_BROADCAST:
      return showBroadcast(state, action)
    case actionTypes.HIDE_BROADCAST:
      return hideBroadcast(state, action)
    case actionTypes.MARKASREAD_BROADCAST:
      return markAsReadBroadcast(state, action)
    default:
      return state
  }
}

export default reducer
