import React, { useState, useEffect } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Layout, Alert, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import NProgress from 'nprogress'
import { push } from 'connected-react-router'
import { WhatsAppOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import store from 'store'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { inits } from 'redux/actions'
import {
  optionsSelector,
  userSelector,
  settingsSelector,
  initsSelector,
  billingSelector,
  companiesSelector,
} from 'redux/selectors'
import TopBar from 'components/LayoutComponents/TopBar/NoCompany'
import Footer from 'components/LayoutComponents/Footer'
import styles from './style.module.scss'

const NoCompanyLayout = ({ intl, children }) => {
  const dispatch = useDispatch()
  // const [backgroundEnabled, setBackgroundEnabled] = useState(false)
  const [networkError, setNetworkError] = useState(false)
  // const [backgroundNumber, setBackgroundNumber] = useState(7)
  const {
    property_name: company,
    chat_support: chatSupport,
    chat_number: chatNumber,
  } = useSelector(optionsSelector)
  const { expiry_date: expiryDate } = useSelector(billingSelector)
  const { id, name } = useSelector(userSelector)

  const { isBorderless, isSquaredBorders, isFixedWidth, isMenuShadow, isMenuTop, isMobileView } =
    useSelector(settingsSelector)
  const { loading } = useSelector(initsSelector)
  const companies = useSelector(companiesSelector)

  // Loader init
  useEffect(() => {
    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }, [loading])

  useEffect(() => {
    if (!_.isEmpty(companies) && !store.get('app.endpoint')) {
      dispatch(push('/user/select-company'))
    }
  }, [dispatch, companies])

  useEffect(() => {
    // Jika init belum di load, maka di ambil dari api
    const loadInits = async () => {
      const onInits = () => dispatch(inits())
      const response = await onInits()
      if (!response) {
        setNetworkError(true)
      }
    }

    if (!id && !loading) {
      loadInits()
    }
  }, [id, dispatch, setNetworkError, loading])

  return (
    <>
      <Layout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
      >
        <Layout>
          {expiryDate && dayjs().subtract(1, 'days').isAfter(expiryDate) && (
            <Alert
              style={{ backgroundColor: '#ffefb3' }}
              message={
                <FormattedMessage
                  id="billing.expired"
                  values={{
                    setup: (
                      <Link to="/settings/billing/upgrade">
                        <strong>
                          <FormattedMessage id="billing.expired_link" />
                        </strong>
                      </Link>
                    ),
                  }}
                />
              }
              type="warning"
              showIcon
            />
          )}
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="utils__content">
              {networkError === true ? (
                <div className={styles.inner}>
                  <Alert
                    message={intl.formatMessage({ id: 'users.failed_to_connect_to_kledo' })}
                    type="warning"
                    description={intl.formatMessage({
                      id: 'users.please_check_your_internet_connection_please_contact_wa',
                    })}
                    showIcon
                  />
                  <a href="https://kledo.com" className="btn mt-3">
                    &larr; {intl.formatMessage({ id: 'users.go_back_to_home_page' })}
                  </a>
                </div>
              ) : (
                children
              )}
            </div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
      {chatSupport === 1 && (
        <Button
          href={`https://api.whatsapp.com/send?phone=${chatNumber}&text=Hallo,%20saya%20${name}%20dari%20${company}%20ingin%20tanya%20tentang%20Kledo.`}
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
          shape={isMobileView ? 'circle' : 'round'}
          icon={<WhatsAppOutlined style={{ paddingTop: '.5rem' }} />}
          className={styles.waBtn}
        >
          {isMobileView ? null : intl.formatMessage({ id: 'button.hello_can_i_help' })}
        </Button>
      )}
    </>
  )
}

export default injectIntl(NoCompanyLayout)
