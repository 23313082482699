import { useSelector } from 'react-redux'

import { loadedInitSelector, userSelector } from 'redux/selectors'

/**
 * check if user.id === 0 because loadedInit has a bug that loadedInit=true before init loaded
 * @returns {boolean}
 */
const useLoadedInit = () => {
  const loadedInit = useSelector(loadedInitSelector)
  const user = useSelector(userSelector)

  return loadedInit && !!user.id
}

export default useLoadedInit
