import React from 'react'
import { Modal, Spin } from 'antd'
import { injectIntl } from 'react-intl'
import Player from './Player'

const ModalPlayer = ({ title, videoId, visible, onCancel, afterClose, playerProps, ...props }) => {
  const [youTubeApi, setYouTubeApi] = React.useState()

  const afterCloseHandler = React.useCallback(() => {
    if (youTubeApi) {
      youTubeApi.pauseVideo()
    }
    if (typeof afterClose === 'function') {
      afterClose()
    }
    // stopVideo('.video-responsive');
  }, [afterClose, youTubeApi])

  const readyHandler = (event) => setYouTubeApi(event.target)

  return (
    <Modal
      title={title}
      open={visible}
      footer={null}
      onCancel={onCancel}
      afterClose={afterCloseHandler}
      styles={{
        body: { overflow: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 },
        header: { padding: '16px 24px', margin: 0 },
        content: { padding: 0 },
      }}
      width={700}
      {...props}
    >
      <Spin tip="Waiting player..." spinning={!youTubeApi}>
        <Player
          containerClassName="video-responsive"
          className="youtube-player"
          videoId={videoId}
          onReady={readyHandler}
          {...playerProps}
        />
      </Spin>
    </Modal>
  )
}

const Component = injectIntl(ModalPlayer)
export default React.memo(Component)
