import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CopyOutlined } from '@ant-design/icons'

function Copy({
  type = 'success',
  icon = <CopyOutlined />,
  title = 'button.copy_to_clipboard',
  onClick = () => {},
  id = 'btn-copy',

  ...props
}) {
  const { formatMessage } = useIntl()
  const newTitle = title === 'button.copy_to_clipboard' ? formatMessage({ id: title }) : title
  return (
    <Button icon={icon} type={type} onClick={onClick} id={id} {...props}>
      {newTitle}
    </Button>
  )
}

// Copy.propTypes = {
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   type: PropTypes.string,
//   onClick: PropTypes.func,
// }

// Copy.defaultProps = {
//   icon: <CopyOutlined />,
//   title: 'button.copy_to_clipboard',
//   type: 'success',
//   onClick: () => {},
//   id: 'btn-copy',
// }

export default Copy
