import * as actionTypes from 'redux/actionTypes'

const reducer = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_HR_ADDON:
      return action.payload
    default:
      return state
  }
}

export default reducer