import { DatePicker, Form, Skeleton, Space, Switch } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { injectIntl } from 'react-intl'

import { formatDate, formatDateText } from 'utils/formatting'

const MassMinTransDateForm = ({ intl, form, loading, minTransDate, onSubmit }) => {
  const transDate = Form.useWatch('trans_date_from_parent_tran', form)

  const getDefaultPaymentDate = useCallback(() => {
    const now = dayjs()
    if (dayjs(minTransDate).isAfter(now)) {
      return dayjs(minTransDate)
    }
    return now
  }, [minTransDate])

  const disabledTransDate = useCallback(
    (current) => {
      return current && current.isBefore(dayjs(minTransDate), 'day')
    },
    [minTransDate],
  )

  if (loading) {
    return <Skeleton active />
  }

  return (
    <Form
      form={form}
      id="massMinTransDateForm"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        trans_date: getDefaultPaymentDate(),
        trans_date_from_parent_tran: 0,
      }}
    >
      {!transDate ? (
        <Form.Item
          label={intl.formatMessage({ id: 'financePurchaseInvoices.transaction_date' })}
          name="trans_date"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'financePurchaseInvoices.please_select_transaction_date',
              }),
            },
            {
              validator: (rule, value) => {
                if (value < dayjs(minTransDate)) {
                  return Promise.reject(
                    intl.formatMessage({
                      id: 'financePurchaseInvoices.transaction_date_must_be_gt_date',
                      date: formatDate(minTransDate),
                    }),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <DatePicker
            format={formatDateText()}
            style={{ width: '100%' }}
            disabledDate={disabledTransDate}
          />
        </Form.Item>
      ) : null}
      <Space align="center" className="mt-1">
        <Form.Item name="trans_date_from_parent_tran" valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>
        <Space direction="vertical" size={0}>
          <span>
            {intl.formatMessage({
              id: 'financeInvoices.date_same_as_the_original_transaction',
            })}
          </span>
        </Space>
      </Space>
    </Form>
  )
}

export default injectIntl(MassMinTransDateForm)
