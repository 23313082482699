import React, { createContext, useContext, useMemo, useReducer } from 'react'

const ProductDetailContext = createContext(null)

const initialState = {
  params: {
    'recent-transactions': {
      page: 1,
      sort_by: null,
      order_by: null,
      per_page: null,
      date_from: null,
      date_to: null,
      tags: [],
    },
    'stock-movement': {
      page: 1,
      sort_by: null,
      order_by: null,
      per_page: null,
      date_from: null,
      date_to: null,
      tags: [],
      trans_type_ids: [],
    },
    'warehouse-movement': {
      page: 1,
      sort_by: null,
      order_by: null,
      per_page: null,
      date_from: null,
      date_to: null,
    },
  },
  transactionsActiveTab: 'recent-transactions',
}

const actions = {
  UPDATE_PARAMS: 'UPDATE_PARAMS',
  TRANSACTIONS_ACTIVE_TAB: 'TRANSACTIONS_ACTIVE_TAB',
}

function reducer(initial, action) {
  if (action.type === actions.UPDATE_PARAMS) {
    return {
      ...initial,
      params: {
        ...initial.params,
        [action.payload.tableName]: {
          ...initial.params[action.payload.tableName],
          ...action.payload.params,
        },
      },
    }
  }

  if (action.type === actions.TRANSACTIONS_ACTIVE_TAB) {
    return {
      ...initial,
      transactionsActiveTab: action.payload,
    }
  }

  return initial
}

export function ProductDetailProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(
    () => ({
      params: state.params,
      transactionsActiveTab: state.transactionsActiveTab,
      updateParams: ({ tableName, params }) => {
        dispatch({
          type: actions.UPDATE_PARAMS,
          payload: {
            tableName,
            params,
          },
        })
      },
      updateTransactionsActiveTab: ({ key }) => {
        dispatch({
          type: actions.TRANSACTIONS_ACTIVE_TAB,
          payload: key,
        })
      },
    }),
    [state.params, state.transactionsActiveTab],
  )

  return <ProductDetailContext.Provider value={value}>{children}</ProductDetailContext.Provider>
}

export function useProductDetailContext() {
  return useContext(ProductDetailContext)
}
