import axios from 'axios'

const resource = 'pos/outletOptions'

export const updateOutletOptions = (outletid, payload) => {
  return axios.put(`/${resource}/${outletid}`, payload)
}

export const getOutletOptions = (outletid) => {
  return axios.get(`/${resource}/${outletid}`)
}
