import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons'

function Add({
  type = 'primary',
  icon = <PlusOutlined />,
  title = 'button.add',
  onClick = () => {},
  id = 'btn-add',
  intl,
  ...props
}) {
  const { formatMessage } = useIntl()
  const newTitle = title === 'button.add' ? formatMessage({ id: title }) : title
  return (
    <Button type={type} icon={icon} onClick={onClick} id={id} disabled={props.loading} {...props}>
      {newTitle}
    </Button>
  )
}

// Add.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   onClick: PropTypes.func,
//   id: PropTypes.string,
// }

// Add.defaultProps = {
//   type: 'primary',
//   icon: <PlusOutlined />,
//   title: 'button.add',
//   onClick: () => {},
//   id: 'btn-add',
// }

export default Add
