import { useQuery } from 'react-query'
import { message } from 'antd'
import { getLayoutReportVariable } from 'utils/apis'

export const useLayoutReportVariable = ({ payload, options = {} }) => {
  return useQuery(
    ['layout-report', payload],
    async () => {
      const {
        data: { data },
      } = await getLayoutReportVariable(payload)
      return data
    },
    {
      onError: (error) => message.error((error && error.message) || 'Failed from server!'),
      ...options,
    },
  )
}
