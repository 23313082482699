import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'
import {
  addInvoicePaymentCreditMemo,
  getInvoicePaymentCreditMemo,
  editInvoicePaymentCreditMemo,
  deleteInvoicePaymentCreditMemo,
} from 'utils/apis/invoicePaymentCreditMemo'

export function useInvoicePaymentCreditMemo({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['invoicePaymentCreditMemo', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getInvoicePaymentCreditMemo(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export const useAddInvoicePaymentCreditMemo = () => {
  return useMutation((payload) => addInvoicePaymentCreditMemo(payload))
}

export const useEditInvoicePaymentCreditMemo = () => {
  return useMutation((payload) => editInvoicePaymentCreditMemo(payload))
}

export const useDeleteInvoicePaymentCreditMemo = () => {
  return useMutation((payload) => deleteInvoicePaymentCreditMemo(payload.id))
}
