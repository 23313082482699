import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {}

const setBilling = (state, { payload }) => {
  return updateObject(state, {
    ...payload,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BILLING:
      return setBilling(state, action)
    default:
      return state
  }
}

export default reducer
