import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import ErrorPage from 'pages/Error'
import ServerError from 'pages/ServerError'
import routes from 'routes'
import { Button } from 'antd'
import Loader from 'components/LayoutComponents/Loader'
import Maintenance from 'pages/Maintenance'
import RequestDeleteAccount from 'pages/user/requestDeleteAccount'

class Router extends React.Component {
  errorComponent = () => {
    return (
      <React.Fragment>
        <h4>You have encountered an error</h4>
        <Button
          type="primary"
          onClick={() => {
            window.location.reload()
          }}
        >
          Click here to reload
        </Button>
      </React.Fragment>
    )
  }

  renderRoutes = () => {
    // const { demo } = this.props;
    try {
      return (
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/dashboard" />
            }}
          />
          <Route
            exact
            path="/profile"
            render={() => {
              return <Redirect to="/dashboard" />
            }}
          />
          {routes.map((route) => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
            />
          ))}
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/server-error" component={ServerError} />
          <Route exact path="/maintenance" component={Maintenance} />
          <Route exact path="/user/request-delete-account" component={RequestDeleteAccount} />
          <Route component={NotFoundPage} />
        </Switch>
      )
    } catch (e) {
      return this.errorComponent()
    }
  }

  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Suspense fallback={<Loader />}>{this.renderRoutes()}</Suspense>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
