import { useMutation, useQuery } from 'react-query'
import { getPriceRules, addPriceRule, editPriceRule, getPriceRule, deletePriceRule } from 'utils/apis/priceRule'

export const usePriceRules = ({ payload = {}, options }) => {
  return useQuery(
    ['getPriceRules', payload],
    async () => {
      const {
        data: { data },
      } = await getPriceRules(payload)
      return data
    },
    options,
  )
}

export const useCreatePriceRule = () => {
  return useMutation((payload) => addPriceRule(payload))
}

export const useEditPriceRule = () => {
  return useMutation((payload) => editPriceRule(payload))
}

export const useDeletePriceRule = () => {
  return useMutation((id) => deletePriceRule(id))
}

export const usePriceRule = (id, options) => {
  return useQuery(
    ['priceRule', id],
    async () => {
      const {
        data: { data },
      } = await getPriceRule(id)
      return data
    },
    {
      enabled: !!id,
      ...options,
    },
  )
}
