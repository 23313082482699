import { useHistory } from 'react-router-dom'
import { goBack, push, replace } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

// Hook untuk handle goBack router
// fallbackUrl diisi jika tidak ada history backnya
// isReplace flag menandakan harus direplace routernya
const useGoBack = ({ fallBackUrl, isReplace = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const goBackHandler = useCallback(() => {
    if (history.length < 2 && !!fallBackUrl) {
      if (isReplace) {
        dispatch(replace(fallBackUrl))
      } else {
        dispatch(push(fallBackUrl))
      }
    } else {
      dispatch(goBack())
    }
  }, [dispatch, fallBackUrl, history.length, isReplace])

  return { goBack: goBackHandler }
}

export default useGoBack
