import axios from 'axios'

// export const getInvoicePaymentCreditMemos = (params) => {
//   return axios.get('/finance/invoicePaymentCreditMemos', { params })
// }

export const addInvoicePaymentCreditMemo = (data) => {
  return axios.post('/finance/invoicePaymentCreditMemos', data)
}

export const editInvoicePaymentCreditMemo = (data) => {
  return axios.put(`/finance/invoicePaymentCreditMemos/${data.id}`, data)
}

export const getInvoicePaymentCreditMemo = (id) => {
  return axios.get(`/finance/invoicePaymentCreditMemos/${id}`)
}

export const deleteInvoicePaymentCreditMemo = (id) => {
  return axios.delete(`/finance/invoicePaymentCreditMemos/${id}`)
}

export const getInvoicePaymentCreditMemoLogs = (id, params) => {
  return axios.get(`/finance/invoicePaymentCreditMemos/${id}/logs`, { params })
}
