import axios from 'axios'

export const getEmails = () => {
  return axios.get('/emailTemplates')
}

export const getEmail = (id) => {
  return axios.get(`/emailTemplates/${id}`)
}

export const updateEmail = (data) => {
  return axios.put(`/emailTemplates/${data.id}`, data)
}

export const revertEmail = (id) => {
  return axios.put(`/emailTemplates/${id}/revert`)
}

export const getEmailTemplateCustomerBirthday = () => {
  return axios.get('/emailTemplates/customerBirthday')
}
