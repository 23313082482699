import { useEffect, useRef } from 'react'

/**
 *
 * @description this hook use to get previous props or state
 * this implementation inspired from class component
 */
const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value // assign the value of ref to the argument
  }, [value]) // this code will run when the value of 'value' changes
  return ref.current // in the end, return the current ref value.
}

export default usePrevious
