import { useQuery } from 'react-query'

import { getVideos } from 'utils/apis'

export const useVideos = ({ payload, options }) => {
  return useQuery(
    ['videos', payload],
    async () => {
      const {
        data: { data },
      } = await getVideos(payload)
      return data
    },
    options,
  )
}
