import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Space } from 'antd'

import { getMobileOperatingSystem } from 'utils/helper'
import { useSelector } from "react-redux";
import { darkModeSelector } from "redux/selectors";

const InstallPromptWrapper = ({ intl }) => {
  const [visible, setVisible] = React.useState(false)
  const [platform, setPlatform] = React.useState()
  const darkMode = useSelector(darkModeSelector)

  useEffect(() => {
    if (getMobileOperatingSystem() === 'android') {
      setVisible(true)
      setPlatform('android')
    }
    if (getMobileOperatingSystem() === 'ios') {
      setVisible(true)
      setPlatform('ios')
    }
  }, [])

  return (
    <div
      className="flex-column align-items-center p-4 w-100 border-top hide-on-print"
      style={{
        zIndex: 4,
        display: visible ? 'flex' : 'none',
        bottom: 0,
        position: 'fixed',
        backgroundColor: darkMode ? "#141414" : "#FFFFFF"
      }}
    >
      <h5 className="text-center mb-4" style={{color: darkMode ? "#FFFFFF" : ""}}>
        {intl.formatMessage({
          id:
            platform === 'ios'
              ? 'notification.kledo_is_now_on_app_store'
              : 'notification.kledo_is_now_on_google_play',
        })}
      </h5>
      <Space>
        <Button
          type="primary"
          target="_blank"
          rel="noopener noreferrer"
          href={
            platform === 'ios'
              ? 'https://apps.apple.com/id/app/kledo/id1586502518'
              : 'https://play.google.com/store/apps/details?id=com.kledo.app'
          }
        >
          {intl.formatMessage({ id: 'notification.install_now' })}
        </Button>
        <Button onClick={() => setVisible(false)}>
          {intl.formatMessage({ id: 'notification.later' })}
        </Button>
      </Space>
    </div>
  )
}

export default injectIntl(InstallPromptWrapper)
