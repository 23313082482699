import React, { useCallback, useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Divider, Form, Input, message, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { getSavedEmail } from 'utils/cookies'
import { hideLoginForm } from 'redux/user/actions'
import { loginFormSelector } from 'redux/selectors'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const UserCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="mr-3"
      style={{
        width: 18,
        height: 18,
      }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  )
}

const LoginForm = ({
  intl,
  onSubmit,
  onGoogleLogin,
  onFacebookLogin,
  onSsoLogin,
  loading,
  email,
}) => {
  const [form] = Form.useForm()
  const [editEmail, setEditEmail] = useState(false)

  const loginForm = useSelector(loginFormSelector)
  const dispatch = useDispatch()
  const onPush = (payload) => dispatch(push(payload))

  const failureGoogleLoginHandler = useCallback(
    (e) => {
      message.error(e?.message || intl.formatMessage({ id: 'notification.login_google_failure' }))
    },
    [intl],
  )
  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    onFailure: onGoogleLogin,
    cookiePolicy: 'single_host_origin',
    onError: failureGoogleLoginHandler,
    flow: 'auth-code',
  })

  const savedEmail = email || getSavedEmail()
  const emailRef = useRef()
  const passwordRef = useRef()

  const onEmailFocus = useCallback(() => {
    setEditEmail(true)
    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus()
        emailRef.current.input.setSelectionRange(0, form.getFieldValue('email').length)
      }
    }, 1)
  }, [form])

  const onEmailBlur = useCallback(() => {
    form.setFieldsValue({
      email: form.getFieldValue('email') ? form.getFieldValue('email').trim() : '',
    })
    if (form.getFieldsError(['email'])?.[0]?.errors?.length < 1 && passwordRef.current?.focus) {
      passwordRef.current.focus()
      setEditEmail(false)
    }
  }, [form])

  const googleLoginHandler = useCallback(() => login(), [login])

  const ssoLoginHandler = () => {
    dispatch(hideLoginForm())
    onPush('/user/login/sso')
  }

  const responseFacebook = (response) => {
    onFacebookLogin(response)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
      initialValues={{
        email: savedEmail ? savedEmail.trim() : '',
        remember_me: true,
      }}
    >
      <Form.Item
        shouldUpdate={(prevValues, currentValues) => prevValues.email !== currentValues.email}
        label={intl.formatMessage({ id: 'users.email' })}
        onClick={editEmail ? undefined : onEmailFocus}
      >
        {({ getFieldValue }) => {
          const currentEmail = getFieldValue('email')
          return (
            <>
              {savedEmail && !editEmail ? (
                <Space>
                  {currentEmail || savedEmail}
                  <Button
                    icon={<EditOutlined />}
                    shape="circle"
                    type="text"
                    onClick={onEmailFocus}
                  />
                </Space>
              ) : null}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'users.please_input_your_email_address' }),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'users.the_input_is_not_valid_email_' }),
                  },
                ]}
                noStyle
              >
                <Input
                  ref={emailRef}
                  size="default"
                  type={savedEmail && !editEmail ? 'hidden' : undefined}
                  autoFocus
                  onBlur={onEmailBlur}
                />
              </Form.Item>
            </>
          )
        }}
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'users.password' })}
        name="password"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: 'users.please_input_your_password' }),
          },
        ]}
      >
        <Input.Password ref={passwordRef} autoFocus={savedEmail} />
      </Form.Item>
      <Form.Item>
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Form.Item name="remember_me" valuePropName="checked" noStyle>
            <Checkbox>{intl.formatMessage({ id: 'users.remember_me' })}</Checkbox>
          </Form.Item>
          <Link to="/user/forgot" className="utils__link--blue utils__link--underlined pull-right">
            {intl.formatMessage({ id: 'users.forgot_password_' })}
          </Link>
        </Space>
      </Form.Item>
      <Button block type="primary" className="mt-2" htmlType="submit" loading={loading}>
        {intl.formatMessage({ id: 'users.login' })}
      </Button>
      <Divider className="my-4" />
      <Button
        onClick={googleLoginHandler}
        type="text"
        className="py-2 border"
        style={{ height: 'auto' }}
        disabled={loading}
        block
      >
        <img
          src="./resources/images/icons/google.svg"
          alt="Google Icon"
          width={18}
          className="mr-3"
        />
        {intl.formatMessage({ id: 'users.login_with_google' })}
      </Button>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        fields="email"
        callback={responseFacebook}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            type="text"
            className="mt-3 py-2 border"
            style={{ height: 'auto' }}
            disabled={loading}
            block
          >
            <img
              src="./resources/images/icons/facebook.svg"
              alt="Facebook Icon"
              width={18}
              className="mr-3"
            />
            {intl.formatMessage({ id: 'users.login_with_facebook' })}
          </Button>
        )}
      />
      <Button
        onClick={loginForm.showLoginForm ? onSsoLogin : ssoLoginHandler}
        type="text"
        className="mt-3 py-2 border d-flex align-items-center justify-content-center"
        style={{ height: 'auto' }}
        disabled={loading}
        block
      >
        <UserCircle />
        {intl.formatMessage({ id: 'sso.login_with_sso' })}
      </Button>
    </Form>
  )
}

export default injectIntl(LoginForm)
