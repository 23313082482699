import dayjs from 'dayjs';
import _ from 'lodash'

import { isValidJson } from 'utils/helper'
import { DEFAULT_PER_PAGES } from 'components/UI/Pagination'

export class ParseURIToParams {
  params = {}

  filters = {}

  constructor(filters) {
    this.filters = filters
  }

  build() {
    return this.params
  }

  date(name, config = {}) {
    const filter = this.filters[name]

    if (!filter || config.pass) return this

    this.params[name] = dayjs(filter).isValid() ? dayjs(filter) : null

    return this
  }

  string(name, config = {}) {
    const filter = this.filters[name]

    if (typeof filter !== 'string' || !filter || config.pass) return this

    if (_.isArray(config.validValues) && !config.validValues.includes(filter)) {
      return this
    }

    this.params[name] = filter

    return this
  }

  number() {
    return this
  }

  int(name, config = {}) {
    const filter = this.filters[name]

    if (!Number.isInteger(filter) || config.pass) return this

    if (_.isArray(config.validValues) && !config.validValues.includes(filter)) {
      return this
    }

    if (config.min && filter < config.min) {
      return this
    }

    if (config.max && filter > config.max) {
      return this
    }

    this.params[name] = Number(filter)

    return this
  }

  array(name, config = {}) {
    const filter = this.filters[name]

    if (config.pass) return this

    if (!isValidJson(filter)) {
      this.params[name] = []
      return this
    }

    const parseParam = JSON.parse(filter)
    if (config.selectedName) {
      this.params[config.selectedName] = parseParam
    }
    if (config.idOnly) {
      this.params[name] = _.isArray(parseParam) ? parseParam.map((row) => row.id) : []
    } else {
      this.params[name] = parseParam
    }

    return this
  }

  object(name, config = {}) {
    const filter = this.filters[name]

    if (config.pass) return this

    if (!isValidJson(filter)) {
      this.params[name] = ''
      return this
    }

    const parseParam = JSON.parse(filter)
    if (config.selectedName) {
      this.params[config.selectedName] = parseParam
    }
    if (config.idOnly) {
      this.params[name] = parseParam?.id ?? ''
    } else {
      this.params[name] = parseParam
    }

    return this
  }

  showColumns(name, config = {}) {
    const filter = this.filters[name]

    if (config.pass) return this

    if ((typeof filter !== 'string' && !filter) || config.pass) return this

    const newShowColumns = []

    filter.split(',').forEach((column) => {
      if (_.isArray(config.validValues) && !config.validValues.includes(column)) {
        return
      }

      newShowColumns.push(column)
    })

    this.params[name] = newShowColumns.join(',')

    return this
  }

  // common params

  page(config = {}) {
    if (config.pass) return this

    this.int('page', {
      min: 1,
      ...config,
    })

    return this
  }

  per_page(config = {}) {
    if (config.pass) return this

    this.int('per_page', {
      validValues: DEFAULT_PER_PAGES,
      ...config,
    })

    return this
  }

  orderBy(config = {}) {
    if (config.pass) return this

    this.string('order_by', {
      validValues: ['asc', 'desc'],
      ...config,
    })

    return this
  }
}
