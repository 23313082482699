import { LoadingOutlined } from '@ant-design/icons'
import { Divider, Space, Typography } from 'antd'
import React, { memo } from 'react'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { loadedInitSelector } from 'redux/selectors'
import { qtyToCurrency } from 'utils/formatting'
import { useQueues } from 'utils/queries/queue'

const { Text } = Typography

const QueueStats = ({ enabled, intl }) => {
  const loadedInit = useSelector(loadedInitSelector)
  // direfetch 2 detik
  const { data } = useQueues({ enabled: loadedInit && enabled, refetchInterval: 2000 })
  // tidak ditampilkan jika <= 1
  if (!data || data?.data <= 1) {
    return null
  }

  return (
    <Divider orientationMargin="0" orientation="center">
      <Space className="d-flex align-items-center">
        <LoadingOutlined style={{ fontSize: 16, color: '#0190fe' }} />
        <Text strong>
          {intl.formatMessage(
            { id: 'queue.data_is_waiting_to_be_processed' },
            { value: qtyToCurrency(data?.data || 0) },
          )}
        </Text>
      </Space>
    </Divider>
  )
}

export default memo(injectIntl(QueueStats))
