import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { message } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'

import { getSuggestionFinanceTag } from 'utils/apis'

const withTagSuggestion = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    constructor(props) {
      super(props)
      this._isMounted = false
      this.state = {
        data: [],
        search: '',
        loading: false,
        infiniteData: {
          hasMore: false,
          loading: false,
          page: 1,
        },
      }

      // Untuk menjeda dan menunggu ambil data ke api ketika user mengetik
      this.getDataDebounced = _.debounce(this.getData, 1000)

      // Untuk menjeda ketika melakukan scroll
      this.loadMoreThrottled = _.throttle(this.loadMore, 400)
    }

    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    searchHandler = (search, params = {}) => {
      const payload = {
        search,
        ...params,
      }
      this.setState({ search }, () => {
        this.getDataDebounced(payload)
      })
    }

    getData = (payload, callBack) => {
      const { user, permissions, ownerOnly } = this.props

      this.setState((prevState) => {
        return {
          loading: true,
          infiniteData: {
            ...prevState.infiniteData,
            page: 1,
            hasMore: false,
          },
        }
      })
      getSuggestionFinanceTag({
        ...payload,
        page: 1,
      })
        .then((response) => {
          if (!this._isMounted || !response) {
            return
          }

          if (response.data.success) {
            const { data } = response.data
            const filteredData =
              ownerOnly && !permissions.tags_other_user_access
                ? data.data.filter((tag) => tag.owner_id === user.id)
                : data.data

            this.setState(
              (prevState) => {
                return {
                  data: {
                    ...data,
                    data: filteredData,
                  },
                  loading: false,
                  infiniteData: {
                    ...prevState.infiniteData,
                    hasMore: response.data.data.last_page > prevState.infiniteData.page,
                  },
                }
              },
              () => {
                if (callBack) {
                  callBack()
                }
              },
            )
          } else {
            message.error(response.data.message)
            this.setState({
              loading: false,
            })
          }
        })
        .catch(() => {
          if (this._isMounted) {
            this.setState({
              loading: false,
            })
          }
        })
    }

    loadMore = (payload) => {
      getSuggestionFinanceTag(payload)
        .then((response) => {
          if (this._isMounted) {
            const { data } = response.data
            if (response.data.success) {
              this.setState((prevState) => {
                return {
                  data: {
                    ...data,
                    data: [...prevState.data.data, ...data.data],
                  },
                  infiniteData: {
                    ...prevState.infiniteData,
                    loading: false,
                    hasMore: data.last_page > prevState.infiniteData.page,
                  },
                }
              })
            } else {
              message.error(response.data.message)
              this.setState((prevState) => {
                return {
                  infiniteData: {
                    ...prevState.infiniteData,
                    loading: false,
                  },
                }
              })
            }
          }
        })
        .catch(() => {
          if (this._isMounted) {
            this.setState((prevState) => {
              return {
                infiniteData: {
                  ...prevState.infiniteData,
                  loading: false,
                },
              }
            })
          }
        })
    }

    scrollHandler = (event, params = {}) => {
      const { target } = event
      if (
        this.state.infiniteData.hasMore &&
        target.scrollTop + target.offsetHeight === target.scrollHeight
      ) {
        this.setState(
          (prevState) => {
            return {
              infiniteData: {
                ...prevState.infiniteData,
                loading: true,
                page: prevState.infiniteData.page + 1,
              },
            }
          },
          () => {
            if (target.scrollTo) {
              target.scrollTo(0, target.scrollHeight)
            }
            this.loadMoreThrottled({
              page: this.state.infiniteData.page,
              search: this.state.search,
              ...params,
            })
          },
        )
      }
    }

    resetSearchHandler = () => this.setState({ search: '' })

    render() {
      const { data, loading, infiniteData } = this.state

      const formItemLoader = {}
      if (loading) {
        formItemLoader.hasFeedback = true
        formItemLoader.validateStatus = 'validating'
      }

      return (
        <WrappedComponent
          {...this.props}
          tags={data.data || []}
          loadingSearchTag={loading}
          onSearchTag={this.searchHandler}
          formItemLoaderTag={formItemLoader}
          onLoadMore={this.scrollHandler}
          onFetchData={this.getData}
          infiniteData={infiniteData}
          onResetSearch={this.resetSearchHandler}
          search={this.state.search}
        />
      )
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.user,
    }
  }

  return injectIntl(connect(mapStateToProps)(HOC))
}

export default withTagSuggestion
