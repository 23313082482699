import axios from 'axios'

export const getFinanceBankStatements = (params) => {
  return axios.get('/finance/bankStatements', { params })
}

export const deleteFinanceBankStatement = (bankAccountId, ids) => {
  return axios.delete('/finance/bankStatements', {
    data: { bank_account_id: bankAccountId, id: ids },
  })
}

export const uploadImportFinanceBankStatement = (data) => {
  return axios.postForm('/finance/bankStatements/uploadImport', data)
}

export const executeImportFinanceBankStatement = (data) => {
  return axios.post('/finance/bankStatements/executeImport', data)
}

export const editFinanceBankStatement = (id, data) => {
  return axios.put(`/finance/bankStatements/${id}`, data)
}

export const getBankStatementDetails = (id) => {
  return axios.get(`/finance/bankStatements/${id}`)
}

export const revertBankStatementDetail = (id, data) => {
  return axios.put(`/finance/bankStatements/${id}/revert`, data)
}

export const inputMassRevertBankStatement = (data) => {
  return axios.post('/finance/bankStatements/inputMassRevert', data)
}

export const executeMassRevertBankStatement = (data) => {
  return axios.post('/finance/bankStatements/executeMassRevert', data)
}
