import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Empty, Skeleton } from 'antd'
import dayjs from 'dayjs'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { LinkTransType, Spinner } from 'components/UI'
import { formatDate, formatTime } from 'utils/formatting'
import Changes from 'components/apps/Notification/Timeline/Changes'
import { optionsSelector } from 'redux/selectors'
import style from './style.module.scss'

const Timeline = ({
  loading,
  isPaidFeature,
  data,
  onLoadMore,
  hasMore,
  loadingMore,
  intl,
  onHideParent,
  isMain = false,
}) => {
  const options = useSelector(optionsSelector)

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={1}
      loadMore={onLoadMore}
      hasMore={!loadingMore && hasMore}
      useWindow={isMain}
    >
      <Skeleton loading={loading} active avatar>
        {data.length > 0 ? (
          <ul className="list-unstyled" style={{ paddingTop: 8 }}>
            {data.slice(0, isPaidFeature ? 4 : data.length).map((item) => {
              let desc = ''
              // eslint-disable-next-line no-restricted-syntax
              for (const [key, value] of Object.entries(item.extra)) {
                if (key.toLowerCase() !== 'pdf url') {
                  desc += `${key}: ${value} `
                }
              }
              let typeDonut = style.info
              if (item.action_type_cat === 'delete') {
                typeDonut = style.danger
              } else if (item.action_type_cat === 'add') {
                typeDonut = style.success
              }
              return (
                <li className={`${style.item} row`} key={item.id}>
                  <div className="col-3" style={{ paddingRight: 0 }}>
                    <div
                      className={`${style.itemTime}`}
                      style={{ display: isPaidFeature ? 'none' : '' }}
                    >
                      {dayjs(item.created_at).tz(options.property_timezone, true).fromNow()}
                    </div>
                  </div>
                  <div className={`${style.itemSeparator}`}>
                    <div className={`${style.donut} ${typeDonut}`} />
                  </div>
                  <div className={`${style.itemContent} col-9`}>
                    <div>
                      {item.action_type_id === 3 ? (
                        <span>{item.desc.trans_type}</span>
                      ) : item.trans_type_id === 1025 ? (
                        <span>{intl.formatMessage({ id: 'report.reports' })}</span>
                      ) : (
                        <LinkTransType
                          title={item.desc.trans_type}
                          transTypeId={item.trans_type_id}
                          data={{
                            id: item.tran_id,
                            accountId: item.bank_account_id,
                            warehouseId: item.warehouse_id,
                            userId: item.user_id,
                          }}
                          onClick={onHideParent}
                        />
                      )}
                      {` ${item.desc.action}`}
                      {item.desc.name && intl.formatMessage({ id: 'audit.by' })}
                      {item.desc.name}
                    </div>
                    <small>{desc}</small>
                    <a
                      href={`https://www.whois.com/whois/${item.remote_addr}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary d-block"
                    >
                      {item.remote_addr}
                    </a>
                    <small>
                      {formatDate(item.created_at)} {formatTime(item.created_at)}
                    </small>
                    <Changes desc={item.desc} changes={item.changes} />
                  </div>
                </li>
              )
            })}
          </ul>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Skeleton>
      <div style={{ position: 'relative' }}>
        <Spinner spinning={loadingMore && hasMore && !isPaidFeature} style={{ left: '25%' }} />
      </div>
    </InfiniteScroll>
  )
}

export default injectIntl(Timeline)
