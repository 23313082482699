import store from 'store'
import Axios from 'axios'

import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'
import { generateBaseUri } from 'utils/helper'

const initialState = {
  companies: [],
  selectedEndpoint: null,
}

const setCompanies = (state, { payload }) => {
  return updateObject(state, {
    ...payload,
  })
}

const setWebsiteId = (state, { payload }) => {
  const { endpoint } = state.companies.filter((e) => e.website_id === payload)[0]

  setSelectedEndpoint(state, { payload: endpoint })
}

const setSelectedEndpoint = (state, { payload }) => {
  if (payload) {
    store.set('app.endpoint', payload)
    setEndpoint(state, { payload })
  } else {
    store.remove('app.endpoint')
  }

  return { ...state, selectedEndpoint: payload }
}

const editCompanyName = (state, { payload }) => {
  const companies = state.companies.map((company) => {
    if (company.endpoint === state.selectedEndpoint) {
      return { ...company, name: payload }
    }
    return company
  })

  return { ...state, companies }
}

const setEndpoint = (state, { payload }) => {
  Axios.defaults.baseURL = generateBaseUri()
  return { ...state, endpoint: payload }
}

const setInitial = () => {
  store.remove('app.endpoint')
  Axios.defaults.baseURL = generateBaseUri()
  return initialState
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANIES:
      return setCompanies(state, action)
    case actionTypes.SET_WEBSITE_ID:
      return setWebsiteId(state, action)
    case actionTypes.SET_ENDPOINT:
      return setEndpoint(state, action)
    case actionTypes.SET_SELECTED_ENDPOINT:
      return setSelectedEndpoint(state, action)
    case actionTypes.EDIT_COMPANY_NAME:
      return editCompanyName(state, action)
    case actionTypes.USER_LOGOUT:
      return setInitial()
    default:
      return state
  }
}

export default reducer
