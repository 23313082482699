import React, { Component } from 'react'
import { connect } from 'react-redux'
import store from 'store'
import Router from 'router'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import SentryErrorBoundary from 'components/SentryErrorBoundary'
import { initializeFp } from 'utils/gtm'
import { changeLayoutSetting } from 'redux/settings/actions'
import InstallPromptWrapper from './InstallPromptWrapper'
import UpdateAppWrapper from './UpdateAppWrapper'
import { ServiceWorkerProvider } from './ServiceWorkerProvider'

class App extends Component {
  async componentDidMount() {
    this.setLayoutView(true)
    window.addEventListener('resize', () => {
      this.setLayoutView()
    })
  }

  componentDidUpdate(prevProps) {
    // Reinitialize facebook pixel
    if (prevProps.user !== this.props.user) {
      if (process.env.NODE_ENV === 'production') {
        initializeFp(this.props.user.email)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setLayoutView)
  }

  setLayoutView = (load = false) => {
    const windowWidth = global.window.innerWidth
    const currentMobileState = windowWidth < 768
    const prevMobileState = store.get('app.settings.isMobileView')
    const currentTabState = windowWidth >= 768 && windowWidth <= 992
    const prevTabState = store.get('app.settings.isTabView')

    if (currentMobileState !== prevMobileState || currentTabState !== prevTabState || load) {
      this.props.onChangeLayoutSetting({
        isMobileView: currentMobileState,
        isTabView: currentTabState,
      })
    }
  }

  render() {
    const { history, user } = this.props
    const appComponent = (
      <>
        <Router history={history} />
        <UpdateAppWrapper />
        <InstallPromptWrapper />
      </>
    )

    return (
      <SentryErrorBoundary
        showDialog
        dialogOptions={{
          user: {
            name: user?.name,
            email: user?.email,
          },
        }}
      >
        <ServiceWorkerProvider>
          {window.Cypress ? (
            appComponent
          ) : (
            <ClearBrowserCacheBoundary duration={900000}>{appComponent}</ClearBrowserCacheBoundary>
          )}
        </ServiceWorkerProvider>
      </SentryErrorBoundary>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  user,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLayoutSetting: (payload) => dispatch(changeLayoutSetting(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
