import React, { useCallback } from 'react'
import { Pagination as PaginationAntd, Typography } from 'antd'
import { useSelector } from 'react-redux'

import { settingsSelector } from 'redux/selectors'
import { qtyToCurrencyAutoComma } from 'utils/formatting'
import PageSizeChanger from './PageSizeChanger'

export const DEFAULT_PER_PAGES = [15, 25, 50, 100]

function Pagination({
  current = 1,
  pageSize = 15,
  onChange = () => {},
  onShowSizeChange = () => {},
  total = 0,
  hideOnSinglePage = true,
  showSizeChanger = false,
  showPageSizeChangeOnSinglePage = false,
  pageSizeOptions,
  disableFloat,
  customTextOnlyTotal,
  ...props
}) {
  const settings = useSelector(settingsSelector)
  const innerPageSizeOptions = pageSizeOptions || DEFAULT_PER_PAGES

  const showTotal = useCallback(
    (value) => {
      if (typeof value === 'number') {
        return `Total ${qtyToCurrencyAutoComma({
          value: customTextOnlyTotal || value,
          maxPrecision: 0,
        })} data`
      }
      return ''
    },
    [customTextOnlyTotal],
  )

  if (showSizeChanger && showPageSizeChangeOnSinglePage) {
    return (
      <div
        className="d-flex custom-pagination"
        style={{
          float: !disableFloat && (settings.isMobileView ? 'left' : 'right'),
          width: 'auto',
        }}
      >
        {total ? (
          <Typography.Text className="ant-pagination-total-text">
            Total {qtyToCurrencyAutoComma({ value: customTextOnlyTotal || total, maxPrecision: 0 })}{' '}
            data
          </Typography.Text>
        ) : null}
        <PageSizeChanger
          pageSizeOptions={innerPageSizeOptions}
          pageSize={pageSize}
          onShowSizeChange={onShowSizeChange}
          current={current}
        />
      </div>
    )
  }

  return (
    <PaginationAntd
      pageSizeOptions={innerPageSizeOptions}
      showTotal={showTotal}
      hideOnSinglePage={hideOnSinglePage}
      pageSize={pageSize}
      onChange={onChange}
      showSizeChanger={showSizeChanger}
      total={total}
      current={current}
      onShowSizeChange={onShowSizeChange}
      {...props}
    />
  )
}

export default Pagination
