/* eslint-disable camelcase */
import axios from 'axios'

const resource = 'authentication'

export const login = (email, password, remember) => {
  const data = {
    email,
    password,
    remember_me: remember,
  }

  return axios.post(`/${resource}/login`, data, { headers: { Authorization: false } })
}

export const singleLogin = (email, password, remember) => {
  const data = {
    email,
    password,
    remember_me: remember,
  }

  return axios.post(`/${resource}/singleLogin`, data, { headers: { Authorization: false } })
}

export const verifyGoogleToken = (code) => {
  return axios.post(`/google-auth/verify`, { code }, { headers: { Authorization: false } })
}
export const verifyFacebookToken = (access_token) => {
  return axios.post(
    `/facebook-login/token`,
    { access_token },
    { headers: { Authorization: false } },
  )
}

export const updateProfile = (data) => {
  return axios.put(`/${resource}/user`, data)
}

export const changePassword = (data) => {
  return axios.put(`/${resource}/password`, data)
}

export const revokeLogin = () => {
  return axios.post(`/authentication/revoke`)
}

export const invite = (data) => {
  return axios.post(`/users/register`, data, { headers: { Authorization: false } })
}

export const acceptInvitation = (data) => {
  return axios.post(`/users/acceptInvitation`, data, { headers: { Authorization: false } })
}

export const reset = (data) => {
  return axios.post(`/authentication/password/recovery/reset`, data, {
    headers: { Authorization: false },
  })
}

export const changeLanguage = (lang) => {
  return axios.patch(`/${resource}/user/lang`, { lang })
}

export const logout = () => {
  return axios.post(`/${resource}/logout`)
}

export const loginWithSso = (data) => {
  return axios.post(`/sso/login`, data, { headers: { Authorization: false } })
}

export const getUserByToken = (token) => {
  return axios.get(`/users/register/${token}`, { headers: { Authorization: false } })
}
