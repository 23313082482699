import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Tooltip } from 'antd'
import { MoonFilled, SunFilled } from '@ant-design/icons'
import store from 'store'
import { injectIntl } from 'react-intl'

import { optionsSelector } from 'redux/selectors'
import { useEditOptionThemeColors } from 'utils/queries/options'
import { editOptionsRedux } from 'redux/options/actions'

const ThemeColors = ({ intl }) => {
  const dispatch = useDispatch()
  const options = useSelector(optionsSelector)
  const isDarkMode = options?.dark_mode === 1

  const editOptionThemeColors = useEditOptionThemeColors()

  const changeThemeHandler = useCallback(
    (value) => {
      dispatch(editOptionsRedux({ dark_mode: value ? 1 : 0 }))
      editOptionThemeColors.mutate(
        { dark_mode: value ? 1 : 0 },
        {
          onSuccess: (response) => {
            if (response.data.success) {
              if (value) {
                store.set('app.darkMode', value ? 1 : 0)
              } else {
                store.remove('app.darkMode')
              }
            } else {
              dispatch(editOptionsRedux({ dark_mode: value ? 0 : 1 }))
            }
          },
        },
      )
    },
    [dispatch, editOptionThemeColors],
  )

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: isDarkMode ? 'topBar.show_light_mode' : 'topBar.show_dark_mode',
        })}
      >
        <div className="flex items-center">
          <Switch
            checkedChildren={<MoonFilled />}
            unCheckedChildren={<SunFilled />}
            checked={options?.dark_mode === 1}
            onChange={changeThemeHandler}
            disabled={editOptionThemeColors.isLoading}
            loading={editOptionThemeColors.isLoading}
          />
        </div>
      </Tooltip>
    </>
  )
}

export default injectIntl(ThemeColors)
