import * as actionTypes from 'redux/actionTypes'
// import { updateObject } from 'redux/utility'

export default function maintenanceReducer(state = '', action) {
  switch (action.type) {
    case actionTypes.SET_MAINTENANCE:
      return action.payload
    default:
      return state
  }
}
