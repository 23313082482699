import * as actionTypes from 'redux/actionTypes'

const initialState = []

export default function outletsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_OUTLETS:
      return action.payload
    case actionTypes.ADD_OUTLET:
      return [...state, action.payload]
    case actionTypes.EDIT_OUTLET:
      return state.map((outlet) => {
        if (outlet.id === action.payload.id) return action.payload
        return outlet
      })
    case actionTypes.DELETE_OUTLET:
      return state.filter((outlet) => outlet.id !== action.payload.id)
    default:
      return state
  }
}
