import axios from 'axios'
import { stringify } from 'query-string'

// import { getOnce } from 'utils/helper'

// export const getDebitMemos = (params) => {
//   const query = stringify(params)
//   return getOnce(`/finance/debitMemos?${query}`)
// }

export const addDebitMemo = (data) => {
  return axios.post('/finance/debitMemos', data)
}

export const editDebitMemo = (data) => {
  return axios.put(`/finance/debitMemos/${data.id}`, data)
}

export const deleteOnAddDebitMemoAttachment = (url) => {
  return axios.delete('/finance/debitMemos/attachments', { data: { url } })
}

export const deleteOnEditDebitMemoAttachment = (id, url) => {
  return axios.delete(`/finance/debitMemos/${id}/attachments`, { data: { url } })
}

export const getDebitMemo = (id) => {
  return axios.get(`/finance/debitMemos/${id}`)
}

export const deleteDebitMemo = (id) => {
  return axios.delete(`/finance/debitMemos/${id}`)
}

export const getDebitMemoLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/debitMemos/${id}/logs${query}`)
}
