import axios from 'axios'
import { stringify } from 'query-string'

export const getConsolidationCompanies = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/consolidations/companies?${query}`)
}

export const updateConsolidationCompanies = (data) => {
  return axios.put('/finance/consolidations/companies', data)
}

export const getConsolidationAccounts = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/consolidations/accounts?${query}`)
}

export const updateConsolidationAccounts = (data) => {
  return axios.put('/finance/consolidations/accounts', data)
}

export const autoCompileConsolidation = (data) => {
  return axios.post('/finance/consolidations/accounts/autoCompile', data)
}

export const activateConsolidation = (data) => {
  return axios.post('/finance/consolidations/activate', data)
}

export const deactivateConsolidation = (data) => {
  return axios.post('/finance/consolidations/deactivate', data)
}
