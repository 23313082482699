import React from 'react'
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl'
import { DatePicker } from 'antd'
import { formatDateText } from 'utils/formatting'
import { connect } from 'react-redux'

const { RangePicker: RangeAntd } = DatePicker

export const getDefaultPresets = (intl) => ([
  {
    label: intl.formatMessage({ id: 'date.yesterday' }),
    value: [
      dayjs().subtract(1, 'day'),
      dayjs().subtract(1, 'day'),
    ],
  },
  {
    label: intl.formatMessage({ id: 'date.today' }),
    value: [dayjs(), dayjs()],
  },
  {
    label: intl.formatMessage({ id: 'date.this_month' }),
    value: [
      dayjs().startOf('month'),
      dayjs().endOf('month'),
    ],
  },
  {
    label: intl.formatMessage({ id: 'date.last_month' }),
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  },
  {
    label: intl.formatMessage({ id: 'date.last_7_days' }),
    value: [dayjs().subtract(7, 'days'), dayjs()],
  },
  {
    label: intl.formatMessage({ id: 'date.last_30_days' }),
    value: [
      dayjs().subtract(30, 'days'),
      dayjs(),
    ],
  },
  {
    label: intl.formatMessage({ id: 'date.this_year' }),
    value: [
      dayjs().startOf('year'),
      dayjs().endOf('year'),
    ],
  },
  {
    label: intl.formatMessage({ id: 'date.last_year' }),
    value: [
      dayjs().subtract(1, 'year').startOf('year'),
      dayjs().subtract(1, 'year').endOf('year'),
    ],
  }
]);

function RangePicker({ intl, presets = [], ...props }) {
  return (
    <RangeAntd
      format={formatDateText()}
      presets={[
        ...getDefaultPresets(intl),
        ...presets
      ]}
      {...props}
    />
  )
}

export default connect()(injectIntl(RangePicker))
