import React from 'react'
import { Button, Space } from 'antd'
import { useIntl } from 'react-intl'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'

function Filter({
  icon = <FilterOutlined />,
  title = 'button.filter',
  onClick = () => {},
  id = 'btn-clear',
  showClearBtn = false,
  onClear = () => {},
  ...props
}) {
  const { formatMessage } = useIntl()
  const newTitle = title === 'button.filter' ? formatMessage({ id: title }) : title
  return (
    <Space>
      <Button icon={icon} onClick={onClick} id={id} {...props} title={newTitle}>
        {newTitle}
      </Button>
      {showClearBtn && (
        <Button onClick={onClear} icon={<CloseOutlined />} danger>
          {formatMessage({ id: 'button.clear_filter' })}
        </Button>
      )}
    </Space>
  )
}

export default Filter
