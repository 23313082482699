import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { TimePicker as TimeAntd } from 'antd'
import locale from 'antd/es/date-picker/locale/id_ID'

import styles from './styles.module.scss'

const idLocale = {
  ...locale,
  lang: {
    ...locale.lang,
    now: 'Sekarang',
    ok: 'Ok',
  },
}

function TimePicker({ intl, ...props }) {
  return (
    <TimeAntd
      locale={intl.locale === 'id-ID' ? idLocale : undefined}
      allowClear={false}
      popupClassName={styles.timePicker}
      {...props}
    />
  )
}

export default connect()(injectIntl(TimePicker))
