import React, { useCallback, useMemo } from 'react'
import { Dropdown } from 'antd'
// import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { DownOutlined, LoadingOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons'
import Save from './Save'

function SaveAddNew({
  onClick = () => {},
  onClickNew = () => {},
  onCancel = () => {},
  type = 'primary',
  id = 'btn-save',
  loading = false,
  intl,
  ...props
}) {
  const items = useMemo(() => {
    const temps = [
      {
        key: 2,
        icon: <SaveOutlined />,
        onClick: () => onClickNew(),
        label: intl.formatMessage({ id: 'button.save_add_new' }),
      },
    ]
    if (typeof onCancel === 'function') {
      temps.push({
        key: 3,
        icon: <CloseOutlined />,
        onClick: onCancel,
        label: intl.formatMessage({ id: 'button.cancel' }),
      })
    }
    return temps
  }, [onCancel, onClickNew, intl])

  const buttonsRender = useCallback(
    (btns) => {
      return [<Save id={id} {...btns[0].props} style={{ flex: 1 }} />, btns[1]]
    },
    [id],
  )

  return (
    <Dropdown.Button
      type={type}
      loading={loading}
      style={{ width: '100%' }}
      menu={{ items }}
      icon={loading ? <LoadingOutlined /> : <DownOutlined />}
      onClick={onClick}
      disabled={loading}
      buttonsRender={buttonsRender}
      {...props}
    />
  )
}

// SaveAddNew.propTypes = {
//   type: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
//   onClickNew: PropTypes.func,
// }

// SaveAddNew.defaultProps = {
//   type: 'primary',
//   id: 'btn-save',
//   onClick: () => {},
//   onClickNew: () => {},
// }

export default injectIntl(SaveAddNew)
