import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {
  payments: [],
}

const setPayments = (state, { payload }) => {
  return updateObject(state, {
    payments: payload.payments,
  })
}

const addPayment = (state, action) => {
  const payments = [action.payload, ...state.payments]
  return updateObject(state, { payments })
}

const editPayment = (state, action) => {
  const payments = state.payments.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return updateObject(state, { payments })
}

const deletePayment = (state, action) => {
  const payments = state.payments.filter((extra) => extra.id !== action.extraId)
  return updateObject(state, { payments })
}

const changeStatusPayment = (state, action) => {
  const payments = state.payments.map((item) => {
    if (item.id === action.payload.id) {
      return {
        ...item,
        is_active: action.payload.checked ? 1 : 0,
      }
    }
    return item
  })
  return updateObject(state, { payments })
}

const movePayment = (state, action) => {
  const { dragIndex, hoverIndex } = action.payload
  const payments = [...state.payments]
  const srcPayment = payments[dragIndex]
  // const { data } = this.state;
  // const dragRow = payments[action.dragIndex];
  // const dstPayment = payments[hoverIndex];
  // console.log("Move Payment srcPayment", srcPayment);
  // console.log("Move Payment state", state);
  // console.log("Move Payment reducers BEFORE", payments);
  // console.log("dragIndex, dragIndex");
  payments.splice(dragIndex, 1)
  payments.splice(hoverIndex, 0, srcPayment)
  // console.log("Move Payment reducers action", action);
  // console.log("Move Payment reducers payments", payments);
  return updateObject(state, { payments })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENTS:
      return setPayments(state, action)
    case actionTypes.ADD_PAYMENT:
      return addPayment(state, action)
    case actionTypes.EDIT_PAYMENT:
      return editPayment(state, action)
    case actionTypes.DELETE_PAYMENT:
      return deletePayment(state, action)
    case actionTypes.MOVE_PAYMENT:
      return movePayment(state, action)
    case actionTypes.CHANGE_STATUS_PAYMENT:
      return changeStatusPayment(state, action)
    default:
      return state
  }
}

export default reducer
