import { message } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  approveFinancePurchaseInvoice,
  deleteFinancePurchaseInvoice,
  executeImportFinancePurchaseInvoice,
  executeImportFinancePurchaseInvoiceRecurring,
  executeImportStatusFinancePurchaseInvoice,
  getFinancePurchaseInvoice,
  getFinancePurchaseInvoicePublicUrl,
  getFinancePurchaseInvoices,
  getMinTransDateFinancePurchaseInvoice,
  getRecurringPurchaseInvoices,
  inputMassCreateFinancePurchaseInvoice,
  rejectFinancePurchaseInvoice,
  revertApprovalFinancePurchaseInvoice,
  uploadImportFinancePurchaseInvoice,
  uploadImportFinancePurchaseInvoiceRecurring,
  uploadImportStatusFinancePurchaseInvoice,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const usePurchaseInvoices = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.purchases_per_page_default) {
    dispatch(
      editOption({
        purchases_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['purchaseInvoice', payload],
    async () => {
      const {
        data: { data },
      } = await getFinancePurchaseInvoices(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useRecurringPurchaseInvoices = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['purchaseInvoice', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringPurchaseInvoices(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function usePurchaseInvoice({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['purchaseInvoice', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinancePurchaseInvoice(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function usePurchaseInvoicePublicUrl(id, options = {}) {
  return useQuery(
    ['purchaseInvoicePublicUrl', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinancePurchaseInvoicePublicUrl(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useDeletePurchaseInvoice = () => {
  return useMutation((payload) => deleteFinancePurchaseInvoice(payload.id))
}

export const useApprovePurchaseInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => approveFinancePurchaseInvoice({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['purchaseInvoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }
      // message.success(response.data.message)
      // queryClient.setQueryData(
      //   ['purchaseInvoice', `${response.data.data.id}`, {}],
      //   response.data.data,
      // )
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRejectPurchaseInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => rejectFinancePurchaseInvoice(payload), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['purchaseInvoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }

      // recreate object to trigger useMemo rerender on data change
      // const oldData = {
      //   ...queryClient.getQueryData(['purchaseInvoice', `${response.data.data.id}`, {}]),
      // }
      // oldData.is_approveable = false
      // oldData.approval_status.is_rejected = 1
      // queryClient.setQueryData(['purchaseInvoice', `${oldData.id}`, {}], oldData)
      // message.success(response.data.message)
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRevertApprovalFinancePurchaseInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => revertApprovalFinancePurchaseInvoice({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['purchaseInvoice', `${response.data.data.id}`, {}])
      } else {
        message.error(response.data.message)
      }
    },
    onError: (error) => message.error(error.data.message),
  })
}

export function useUploadImportFinancePurchaseInvoiceMutation() {
  return useMutation(uploadImportFinancePurchaseInvoice)
}

export function useExecuteImportFinancePurchaseInvoiceMutation() {
  return useMutation(executeImportFinancePurchaseInvoice)
}

export function useUploadImportStatusFinancePurchaseInvoiceMutation() {
  return useMutation(uploadImportStatusFinancePurchaseInvoice)
}

export function useExecuteImportStatusFinancePurchaseInvoiceMutation() {
  return useMutation(executeImportStatusFinancePurchaseInvoice)
}

export const useGetMinTransDateFinancePurchaseInvoiceMutation = () => {
  return useMutation((payload) =>
    getMinTransDateFinancePurchaseInvoice({ ...payload, trans_type: 'purchase_invoice' }),
  )
}

export const useInputMassCreateFinancePurchaseInvoiceMutation = () => {
  return useMutation(inputMassCreateFinancePurchaseInvoice)
}

export const useUploadImportFinancePurchaseInvoiceRecurringMutation = () => {
  return useMutation(uploadImportFinancePurchaseInvoiceRecurring)
}

export const useExecuteImportFinancePurchaseInvoiceRecurringMutation = () => {
  return useMutation(executeImportFinancePurchaseInvoiceRecurring)
}
