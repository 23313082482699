import { message } from 'antd'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import { getFinanceBankReconciles, inputMassDeleteFinanceBank } from 'utils/apis'
// import { massReconcileFinanceBank } from 'utils/apis'

export const useBankReconciles = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['bankReconcile', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceBankReconciles(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export const useMassReconcileInfo = ({
  setMassReconciliationInfo,
  setLoadingMassReconciliation,
}) => {
  const { formatMessage } = useIntl()
  return useMutation(inputMassDeleteFinanceBank, {
    onSuccess(res) {
      if (res.data.success) {
        setMassReconciliationInfo(res.data.data)
        setLoadingMassReconciliation(false)
      } else {
        message.error(message.data.message)
        setLoadingMassReconciliation(false)
      }
    },
    onError() {
      message.error(formatMessage({ id: 'massDelete.cannot_mass_delete' }))
      setLoadingMassReconciliation(false)
    },
  })
}

// export const useMassDeleteReconcile = ({ intl, setMassDeleteInfo, setLoadingMassDelete }) => {
//   return useMutation(massReconcileFinanceBank, {
//     onSuccess(res) {
//       if (res.data.success) {
//         setMassDeleteInfo(res.data.data)
//         setLoadingMassDelete(false)
//       } else {
//         message.error(message.data.message)
//         setLoadingMassDelete(false)
//       }
//     },
//     onError() {
//       message.error(intl.formatMessage({ id: 'massDelete.cannot_mass_delete' }))
//       setLoadingMassDelete(false)
//     },
//   })
// }
