import * as actionTypes from 'redux/actionTypes'

const setReviews = (state, action) => {
  return action.payload
}

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_REVIEWS:
      return setReviews(state, action)
    default:
      return state
  }
}

export default reducer
