import axios from 'axios'

export const addShippingComp = (data) => {
  return axios.post('/finance/shippingComps', data)
}

export const editShippingComp = (data) => {
  return axios.put(`/finance/shippingComps/${data.id}`, data)
}

export const deleteShippingComp = (data) => {
  return axios.delete(`/finance/shippingComps/${data.id}`)
}

export const moveShippingComp = (srcId, destId) => {
  return axios.patch(`/finance/shippingComps/${srcId}/move`, { dest_id: destId })
}

export const activateShippingComp = (id) => {
  return axios.patch(`/finance/shippingComps/${id}/activate`)
}

export const deactivateShippingComp = (id) => {
  return axios.patch(`/finance/shippingComps/${id}/deactivate`)
}

export const getCanDeletedShippingComp = () => {
  return axios.get('/finance/shippingComps/canBeDeleted')
}
