import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getOnboarding, getOnboardingStatus, updateOnboardingStatus } from 'utils/apis'
import { message } from 'antd'

export const useOnboarding = ({ options = {} } = {}) => {
  return useQuery(
    ['onboarding'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getOnboarding()
        response = data
      } catch (error) {
        message.error(error?.response?.data?.message || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error(error?.response?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useOnboardingStatus = ({ options = {} } = {}) => {
  return useQuery(
    ['onboarding-status'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getOnboardingStatus()
        response = data
      } catch (error) {
        message.error(error?.response?.data?.message || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error(error?.response?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useUpdateOnboardingStatus = ({ options = {} } = {}) => {
  const queryClient = useQueryClient()
  return useMutation((id) => updateOnboardingStatus(id), {
    ...options,
    onSuccess: (response) => {
      queryClient.setQueriesData(['onboarding-status'], (old) => {
        const data = {
          ...old,
          progress: response.data.data.progress,
          progress_detail: response.data.data.progress_detail,
        }
        return data
      })
    },
    onError: (error) => {
      message.error(error?.response?.data?.message || 'Failed to load data from server!')
    },
  })
}
