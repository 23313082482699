import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { UpCircleOutlined } from '@ant-design/icons'

function Upgrade(props) {
  const { formatMessage } = useIntl()
  const title = props.title === 'button.upgrade' ? formatMessage({ id: props.title }) : props.title
  return (
    <Button {...props} title={title}>
      {title}
    </Button>
  )
}

Upgrade.propTypes = {
  icon: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}

Upgrade.defaultProps = {
  icon: <UpCircleOutlined />,
  type: 'success',
  title: 'button.upgrade',
  onClick: () => {},
  id: 'btn-upgrade',
}

export default Upgrade
