import React from 'react'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Grid, Select } from 'antd'

import { settingsSelector } from 'redux/selectors'

const { useBreakpoint } = Grid

const PageSizeChanger = ({ intl, pageSize, pageSizeOptions, onShowSizeChange, current }) => {
  const { xs } = useBreakpoint()
  const settings = useSelector(settingsSelector)
  const isSmall = !!xs

  return (
    <Select
      style={{ float: settings.isMobileView ? 'left' : 'right', width: 'auto' }}
      size={isSmall ? 'small' : 'middle'}
      className="ant-select"
      value={pageSize}
      onChange={(value) => onShowSizeChange(current, value)}
    >
      {pageSizeOptions.map((option) => (
        <Select.Option key={option} value={option}>
          {option} / {intl.formatMessage({ id: 'button.page' }).toLowerCase()}
        </Select.Option>
      ))}
    </Select>
  )
}

export default injectIntl(PageSizeChanger)
