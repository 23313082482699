import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import {
  editOptionsThemeColors,
  getOptionsDueReminders,
  getOptionsThemeColors,
  updateOptions,
  updateOptionsDueReminders
} from 'utils/apis'
import { useDispatch, useSelector } from 'react-redux'
import { editOptionsRedux } from 'redux/options/actions'
import { optionsSelector } from 'redux/selectors'

export const useOptionsDueReminders = ({ payload, options = {} } = {}) => {
  return useQuery(
    ['getOptionsDueReminders', payload],
    async () => {
      const {
        data: { data },
      } = await getOptionsDueReminders(payload)
      return data
    },
    {
      ...options,
      keepPreviousData: true,
      onError: (error) => message.error((error && error.message) || 'Failed data from server!'),
    },
  )
}

export const useOptionsDueRemindersMutation = ({ options = {} } = {}) => {
  return useMutation((payload) => updateOptionsDueReminders(payload), {
    ...options,
  })
}

export const useEditOptions = () => {
  const dispatch = useDispatch()
  const prevOptions = useSelector(optionsSelector)
  return useMutation((payload) => updateOptions(payload), {
    onMutate: (payload) => {
      dispatch(editOptionsRedux(payload))
      return { prevOptions }
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message)
      } else {
        message.success(data?.message)
      }
    },
    onError: (error, val, context) => {
      dispatch(editOptionsRedux(context.prevOptions))
      message.error('Failed save data to server!')
    },
  })
}

export const useOptionsThemeColors = () => {
  return useQuery(
    "getOptionsThemeColors",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getOptionsThemeColors();
        response = data;
      } catch (error) {
        message.error((error && error.message) || 'Failed save data to server!')
      }
      return response;
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed save data to server!')
    }
  );
}

export const useEditOptionThemeColors = () => {
  return useMutation((payload) => editOptionsThemeColors(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message)
      } else {
        message.success(data?.message)
      }
    },
    onError: (error) => {
      message.error((error && error.message) || 'Failed save data to server!')
    },
  })
}
