import { message } from 'antd'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import {
  addFinanceProduct,
  archiveFinanceProduct,
  deleteFinanceProduct,
  editFinanceProduct,
  editUnitConversion,
  getConversionProduct,
  getFinanceProduct,
  getFinanceProductMovementByCategoryChart,
  getFinanceProductRoles,
  getFinanceProducts,
  getFinanceProductSalesPurchaseChartDetail,
  getFinanceProductSerialNumbers,
  getFinanceProductsOverview,
  getFinanceProductStockMovementChart,
  getFinanceProductStockMovementChartDetail,
  getFinanceProductStockMovements,
  getFinanceProductTransactions,
  getFinanceProductValueMovementChart,
  getProductDetailSuggestion,
  getProductPerCategoryStats,
  getProductsSummary,
  getProductSummary,
  getProductWarehouseQtyStats,
  getStockPerCategoryStats,
  getStockProduct,
  getStockStatusStats,
  getSuggestionBarcodeFinanceProduct,
  getSuggestionFinanceProduct,
  unarchiveFinanceProduct,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useProductsSuggestion = (payload, apiOptions, RQOptions) => {
  return useQuery(
    ['products-suggestion', payload],
    async () => {
      const {
        data: { data },
      } = await getSuggestionFinanceProduct(payload, apiOptions)
      return data
    },
    {
      ...RQOptions,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export const useProducts = ({ payload = {}, enabled = false, savePerPage = true }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (savePerPage && payload.per_page && payload.per_page !== options.products_per_page_default) {
    dispatch(
      editOption({
        products_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['product', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProducts(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useProductStockMovements = ({ payload = {}, showColumns, options = {} }) => {
  return useQuery(
    ['productStockMovements', payload.id, payload, showColumns],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductStockMovements(payload.id, payload.params)
      return data
    },
    {
      ...options,
      onError: (error) => {
        message.error(error?.data?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useProductOverview = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['product', 'overview', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductsOverview(payload)
      return data
    },
    {
      enabled,
      // keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function useProductDetail({ id, params = {}, options }) {
  return useQuery(
    ['product_detail', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceProduct(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    options,
  )
}

export function useProduct({ id, payload = {}, enabled = false, options = {} }) {
  const queryKey = ['product', id]
  if (!_.isEmpty(payload)) {
    queryKey.push(payload)
  }
  return useQuery(
    queryKey,
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceProduct(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useConversionProduct(payload, options = {}) {
  return useQuery(
    ['productConvertion', payload.id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getConversionProduct(payload.id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!payload.id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductStockMovementChart = (payload, options = {}) => {
  return useQuery(
    ['productStockMovementChart', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductStockMovementChart(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductMovementByCategoryChart = (payload, options = {}) => {
  return useQuery(
    ['productMovementByCategoryChart', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductMovementByCategoryChart(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductValueMovementChart = (payload, options = {}) => {
  return useQuery(
    ['productValueMovementChart', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductValueMovementChart(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useProductRoles({ id, enabled = false, options }) {
  return useQuery(
    ['product-roles', id],
    async () => {
      let response
      try {
        response = (await getFinanceProductRoles(id)).data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useSuggestionBarcodeProduct(payload, options = {}) {
  return useQuery(
    ['suggestionBarcodeProduct', payload],
    async () => {
      let response
      try {
        const { data } = await getSuggestionBarcodeFinanceProduct(payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    options,
  )
}

export const useMutateSuggestionBarcodeProduct = (options = {}) => {
  return useMutation((payload) => getSuggestionBarcodeFinanceProduct(payload), options)
}

export const useProductSummary = (id, options = {}) => {
  return useQuery(
    ['productSummary', id],
    async () => {
      const {
        data: { data },
      } = await getProductSummary(id)
      return data
    },
    {
      enabled: !!id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductSalesPurchaseStats = (id, params = {}, options = {}) => {
  return useQuery(
    ['productSalesPurchaseStats', id, params],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductSalesPurchaseChartDetail(id, params)
      return data
    },
    {
      enabled: !!id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductStockMovementStats = (id, params = {}, options = {}) => {
  return useQuery(
    ['productStockMovementStats', id, params],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductStockMovementChartDetail(id, params)
      return data
    },
    {
      enabled: !!id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductWarehouseQtyStats = (id, options = {}) => {
  return useQuery(
    ['productWarehouseQtyStats', id],
    async () => {
      const {
        data: { data },
      } = await getProductWarehouseQtyStats(id)
      return data
    },
    {
      enabled: !!id,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductDetailStockMovements = (id, params = {}, options = {}) => {
  return useQuery(
    ['productDetailStockMovements', id, params],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductStockMovements(id, params)
      return data
    },
    {
      enabled: !!id,
      onError: (error) => {
        message.error((error && error.message) || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useProductTransactions = (id, params = {}, options = {}) => {
  return useQuery(
    ['productTransactions', id, params],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductTransactions(id, params)
      return data
    },
    {
      enabled: !!id,
      onError: (error) => {
        message.error((error && error.message) || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useProductStock = (payload, options = {}) => {
  return useQuery(
    ['productStock', payload],
    async () => {
      const {
        data: { data },
      } = await getStockProduct(payload.id, payload.warehouse_ids, payload.trans_date)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useStockStatusStats = (payload, options = {}) => {
  return useQuery(
    ['stockStatusStats', payload],
    async () => {
      const {
        data: { data },
      } = await getStockStatusStats(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useStockPerCategoryStats = (payload, options = {}) => {
  return useQuery(
    ['stockPerCategoryStats', payload],
    async () => {
      const {
        data: { data },
      } = await getStockPerCategoryStats(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductPerCategoryStats = (payload, options = {}) => {
  return useQuery(
    ['productPerCategoryStats', payload],
    async () => {
      const {
        data: { data },
      } = await getProductPerCategoryStats(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useProductsSummary = ({ payload = {} }, options) => {
  return useQuery(
    ['productsSummary', payload],
    async () => {
      const {
        data: { data },
      } = await getProductsSummary(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useDeleteProduct = () => {
  return useMutation((payload) => deleteFinanceProduct(payload.id))
}

export const useArchiveProduct = () => {
  return useMutation((payload) => archiveFinanceProduct(payload.id))
}

export const useUnArchiveProduct = () => {
  return useMutation((payload) => unarchiveFinanceProduct(payload.id))
}

export const useEditProduct = () => {
  return useMutation((payload) => editFinanceProduct(payload))
}

export const useAddProduct = () => {
  return useMutation((payload) => addFinanceProduct(payload))
}

export const useEditUnitConversion = () => {
  return useMutation(({ id, items }) => editUnitConversion(id, items))
}

export const useInfiniteProductSuggestion = (payload, options = {}, apiOptions = {}) => {
  return useInfiniteQuery(
    ['infinite/products/suggestion', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getSuggestionFinanceProduct({ page: pageParam, ...payload }, apiOptions)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const useInfiniteProductSerialNumbersSuggestion = (payload, options = {}) => {
  return useInfiniteQuery(
    ['infinite/products/serialnumbers/suggestion', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceProductSerialNumbers({ page: pageParam, ...payload })
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }

        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const useProductSerialNumbers = (payload, options) => {
  return useQuery(
    ['product/serial_numbers', payload.id, payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceProductSerialNumbers(payload)
      return data
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useProductDetailSuggestion(id, params, options) {
  return useQuery(
    ['product_detail_suggestion', id, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getProductDetailSuggestion(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    options,
  )
}
