import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { optionsSelector } from 'redux/selectors'

// Hook untuk handle format date dari options
const useFormatting = () => {
  const options = useSelector(optionsSelector)

  const formatDate = useCallback(
    (date = new Date(), format) => {
      return dayjs(date).format(format || options?.property_date_format)
    },
    [options],
  )

  const formatTime = useCallback(
    (date = new Date()) => {
      return dayjs(date).format(options?.property_time_format)
    },
    [options],
  )

  const toDbFormat = useCallback((date) => dayjs(date).format('YYYY-MM-DD'), [])

  return { formatDate, formatTime, formatDateText: options?.property_date_format, toDbFormat }
}

export default useFormatting
