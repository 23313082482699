import React from 'react'
import { Card as CardAntd, Skeleton } from 'antd'
import PropTypes from 'prop-types'

function Card({ loading = false, ...props }) {
  const newExtra = props.extra ? (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.extra.map((e) => (
        <div key={e.key} style={{ margin: 2 }}>
          {e}
        </div>
      ))}
    </div>
  ) : null

  return (
    <CardAntd
      {...props}
      title={loading ? <Skeleton.Input style={{ width: 300 }} active /> : props.title}
      extra={newExtra}
    />
  )
}

Card.propTypes = {
  // eslint-disable-next-line react/require-default-props
  loading: PropTypes.bool,
}

export default Card
