import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

export const initializeFp = (email) => {
  if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
    const advancedMatching = email ? { em: email } : {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    }
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options)
  }
}

export const initializeTagManager = () => {
  if (process.env.REACT_APP_GOOGLE_TAG_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
    }

    TagManager.initialize(tagManagerArgs)
  }
}

export const trackingPage = (page) => {
  if (process.env.NODE_ENV === 'production') {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page,
      },
      dataLayerName: 'PageDataLayer',
    })
    ReactPixel.pageView(page)
  }
}

export const trackingEvent = (data = { reactPixel: {}, gtm: {} }) => {
  if (process.env.NODE_ENV === 'production') {
    TagManager.dataLayer(data.gtm)
    ReactPixel.track(data.reactPixel?.eventName, data.reactPixel?.data)
  }
}
