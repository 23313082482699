import { message } from 'antd'

import { editCompanyName } from 'redux/companies/actions'
import * as actionTypes from 'redux/actionTypes'
import { confirmsEmail, deleteLockDate, onBoarding, setLockDate, updateOptions } from 'utils/apis'

export const setOptions = (payload) => {
  return {
    type: actionTypes.SET_OPTIONS,
    payload,
  }
}

export const editOptionsRedux = (payload) => {
  return {
    type: actionTypes.EDIT_OPTIONS,
    payload,
  }
}

export const editOptions = (payload, loadingMessage, successMessage) => {
  return (dispatch) => {
    let loading
    if (loadingMessage) {
      loading = message.loading(loadingMessage)
    }
    return updateOptions(payload).then((response) => {
      if (typeof loading === 'function') {
        loading()
      }

      if (successMessage) {
        message.success(successMessage)
      }

      dispatch(editOptionsRedux(response.data.data))
      if (response.data.data.property_name) {
        dispatch(editCompanyName(response.data.data.property_name))
      }

      return response
    })
  }
}

export const editOption = (payload, withEditCompanyName) => {
  return async (dispatch, getState) => {
    const prevOption = await getState().options.options
    dispatch(editOptionsRedux(payload))
    if (withEditCompanyName) {
      dispatch(editCompanyName(payload.property_name))
    }
    return updateOptions(payload).catch(() => {
      Object.keys(payload).forEach((name) => {
        payload[name] = prevOption[name]
      })
      if (withEditCompanyName) {
        dispatch(editCompanyName(prevOption.property_name))
      }
      dispatch(editOptionsRedux(payload))
    })
  }
}

export const confirmEmail = (payload) => {
  return async (dispatch) => {
    return confirmsEmail(payload).then((response) => {
      dispatch(editOptionsRedux({ email_confirmed: '1' }))

      return response
    })
  }
}

export const setLockDateRedux = (payload) => {
  return {
    type: actionTypes.SET_LOCK_DATE_OPTION,
    payload,
  }
}

export const removeLockDateRedux = (payload) => {
  return {
    type: actionTypes.REMOVE_LOCK_DATE_OPTION,
    payload,
  }
}

export const setLockDateOption = (payload) => {
  return (dispatch) => {
    return setLockDate(payload).then((response) => {
      if (response.data.success) {
        dispatch(setLockDateRedux({ lock_date: response.data.data }))
      }

      return response
    })
  }
}

export const removeLockDateOption = () => {
  return (dispatch) => {
    return deleteLockDate().then((response) => {
      if (response.data.success) {
        dispatch(removeLockDateRedux())
      }

      return response
    })
  }
}

export const onBoardingProcess = (payload) => {
  return (dispatch) => {
    return onBoarding(payload.redux).then((response) => {
      if (response.data.success) {
        dispatch(editOptionsRedux(payload.redux))
      }
      return response
    })
  }
}

export const editOptionRedux = (payload) => {
  return {
    type: actionTypes.EDIT_OPTION,
    payload,
  }
}
