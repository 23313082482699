import axios from 'axios'
import { stringify } from 'query-string'

export const getUsers = (payload) => {
  const query = stringify(payload)
  return axios.get(`/users?${query}`)
}

export const addUser = (data) => {
  return axios.post(`/users`, data)
}

export const updateUser = (data, id) => {
  return axios.put(`/users/${id}`, data)
}

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`)
}

export const resendInvitation = ({ id }) => {
  return axios.post(`/users/resendInvitation/${id}`)
}
