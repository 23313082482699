import * as api from 'utils/apis/shippingComp'
import * as actionTypes from 'redux/actionTypes'

export const getCanDeletedShippingCompsRedux = (payload) => {
  return {
    type: actionTypes.GET_CAN_DELETED_SHIPPING_COMPS,
    payload,
  }
}

export const getCanDeletedShippingComps = () => {
  return (dispatch) => {
    return api.getCanDeletedShippingComp().then((response) => {
      dispatch(getCanDeletedShippingCompsRedux(response.data.data))
      return response
    })
  }
}

export const setShippingComps = (payload) => {
  return {
    type: actionTypes.SET_SHIPPING_COMPS,
    payload,
  }
}

export const addShippingCompRedux = (payload) => {
  return {
    type: actionTypes.ADD_SHIPPING_COMP,
    payload,
  }
}

export const editShippingCompRedux = (payload) => {
  return {
    type: actionTypes.EDIT_SHIPPING_COMP,
    payload,
  }
}

export const addShippingComp = (payload) => {
  return (dispatch) => {
    return api.addShippingComp(payload).then((response) => {
      dispatch(addShippingCompRedux(response.data.data))
      return response
    })
  }
}

export const editShippingComp = (payload) => {
  return (dispatch) => {
    return api.editShippingComp(payload).then((response) => {
      dispatch(editShippingCompRedux(response.data.data))
      return response
    })
  }
}

export const deleteShippingCompRedux = (payload) => {
  return {
    type: actionTypes.DELETE_SHIPPING_COMP,
    payload,
  }
}

export const deleteShippingComp = (payload) => {
  return (dispatch) => {
    return api.deleteShippingComp(payload).then((response) => {
      dispatch(deleteShippingCompRedux(payload.id))

      return response
    })
  }
}

export const moveShippingCompRedux = (payload) => {
  return {
    type: actionTypes.MOVE_SHIPPING_COMP,
    payload,
  }
}

export const moveShippingComp = (payload) => {
  return (dispatch) => {
    dispatch(moveShippingCompRedux(payload))

    return api
      .moveShippingComp(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveShippingCompRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const changeStatusShippingCompRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_SHIPPING_COMP,
    payload,
  }
}

export const changeStatusShippingComp = (payload) => {
  return (dispatch) => {
    dispatch(changeStatusShippingCompRedux(payload))

    if (payload.checked) {
      return api.activateShippingComp(payload.id)
    }

    return api.deactivateShippingComp(payload.id)
  }
}

export const filterShippingComp = (payload) => {
  return {
    type: actionTypes.FILTER_SHIPPING_COMP,
    payload,
  }
}
