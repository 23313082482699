import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { getFinanceBankStatements, multiBankReconciliation } from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useBankStatements = ({ payload = {}, enabled = false, savePerPage = true }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (savePerPage && payload.per_page && payload.per_page !== options.banks_per_page_default) {
    dispatch(
      editOption({
        banks_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['bankStatement', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceBankStatements(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useMultiBankReconciliation = () => {
  return useMutation((payload) => multiBankReconciliation(payload))
}
