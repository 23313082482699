import { message } from 'antd'
import { useQuery } from 'react-query'
import { getPurchaseReturn, getPurchaseReturns } from 'utils/apis'

export const usePurchaseReturns = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['purchaseRetur', payload],
    async () => {
      const {
        data: { data },
      } = await getPurchaseReturns(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}

export function usePurchaseRetur({ id, payload = {}, enabled = false }) {
  return useQuery(
    ['purchaseRetur', id, payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getPurchaseReturn(id, payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}
