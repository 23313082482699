import React from 'react'
import { useSelector } from 'react-redux'

import { mobileViewSelector } from 'redux/selectors'

import ProfileMenu from './ProfileMenu'

const NoCompanyTopBar = () => {
  const isMobileView = useSelector(mobileViewSelector)
  return (
    <div className="topbar" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ height: 64 }}>
        {isMobileView ? (
          <img height={30} src="favicon.png" alt="Kledo" />
        ) : (
          <img height={30} src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
        )}
      </div>
      <ProfileMenu />
    </div>
  )
}

export default NoCompanyTopBar
