import { useCallback, useMemo } from 'react'

import styles from './chart-legend.module.scss'

const useChartLegend = ({ horizontalLegendScreens = [] }) => {
  const getOrCreateLegendList = useCallback(
    ({ id }) => {
      const legendContainer = document.getElementById(id)
      let listContainer = legendContainer.querySelector('ul')
      if (!listContainer) {
        listContainer = document.createElement('ul')
        listContainer.style.display = 'flex'
        listContainer.style.flexDirection = horizontalLegendScreens.includes(true)
          ? 'row'
          : 'column'
        listContainer.style.justifyContent = horizontalLegendScreens.includes(true) ? 'center' : ''
        listContainer.style.margin = '0'
        listContainer.style.padding = '0'
        listContainer.style.flexWrap = 'wrap'

        legendContainer.appendChild(listContainer)
      }

      return listContainer
    },
    [horizontalLegendScreens],
  )

  return useMemo(
    () => ({
      id: 'htmlLegend',
      afterUpdate(chart, args, options) {
        if (options.display === false) return

        const ul = getOrCreateLegendList({
          id: options.containerID,
        })

        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove()
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart)

        items.forEach((item) => {
          const li = document.createElement('li')
          li.style.alignItems = 'center'
          li.style.cursor = 'pointer'
          li.style.display = 'flex'
          li.style.flexDirection = 'row'
          li.style.marginLeft = '10px'
          li.style.cursor = 'pointer'

          li.onclick = () => {
            const { type } = chart.config
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index)
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex),
              )
            }
            chart.update()
          }

          // Color box
          const boxSpan = document.createElement('span')
          boxSpan.style.background = item.fillStyle
          boxSpan.style.borderColor = item.strokeStyle
          boxSpan.style.borderWidth = `${item.lineWidth}px`
          boxSpan.style.display = 'inline-block'
          boxSpan.style.height = '10px'
          boxSpan.style.marginRight = '10px'
          boxSpan.style.width = '35px'
          boxSpan.style.minWidth = '35px'
          boxSpan.style.maxWidth = '35px'

          // Text
          const textContainer = document.createElement('p')
          textContainer.style.color = item.fontColor
          textContainer.classList.add(styles.legendText)
          textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

          const text = document.createTextNode(item.text)
          textContainer.appendChild(text)

          li.appendChild(boxSpan)
          li.appendChild(textContainer)
          ul.appendChild(li)
        })
      },
    }),
    [getOrCreateLegendList],
  )
}

export default useChartLegend
