import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Card, Form, Popover, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { DropdownDate } from 'containers/GridDashboard/DropdownDate'
import TagSuggestion from 'containers/FinanceTag/TagSuggestion'
import UserSuggestion from 'containers/Users/UserSuggestion'
import { cleanBlankValue } from 'utils/helper'
import { usePrevious } from 'utils/hooks'
import { LinkTransType } from 'components/UI'
import { darkModeSelector } from 'redux/selectors'
import styles from './card-with-with-filter.module.scss'

const ComponentLinkTitle = ({ title, ...props }) => {
  const darkMode = useSelector(darkModeSelector)

  if (!props.transTypeId) {
    return (
      <Typography.Text
        style={{ fontWeight: 'bold' }}
        className={['text-uppercase font-size-16', props.className].join(' ')}
      >
        {title}
      </Typography.Text>
    )
  }

  return (
    <LinkTransType
      title={
        <strong className={`text-uppercase font-size-16 ${darkMode ? styles.textLinkDark : ''}`}>
          {title}
        </strong>
      }
      className={styles.titleOnPrint}
      {...props}
    />
  )
}

const ChartCardWithFilter = ({
  children,
  loading,
  title,
  titleProps,
  subtitle,
  intl,
  withFilter = false,
  withDateFilter,
  filter = {},
  params = {},
  initialParams,
  onParamsChange,
  withCustomDaterange,
  withCustomDateType = true,
  dateTypes = ['daily', 'monthly', 'yearly'],
  cardHeaderMarginBottom = 15,
  onRenderTitle,
  forceCustomDaterange,
  placeholderDateFrom,
  formatPickerDateFrom,
  pickerTypeDateFrom,
  placeholderDateTo,
  formatPickerDateTo,
  pickerTypeDateTo,
  daterangeOnly,
  dateOnly,
  cardClassName,
  cardProps = {},
  extra = [],
  innerRef,
  legacy = false,
  customDateType,
  customDateTypeKey,
}) => {
  const filterFormRef = useRef()

  const prevParams = usePrevious(params)

  const dateTypeKey = legacy ? 'daterange' : 'movement_compare'

  useEffect(() => {
    if (filterFormRef.current && !_.isEqual(params, prevParams)) {
      filterFormRef.current.setFieldsValue(params)
    }
  }, [params, prevParams])

  const dropdownDateTypeData = useMemo(() => {
    const currentData = {
      daily: intl.formatMessage({ id: 'date.daily' }),
      monthly: intl.formatMessage({ id: 'date.monthly' }),
      yearly: intl.formatMessage({ id: 'date.yearly' }),
      today: intl.formatMessage({ id: 'date.today' }),
      yesterday: intl.formatMessage({ id: 'date.yesterday' }),
      this_month: intl.formatMessage({ id: 'date.this_month' }),
      last_month: intl.formatMessage({ id: 'date.last_month' }),
      this_year: intl.formatMessage({ id: 'date.this_year' }),
      last_year: intl.formatMessage({ id: 'date.last_year' }),
    }

    return dateTypes.map((dateKey) => ({
      name: dateKey,
      title: currentData[dateKey],
    }))
  }, [dateTypes, intl])

  const renderTitle = () => {
    if (typeof onRenderTitle === 'function') {
      return onRenderTitle({
        LinkTitle: ComponentLinkTitle,
      })
    }

    return <ComponentLinkTitle title={title} {...titleProps} />
  }

  const changeDateRangeHandler = useCallback(
    (value) => {
      onParamsChange({
        ...value,
        [dateTypeKey]: value[dateTypeKey],
        custom_daterange: value.custom_daterange,
        date_from: value.date_from ? dayjs(value.date_from) : null,
        date_to: value.date_to ? dayjs(value.date_to) : null,
      })
    },
    [dateTypeKey, onParamsChange],
  )

  const filterVisibleChangeHandler = (visible) => {
    if (!visible) {
      const form = filterFormRef.current
      form.validateFields().then((values) => onParamsChange(values))
    }
  }

  const renderFilter = useCallback(() => {
    const initialValues = {}
    if (filter.tag_ids) initialValues.tag_ids = params.tag_ids
    if (filter.sales_id) initialValues.sales_id = params.sales_id

    return (
      <Form
        layout="vertical"
        name="FilterForm"
        ref={filterFormRef}
        initialValues={initialValues}
        style={{ width: 300 }}
      >
        {filter.tag_ids ? (
          <TagSuggestion form={filterFormRef.current} name="tag_ids" withExtra={false} />
        ) : null}
        {filter.sales_id ? <UserSuggestion name="sales_id" form={filterFormRef.current} /> : null}
      </Form>
    )
  }, [filter, params])

  const onClearFilter = useMemo(() => {
    const unClearFilter = {
      [dateTypeKey]: params[dateTypeKey],
      custom_daterange: params.custom_daterange,
      date_from: params.date_from ? dayjs(params.date_from).format('DD MM YYYY') : null,
      date_to: params.date_to ? dayjs(params.date_to).format('DD MM YYYY') : null,
    }
    const initialValues = {}
    const currentParams = {}
    if (filter.tag_ids) {
      initialValues.tag_ids = initialParams.tag_ids
      currentParams.tag_ids = params.tag_ids
    }
    if (filter.sales_id) {
      initialValues.sales_id = initialParams.sales_id
      currentParams.sales_id = params.sales_id
    }
    return {
      showClearFilter: !_.isEqual(
        cleanBlankValue({ ...currentParams, ...unClearFilter }),
        cleanBlankValue({ ...initialValues, ...unClearFilter }),
      ),
      onClear: () =>
        onParamsChange({
          ...initialValues,
          ...unClearFilter,
          date_from: dayjs(params.date_from),
          date_to: dayjs(params.date_to),
        }),
    }
  }, [dateTypeKey, filter, initialParams, onParamsChange, params])

  return (
    <Card
      style={{
        borderRadius: 10,
      }}
      loading={loading}
      className={cardClassName}
      ref={innerRef}
      {...cardProps}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: subtitle ? 'start' : 'center',
          marginBottom: cardHeaderMarginBottom,
        }}
      >
        <Space size={0} direction="vertical">
          <div className="utils__title utils__title--flat">{renderTitle()}</div>
          <span>{subtitle}</span>
        </Space>

        <Space
          style={{
            paddingTop: subtitle ? '3px' : undefined,
          }}
        >
          {extra.map((_extra) => _extra)}
          {withFilter ? (
            <>
              <Popover
                style={{ alignItems: 'center' }}
                placement="bottomLeft"
                content={renderFilter}
                trigger="click"
                onOpenChange={filterVisibleChangeHandler}
              >
                <Button icon={<FilterOutlined />} type="text" />
              </Popover>
              {onClearFilter.showClearFilter && (
                <Button onClick={onClearFilter.onClear} icon={<CloseOutlined />} danger>
                  {intl.formatMessage({ id: 'button.clear_filter' })}
                </Button>
              )}
            </>
          ) : null}

          {withDateFilter ? (
            <DropdownDate
              intl={intl}
              setDateRange={changeDateRangeHandler}
              selectedKeys={[params[dateTypeKey]]}
              dateType={params[dateTypeKey]}
              data={dropdownDateTypeData}
              params={{
                [dateTypeKey]: params[dateTypeKey],
                custom_daterange: params.custom_daterange,
                date_from: params.date_from,
                date_to: params.date_to,
              }}
              withCustomDaterange={withCustomDaterange}
              withCustomDateType={withCustomDateType}
              forceCustomDaterange={forceCustomDaterange}
              legacy={legacy}
              placeholderDateFrom={placeholderDateFrom}
              formatPickerDateFrom={formatPickerDateFrom}
              pickerTypeDateFrom={pickerTypeDateFrom}
              placeholderDateTo={placeholderDateTo}
              formatPickerDateTo={formatPickerDateTo}
              pickerTypeDateTo={pickerTypeDateTo}
              daterangeOnly={daterangeOnly}
              dateOnly={dateOnly}
              customDateType={customDateType}
              customDateTypeKey={customDateTypeKey}
            />
          ) : null}
        </Space>
      </div>
      {children}
    </Card>
  )
}

const Component = injectIntl(ChartCardWithFilter)

export default React.forwardRef((props, ref) => <Component {...props} innerRef={ref} />)
