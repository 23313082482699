import _ from 'lodash'
import store from 'store'

export const storedSettings = (payload) => {
  const settings = {}
  Object.keys(payload).forEach((key) => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : payload[key]
  })
  return settings
}

export const setSettingsToLocalStorage = (payload) => {
  Object.keys(payload).forEach((setting) => {
    store.set(`app.settings.${setting}`, payload[setting])
  })
}

// Untuk pengecekan apakah keynya valid ada websiteid nya, untuk menghapus yg lama
const isValidKey = (key) => {
  const spliteKey = key.split(':')
  if (Array.isArray(spliteKey)) {
    // Index 0 website id, ke 1 uuid
    if (!_.isEmpty(spliteKey[0]) && !_.isEmpty(spliteKey[1])) {
      return true
    }
  }
  return false
}

export const setDraftTransaction = (name, key, data) => {
  if (!_.isEmpty(name) && isValidKey(key)) {
    const storeDate = store.get(name)
    let storedData = storeDate ? JSON.parse(storeDate) : undefined
    if (storedData) {
      if (storedData[key]) {
        storedData[key] = { ...storedData[key], ...data }
      } else {
        storedData[key] = data
      }
    } else {
      storedData = {}
      storedData[key] = data
    }
    store.set(name, JSON.stringify(storedData)) // 3 hari
  }
}
export const getDraftTransaction = (name, key) => {
  const storeDate = store.get(name)
  const data = storeDate ? JSON.parse(storeDate) : undefined

  if (!data) {
    return undefined
  }
  if (!key) {
    return data
  }
  if (data[key]) {
    return data[key]
  }
  return undefined
}

export const removeTempTransaction = (name, key) => {
  if (!_.isEmpty(name) && !_.isEmpty(key)) {
    const storeDate = store.get(name)
    const data = storeDate ? JSON.parse(storeDate) : undefined
    if (data) {
      if (Object.keys(data).length === 1) {
        store.remove(name)
      } else if (data[key]) {
        delete data[key]
        store.set(name, JSON.stringify(data), { expires: 3 }) // 3 hari
      }
    }
  }
}

export const cleanInvalidDraftTransaction = (name) => {
  if (!_.isEmpty(name)) {
    const storeDate = store.get(name)
    const data = storeDate ? JSON.parse(storeDate) : undefined
    if (data) {
      if (typeof data === 'object') {
        Object.keys(data).forEach((key) => {
          if (!isValidKey(key)) {
            removeTempTransaction(name, key)
          }
        })
      }
    }
  }
}
