import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import {
  getFinancePaymentConnectSetting,
  setDefaultPaymentConnectSetting,
  testConnectionFinancePaymentConnectSetting,
  updatePaymentConnectSetting,
} from 'utils/apis'

export const useFinancePaymentConnectSetting = ({ id, options }) => {
  return useQuery(
    ['paymentConnectSetting', id],
    async () => {
      const {
        data: { data },
      } = await getFinancePaymentConnectSetting(id)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useTestConnectionPaymentConnectSettingMutation = () => {
  return useMutation((payload) => testConnectionFinancePaymentConnectSetting(payload))
}

export const useUpdatePaymentConnectSettingMutation = () => {
  return useMutation((payload) => updatePaymentConnectSetting(payload))
}

export const useSetDefaultPaymentConnectSettingMutation = () => {
  return useMutation((payload) => setDefaultPaymentConnectSetting(payload.id, payload.data))
}
