import React, { useCallback } from 'react'
import { Divider } from 'antd'
// import PropTypes from 'prop-types'
import { PlusOutlined } from '@ant-design/icons'

function DropdownItemAction({
  menu = null,
  icon = <PlusOutlined />,
  title = '',
  onClick = () => {},
}) {
  const mouseDownHandler = useCallback((e) => e.preventDefault(), [])
  return (
    <div style={{ marginBottom: 8 }}>
      {menu}
      <Divider style={{ margin: '4px 0' }} />
      <a href="#" onMouseDown={mouseDownHandler} onClick={onClick} style={{ marginLeft: 8 }}>
        {icon} {title}
      </a>
    </div>
  )
}

// DropdownItemAction.propTypes = {
//   menu: PropTypes.node,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   onClick: PropTypes.func,
// }

// DropdownItemAction.defaultProps = {
//   menu: null,
//   icon: <PlusOutlined />,
//   title: '',
//   onClick: () => {},
// }

export default DropdownItemAction
