import React, { Component } from "react";
import { connect } from 'react-redux';

// HOC for get translation from redux
const withTranslation = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
        />
      )
    }
  };

  return connect(state => {
    return {
      translation: state.translation,
    }
  }, {})(HOC);
};

export default withTranslation;
