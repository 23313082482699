import React from 'react';
import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { darkModeSelector } from 'redux/selectors';

const ProductTour = ({innerRef, ...props}) => {
  const darkMode = useSelector(darkModeSelector)

  return (
    <Joyride
      ref={innerRef}
      continuous
      scrollToFirstStep
      showProgress
      showSkipButton={false}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: darkMode ? '#1f1f1f' : 'white',
          backgroundColor: darkMode ? '#1f1f1f' : 'white',
          textColor: darkMode ? 'white' : '#1f1f1f',
          primaryColor: '#4777F3',
        },
        tooltipContent: {
          fontSize: 16,
          lineHeight: 1.6
        }
      }}
      spotlightPadding={0}
      {...props}
    />
  );
};

const Component = React.forwardRef((props, ref) => <ProductTour innerRef={ref} {...props} />);
export default React.memo(Component);
