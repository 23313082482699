import axios from 'axios'
import { stringify } from 'query-string'

export const getBillingTransaction = (params) => {
  const query = stringify(params)
  return axios.get(`/billings/transactions?${query}`)
}

export const getBillingUpgradeInfo = () => {
  return axios.get('/billings/upgrade')
}

export const getBillingDowngrade = () => {
  return axios.get('/billings/downgrade')
}

export const postBillingDowngrade = ({ params }) => {
  const query = stringify(params)
  return axios.post(`/billings/downgrade?${query}`)
}

export const getBillingUpgradeAmount = (params) => {
  return axios.get(`/billings/upgrade/amount`, { params })
}

export const upgradeBillingPlan = (data) => {
  return axios.post('/billings/upgrade', data)
}

export const getBillingRevewInfo = () => {
  return axios.get('/billings/renew')
}

export const getBillingRenewAmount = (params) => {
  return axios.get(`/billings/renew/amount`, { params })
}

export const deleteBilling = (id) => {
  return axios.delete(`/billings/transactions/${id}`)
}

export const getBillingToken = (id) => {
  return axios.get(`/billings/transactions/${id}/checkout`)
}

export const renewBillingPlan = (data) => {
  return axios.post('/billings/renew', data)
}

export const getBillingAddonInfo = () => {
  return axios.get('/billings/addon')
}

export const getBillingAddonAmount = (params) => {
  return axios.get(`/billings/addon/amount`, {
    params,
    // paramsSerializer: param => {
    //   console.log(param);
    //   return stringify(param)
    // }
  })
}

export const upgradeBillingAddon = (data) => {
  return axios.post('/billings/addon', data)
}

export const getBillingUsage = () => {
  return axios.get('/billings/usage')
}

export const getBillingPosAddonAmount = ({ params }) => {
  const query = stringify(params)
  return axios.get(`/billings/pos/addon/amount?${query}`)
}

export const upgradeBillingPosAddon = (data) => {
  return axios.post('/billings/pos/addon', data)
}

export const checkReferralCode = (data) => {
  return axios.post('/billings/checkReferralCode', data)
}

export const getBillingStamp = () => {
  return axios.get('/billings/stamps')
}

export const getBillingStampTransaction = (params) => {
  const query = stringify(params)
  return axios.get(`/billings/stamps/transactions?${query}`)
}
