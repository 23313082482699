import * as actionTypes from 'redux/actionTypes'

const initialState = {
  loading: false,
  loadingProduct: false,
  data: {},
  dataProduct: {},
}

const storePosSettings = (state, action) => ({ ...state, ...action.data })

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FINANCE_PRODUCTS:
      return storePosSettings(state, action)
    default:
      return state
  }
}

export default reducer
