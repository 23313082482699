import React from 'react'
import { injectIntl } from 'react-intl'
import { Alert, Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import store from 'store'
import NProgress from 'nprogress'
import dayjs from 'dayjs'

import { initsPublic } from 'redux/actions'
import { changeSetting } from 'redux/settings/actions'
import { hideLoginForm } from 'redux/user/actions'
import ChangeEnvModal from 'components/apps/User/ChangeEnvModal'
import { allowedLocales } from 'utils/static'
import styles from './style.module.scss'

const mapDispatchToProps = (dispatch) => {
  return {
    onInits: () => dispatch(initsPublic()),
    onChangeSetting: (payload) => dispatch(changeSetting(payload)),
    hideLoginModal: () => dispatch(hideLoginForm()),
  }
}

const mapStateToProps = ({ options: { options }, inits: { loading }, settings: { locale } }) => ({
  property: options,
  loading,
  locale,
})

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 7,
    backgroundEnabled: false,
    networkErr: false,
    countClick: 0,
    showEnvModal: false,
  }

  componentDidMount() {
    const { property, locale, onChangeSetting } = this.props
    this.props.hideLoginModal()
    store.remove('app.endpoint')
    this.onInit()
    if (!isEmpty(property) && locale !== property.property_language) {
      onChangeSetting({
        setting: 'locale',
        value: locale && allowedLocales.includes(locale) ? locale : property.property_language,
      })
    }
  }

  onInit = async () => {
    const { onInits } = this.props
    const r = await onInits()
    if (r === false) {
      this.setState({ networkErr: true })
    }
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    })
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    })
  }

  renderLogo = () => {
    const { backgroundEnabled } = this.state
    const { property } = this.props
    if (backgroundEnabled) {
      if (!isEmpty(property.property_logo)) {
        return <img src={property.property_logo} alt={property.property_name} />
      }

      return <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
    }

    return <img src="resources/images/logo-inverse.png" alt="Kledo" />
  }

  clickLayout = () => {
    this.setState((prevState) => {
      const countClick = prevState.countClick + 1
      return { ...prevState, countClick, showEnvModal: countClick > 10 }
    })
  }

  closeEnvModal = () => this.setState({ countClick: 0, showEnvModal: false })

  render() {
    const { children, loading, intl } = this.props
    const { backgroundNumber, backgroundEnabled, networkErr, showEnvModal } = this.state

    if (loading) {
      NProgress.start()
    } else {
      NProgress.done()
    }

    return (
      <Layout>
        <Layout.Content>
          <div
            className={styles.layout}
            style={{
              backgroundImage: backgroundEnabled
                ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
                : `none`,
            }}
          >
            <div className={styles.content}>
              {networkErr ? (
                <div className={styles.inner}>
                  <Alert
                    message={intl.formatMessage({ id: 'users.failed_to_connect_to_kledo' })}
                    type="warning"
                    description={intl.formatMessage({
                      id: 'users.please_check_your_internet_connection_please_contact_wa',
                    })}
                    showIcon
                  />
                  <a href="https://kledo.com" className="btn mt-3">
                    &larr; {intl.formatMessage({ id: 'users.go_back_to_home_page' })}
                  </a>
                </div>
              ) : (
                children
              )}
            </div>
            <div
              aria-hidden="true"
              onClick={this.clickLayout}
              className={`${styles.footer} text-center`}
            >
              <div>
                Powered by
                <div className={styles.logoKledoFooter}>
                  <a target="_blank" rel="noopener noreferrer" href="https://kledo.com">
                    <img src="resources/images/logo-color-kledo-front.png" alt="Kledo" />
                  </a>
                </div>
              </div>

              <p>&copy;{` ${dayjs().format('YYYY')} Kledo. All rights reserved.`}</p>
            </div>
          </div>
        </Layout.Content>
        {showEnvModal && <ChangeEnvModal visible onCancel={this.closeEnvModal} />}
      </Layout>
    )
  }
}

export default injectIntl(LoginLayout)
