import axios from 'axios'

export const getCountries = (params) => {
  return axios.get('/countries', { params })
}

export const getProvinces = (params) => {
  return axios.get('/provinces', { params })
}

export const getCities = (params) => {
  return axios.get('/cities', { params })
}

export const getDistricts = (params) => {
  return axios.get('/districts', { params })
}

export const getVillages = (params) => {
  return axios.get('/villages', { params })
}
