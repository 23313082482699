import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'

import News from 'containers/Notification/News'
import { useResponsive } from 'utils/hooks'
import { useSelector } from 'react-redux'
import { darkModeSelector } from 'redux/selectors'
import styles from './style.module.scss'

const Actions = ({ intl }) => {
  const [visible, setVisible] = React.useState(false)
  const darkMode = useSelector(darkModeSelector)

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = (flag) => setVisible(flag)

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const menu = (
    <div
      className={`card cui__utils__shadow width-350 overflow-hidden border-0 ${
        darkMode ? 'bg-dark-3 text-light' : 'bg-light'
      }`}
    >
      <div className="card-body" style={stylePane}>
        <News />
      </div>
    </div>
  )
  return (
    <Tooltip
      open={visible ? false : undefined}
      title={intl.formatMessage({ id: 'topBar.latestKledoUpdate' })}
    >
      <div>
        <Dropdown
          dropdownRender={() => menu}
          trigger={['click']}
          placement="bottomLeft"
          open={visible}
          onOpenChange={visibleChangeHandler}
        >
          <div className={styles.dropdown}>
            <FontAwesomeIcon icon="bell" className={`${styles.icon}`} />
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  )
}

export default injectIntl(Actions)
