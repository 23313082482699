import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import { addFinanceContactGroupRoles, getFinanceContactGroupRoles } from 'utils/apis'

export function useContactGroupRoles({ id, options }) {
  return useQuery(
    ['contactGroups', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceContactGroupRoles({ id })
        response = data
      } catch (error) {
        throw new Error(error?.data?.message ?? 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useAddFinanceContactGroupRoles = () => useMutation(addFinanceContactGroupRoles)
