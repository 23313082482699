import { useQuery } from 'react-query'
import { message } from 'antd'

import { getFinanceTag, getFinanceTagDetail, getFinanceTagRoles } from 'utils/apis'

export function useTag({ id, params = {}, enabled = false, options = {} }) {
  return useQuery(
    ['tag', id, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceTag(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useTagRoles({ id, params = {}, enabled = false, options = {} }) {
  return useQuery(
    ['tag-roles', id, params],
    async () => {
      let response
      try {
        response = (await getFinanceTagRoles(id, params)).data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useTagDetail({ params, options, extraQueryKey }) {
  return useQuery(
    ['tags', params, extraQueryKey],
    async () => {
      let response
      try {
        response = await getFinanceTagDetail(params)
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response.data.data
    },
    options,
  )
}
