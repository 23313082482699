import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { makeCheckPermission } from 'redux/selectors'

/**
 *
 * @param {string} permission
 * @returns {boolean}
 */
const useMemoizeCheckPermission = ({ permission }) => {
  const checkPermission = useMemo(makeCheckPermission, [])
  const granted = useSelector((state) => checkPermission(state, permission))

  return granted
}

export default useMemoizeCheckPermission
