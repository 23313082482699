import {useMutation} from "react-query";
import { addFavoriteReport, deleteFavoriteReport } from 'utils/apis'

export const useAddFavoriteReport = ({ options = {} } = {}) => {
  return useMutation((payload) => addFavoriteReport(payload), options)
}

export const useDeleteFavoriteReport = ({ options = {} } = {}) => {
  return useMutation((payload) => deleteFavoriteReport(payload), options)
}
