import React, {useMemo, useState} from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { translationSelector } from 'redux/selectors'
import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { push } from 'connected-react-router'

const ReportDropdown = ({ intl, options, ...props }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { reportmenu } = useSelector(translationSelector);
  const pushPath = (path) => dispatch(push(path));
  const visibleChangeHandler = (flag) => setVisible(flag);
  const toggleHandler = () => setVisible(!visible);

  const items = [
    {
      key: 'report_balance_sheet',
      label: reportmenu.balance_sheet,
      onClick: () => pushPath('/reports/balance-sheet'),
      permission: 'report_balance_sheet',
    },
    {
      key: 'report_cash_flow',
      label: reportmenu.cash_flow,
      onClick: () => pushPath('/reports/cash-flow'),
      permission: 'report_cash_flow',
    },
    {
      key: 'report_profit_loss',
      label: reportmenu.profit_and_loss,
      onClick: () => pushPath('/reports/profit-loss'),
      permission: 'report_profit_loss',
    },
    {
      key: 'report_equity_movement',
      label: reportmenu.equity_movement,
      onClick: () => pushPath('/reports/equity-movement'),
      permission: 'report_equity_movement',
    },
    {
      key: 'report_executive_summary',
      label: reportmenu.executive_summary,
      onClick: () => pushPath('/reports/executive-summary'),
      permission: 'report_executive_summary',
    },
    {
      key: 'report_receivable_payable',
      label: reportmenu.payable_credit_per_contact,
      onClick: () => pushPath('/reports/receivable-payable-per-contact'),
      permission: 'report_receivable_payable',
    },
    {
      key: 'report_bank_summary',
      label: reportmenu.bank_summary,
      onClick: () => pushPath('/reports/bank-summary'),
      permission: 'report_bank_summary',
    },
    {
      key: 'report_general_ledger',
      label: reportmenu.general_ledger,
      onClick: () => pushPath('/reports/general-ledger'),
      permission: 'report_general_ledger',
    },
    {
      key: 'report_journal',
      label: reportmenu.journal,
      onClick: () => pushPath('/reports/journal'),
      permission: 'report_journal',
    },
    {
      key: 'report_trial_balance',
      label: reportmenu.trial_balance,
      onClick: () => pushPath('/reports/trial-balance'),
      permission: 'report_trial_balance',
    },
    {
      key: 'report_sales_detail',
      label: reportmenu.sales_detail,
      onClick: () => pushPath('/reports/sales-detail'),
      permission: 'report_sales_detail',
    },
    {
      key: 'report_aged_receivables',
      label: reportmenu.aged_receivables,
      onClick: () => pushPath('/reports/aged-receivable'),
      permission: 'report_aged_receivables',
    },
    {
      key: 'report_customer_invoice',
      label: reportmenu.customer_invoice,
      onClick: () => pushPath('/reports/customer-invoice'),
      permission: 'report_customer_invoice',
    },
    {
      key: 'report_profitability_product',
      label: reportmenu.profitability_product,
      onClick: () => pushPath('/reports/product-profitability'),
      permission: 'report_product_profitability',
    },
    {
      key: 'report_profitability_per_invoice',
      label: reportmenu.profitability_per_invoice,
      onClick: () => pushPath('/reports/profitability-per-invoice'),
      permission: 'report_profitability_per_invoice',
    },
    {
      key: 'report_income_per_customer',
      label: reportmenu.income_per_customer,
      onClick: () => pushPath('/reports/income-per-customer'),
      permission: 'report_income_per_customer',
    },
    {
      key: 'report_sales_per_product',
      label: reportmenu.sales_per_product,
      onClick: () => pushPath('/reports/sales-per-product'),
      permission: 'report_sales_per_product',
    },
    {
      key: 'report_order_per_product',
      label: reportmenu.order_per_product,
      onClick: () => pushPath('/reports/order-per-product'),
      permission: 'report_order_per_product',
    },
    {
      key: 'report_sales_per_sales_person',
      label: reportmenu.sales_per_sales_person,
      onClick: () => pushPath('/reports/sales-per-sales-person'),
      permission: 'report_sales_per_sales_person',
    },
    {
      key: 'report_sales_delivery',
      label: reportmenu.sales_delivery,
      onClick: () => pushPath('/reports/sales-delivery'),
      permission: 'report_sales_delivery',
    },
    {
      key: 'report_shipping_cost_per_expedition',
      label: reportmenu.shipping_cost_per_expedition,
      onClick: () => pushPath('/reports/shipping-cost-per-expedition'),
      permission: 'report_shipping_cost_per_expedition',
    },
    {
      key: 'report_invoice_payment',
      label: reportmenu.invoice_payment,
      onClick: () => pushPath('/reports/invoice-payment'),
      permission: 'report_invoice_payment',
    },
    {
      key: 'report_sales_per_product_category',
      label: reportmenu.sales_per_product_category,
      onClick: () => pushPath('/reports/sales-per-product-category'),
      permission: 'report_sales_per_product_category',
    },
    {
      key: 'report_product_sales_per_customer',
      label: reportmenu.product_sales_per_customer,
      onClick: () => pushPath('/reports/product-sales-per-customer'),
      permission: 'report_product_sales_per_customer',
    },
    {
      key: 'report_sales_per_period',
      label: reportmenu.sales_per_period,
      onClick: () => pushPath('/reports/sales-per-period'),
      permission: 'report_sales_per_period',
    },
    {
      key: 'report_purchase_detail',
      label: reportmenu.purchase_detail,
      onClick: () => pushPath('/reports/purchase-detail'),
      permission: 'report_purchase_detail',
    },
    {
      key: 'report_aged_payables',
      label: reportmenu.aged_payables,
      onClick: () => pushPath('/reports/aged-payable'),
      permission: 'report_aged_payables',
    },
    {
      key: 'report_supplier_invoice',
      label: reportmenu.supplier_invoice,
      onClick: () => pushPath('/reports/supplier-invoice'),
      permission: 'report_supplier_invoice',
    },
    {
      key: 'report_purchases_per_product',
      label: reportmenu.purchases_per_product,
      onClick: () => pushPath('/reports/purchases-per-product'),
      permission: 'report_purchases_per_product',
    },
    {
      key: 'report_purchases_per_supplier',
      label: reportmenu.purchases_per_supplier,
      onClick: () => pushPath('/reports/purchases-per-vendor'),
      permission: 'report_purchases_per_supplier',
    },
    {
      key: 'report_purchase_delivery',
      label: reportmenu.purchase_delivery,
      onClick: () => pushPath('/reports/purchase-delivery'),
      permission: 'report_purchase_delivery',
    },
    {
      key: 'report_purchases_invoice_payment',
      label: reportmenu.purchases_invoice_payment,
      onClick: () => pushPath('/reports/purchase-invoice-payment'),
      permission: 'report_purchase_invoice_payment',
    },
    {
      key: 'report_product_purchases_per_supplier',
      label: reportmenu.product_purchases_per_supplier,
      onClick: () => pushPath('/reports/product-purchases-per-vendor'),
      permission: 'report_product_purchases_per_vendor',
    },
    {
      key: 'report_purchases_per_period',
      label: reportmenu.purchases_per_period,
      onClick: () => pushPath('/reports/purchases-per-period'),
      permission: 'report_purchases_per_period',
    },
    {
      key: 'report_expense_per_contact',
      label: reportmenu.expense_per_contact,
      onClick: () => pushPath('/reports/expense-per-contact'),
      permission: 'report_expense_per_contact',
    },
    {
      key: 'report_expense_claim_detail',
      label: reportmenu.expense_claim_detail,
      onClick: () => pushPath('/reports/expense-claim'),
      permission: 'report_expense_claim_detail',
    },
    {
      key: 'report_sales_tax',
      label: reportmenu.sales_tax,
      onClick: () => pushPath('/reports/sales-tax'),
      permission: 'report_sales_tax',
    },
    {
      key: 'report_witholding_tax',
      label: reportmenu.witholding_tax,
      onClick: () => pushPath('/reports/witholding-tax'),
      permission: 'report_witholding_tax',
    },
    {
      key: 'report_inventory_summary',
      label: reportmenu.inventory_summary,
      onClick: () => pushPath('/reports/inventory-summary'),
      permission: 'report_inventory_summary',
    },
    {
      key: 'report_inventory_stock_movement',
      label: reportmenu.inventory_stock_movement,
      onClick: () => pushPath('/reports/inventory-stock-movement'),
      permission: 'report_inventory_stock_movement',
    },
    {
      key: 'report_warehouse_stock_summary',
      label: reportmenu.warehouse_stock_summary,
      onClick: () => pushPath('/reports/warehouse-stock-summary'),
      permission: 'report_warehouse_stock_summary',
    },
    {
      key: 'report_warehouse_stock_movement',
      label: reportmenu.warehouse_stock_movement,
      onClick: () => pushPath('/reports/warehouse-stock-movement'),
      permission: 'report_warehouse_stock_movement',
    },
    {
      key: 'report_production',
      label: reportmenu.production,
      onClick: () => pushPath('/reports/production'),
      permission: 'report_production',
    },
    {
      key: 'report_report_inventory_stock_adjustment',
      label: reportmenu.report_inventory_stock_adjustment,
      onClick: () => pushPath('/reports/inventory-stock-adjustment'),
      permission: 'report_report_inventory_stock_adjustment',
    },
    {
      key: 'report_inventory_warehouse_transfer',
      label: reportmenu.inventory_warehouse_transfer,
      onClick: () => pushPath('/reports/warehouse-transfer'),
      permission: 'report_inventory_warehouse_transfer',
    },
    {
      key: 'report_fixed_asset_summary',
      label: reportmenu.fixed_asset_summary,
      onClick: () => pushPath('/reports/fixed-asset-summary'),
      permission: 'report_fixed_asset_summary',
    },
    {
      key: 'report_fixed_asset_detail',
      label: reportmenu.fixed_asset_detail,
      onClick: () => pushPath('/reports/fixed-asset-detail'),
      permission: 'report_fixed_asset_detail',
    },
    {
      key: 'report_fixed_asset_disposal',
      label: reportmenu.fixed_asset_disposal,
      onClick: () => pushPath('/reports/fixed-asset-disposal'),
      permission: 'report_fixed_asset_disposal',
    },
    {
      key: 'report_budget',
      label: reportmenu.budget,
      onClick: () => pushPath('/reports/budget-management'),
      permission: 'report_budget',
    },
    {
      key: 'report_budget_profit_loss',
      label: reportmenu.budget_profit_loss,
      onClick: () => pushPath('/reports/budget-profit-loss'),
      permission: 'report_budget_profit_loss',
    },
    {
      key: 'report_attachment',
      label: reportmenu.attachment,
      onClick: () => pushPath('/reports/attachment'),
      permission: 'report_attachment',
    },
    {
      key: 'report_export',
      label: reportmenu.export,
      onClick: () => pushPath('/reports/export'),
      permission: 'report_export',
    },
    {
      key: 'report_import',
      label: reportmenu.import,
      onClick: () => pushPath('/reports/import'),
      permission: 'report_import',
    },
  ];

  const filteredItems = useMemo(() => {
    if (!Array.isArray(options)) return [];
    return items.filter(item => options.includes(item.key));
  }, [items, options]);

  return (
    <>
      <Dropdown.Button
        icon={<DownOutlined />}
        menu={{ items: filteredItems }}
        trigger={['click']}
        onOpenChange={visibleChangeHandler}
        onClick={toggleHandler}
        open={visible}
        {...props}
      >
        <>
          {intl.formatMessage({ id: 'report.see_report' })}
        </>
      </Dropdown.Button>
    </>
  );
};

const Component = injectIntl(ReportDropdown);
export default React.memo(Component);
