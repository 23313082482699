import React from 'react'
import { Button, Descriptions, Modal, Result, Skeleton, Typography } from 'antd'
import { injectIntl } from 'react-intl'
import { Cancel, Send } from '../Button'

const { Text } = Typography

const MassSendEmailModal = ({
  intl,
  show,
  loading,
  info,
  title,
  getMassSendEmailInfo,
  onCancel,
  onExecuteMassSendEmail,
  onSendEmailCompleted,
}) => {
  const [step, setStep] = React.useState(0)
  const [loadingExecute, setLoadingExecute] = React.useState(false)

  React.useEffect(() => {
    if (show && step === 0) {
      getMassSendEmailInfo()
    }
  }, [getMassSendEmailInfo, show, step])

  const onMassSendEmail = async () => {
    setLoadingExecute(true)
    await onExecuteMassSendEmail()
    setStep(2)
    setLoadingExecute(false)
    onSendEmailCompleted()
  }

  const onClose = () => {
    onCancel()
    if (step === 2) {
      setLoadingExecute(false)
      setStep(0)
    }
  }

  return (
    <Modal
      open={show}
      title={intl.formatMessage({ id: 'massEmail.mass_email' })}
      onCancel={onClose}
      maskClosable={info.valid <= 0}
      footer={
        info.valid > 0 && step === 0
          ? [
              <Cancel key={1} onClick={onClose} />,
              <Send key={0} onClick={onMassSendEmail} loading={loadingExecute} />,
            ]
          : null
      }
    >
      {step === 0 && (
        <>
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Descriptions bordered column={1} className="mb-3">
                <Descriptions.Item label={intl.formatMessage({ id: 'massEmail.can_be_sent' })}>
                  {info.valid}
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage({ id: 'massEmail.cannot_be_sent' })}>
                  {info.invalid}
                </Descriptions.Item>
              </Descriptions>
              {info.valid > 0 && (
                <Text strong className="mt-4 d-block">
                  {intl.formatMessage(
                    { id: 'massEmail.are_you_sure' },
                    { title, count: info.valid },
                  )}
                </Text>
              )}
            </>
          )}
        </>
      )}
      {step === 2 && (
        <>
          <Result
            status="success"
            title={
              <h5>
                {intl.formatMessage({ id: 'massEmail.data_has_been_sent' }, { count: info.valid })}
              </h5>
            }
          />
          <div className="d-flex justify-content-center">
            <Button onClick={onClose}>Ok</Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default injectIntl(MassSendEmailModal)
