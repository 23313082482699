import axios from 'axios'

export const getSsoDomain = () => {
  return axios.get('/sso/domain')
}

export const addSsoDomain = (data) => {
  return axios.post('/sso/domain', data)
}

export const sendCodeSsoDomain = (id) => {
  return axios.post(`/sso/domain/${id}/sendCode`)
}

export const verifySsoDomain = ({ id, data }) => {
  return axios.post(`/sso/domain/${id}/verify`, data)
}

export const deleteSsoDomain = (id) => {
  return axios.delete(`/sso/domain/${id}`)
}

export const generateSpSso = () => {
  return axios.get('/sso/generateSp')
}

export const saveSsoConfiguration = ({ data }) => {
  return axios.post('/sso/config', data)
}

export const getSsoConfiguration = () => {
  return axios.get('/sso/config')
}

export const updateSsoConfiguration = ({ id, data }) => {
  return axios.patch(`/sso/config/${id}`, data)
}
