import React, { useCallback, useEffect, useMemo } from 'react'
import { Dropdown, Menu } from 'antd'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useQueryClient } from 'react-query'

import { useResponsive } from 'utils/hooks'
import styles from './style.module.scss'

const CompanySelector = ({ onChangeCompany, companies, intl }) => {
  const { isMobileView } = useResponsive()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  useEffect(() => {
    const header = document.querySelector('header.ant-layout-header')

    if (!header) return

    if (isMobileView) header.classList.add(styles.lineHeightInherit)
    else header.classList.remove(styles.lineHeightInherit)
  }, [isMobileView])

  const changeCompany = useCallback(
    ({ key }) => {
      if (key === 'list') {
        dispatch(push('/companies'))
      } else if (key === 'add') {
        dispatch(push('/companies/add'))
      } else if (companies.selectedEndpoint !== key) {
        onChangeCompany(key, () => {
          // Clear all cache from react-query to prevent sharing cache data between company.
          // Sharing cache data will cause current company use last cache data from previous company
          // until current fetching success
          queryClient.clear()
        })
      }
    },
    [companies, dispatch, onChangeCompany, queryClient],
  )

  const selectedCompany = useMemo(
    () => companies.companies.filter((e) => e.endpoint === companies.selectedEndpoint),
    [companies],
  )

  const items = useMemo(() => {
    const newItems = companies.companies.map((item) => {
      return {
        key: item.endpoint,
        label: item.name,
      }
    })
    newItems.push({
      type: 'divider',
      key: Math.random(),
    })
    if (companies.companies.length > 1) {
      newItems.push({
        key: 'list',
        label: intl.formatMessage({ id: 'companies.company_list' }),
      })
    } else {
      newItems.push({
        key: 'add',
        label: intl.formatMessage({ id: 'companies.add_company' }),
      })
    }
    return newItems
  }, [companies, intl])

  const langMenu = (
    <Menu
      className={styles.menu}
      selectedKeys={[companies.selectedEndpoint]}
      onClick={changeCompany}
      items={items}
    />
  )
  return (
    <Dropdown
      dropdownRender={() => (langMenu)}
      trigger={['click']}
    >
      <div className={styles.dropdown}>
        <strong className="text-uppercase" style={{ lineHeight: 'inherit' }}>
          {selectedCompany[0] ? selectedCompany[0].name : ''}
        </strong>
      </div>
    </Dropdown>
  )
}

export default injectIntl(CompanySelector)
