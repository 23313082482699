import React, { forwardRef, useCallback } from 'react'
import { Select, Space, Spin } from 'antd'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import DropdownItemAction from './DropdownItemAction'

const SearchInput = forwardRef(
  (
    {
      showSearch = true,
      defaultActiveFirstOption = false,
      filterOption = false,
      extraIcon,
      extraTitle = '',
      withExtra = true,
      onExtraClick = () => {},
      popupClassName = 'ant-select-custom',
      popupMatchSelectWidth = false,
      autoFocus,
      onSearch,
      onFocus,
      infiniteScroll,
      infiniteData,
      options,
      ...props
    },
    ref,
  ) => {
    const { formatMessage } = useIntl()

    const focusHandler = useCallback(() => {
      if (typeof onSearch === 'function') {
        onSearch('')
      }
      if (typeof onFocus === 'function') {
        onFocus()
      }
    }, [onFocus, onSearch])

    const dropdownRender = useCallback(
      (menu) => {
        const isInfiniteLoading = infiniteScroll && infiniteData?.loading
        if (!withExtra) {
          return (
            <div>
              {menu}
              <Loader style={{ bottom: _.isEmpty(options) ? 8 : 0 }} loading={isInfiniteLoading} />
            </div>
          )
        }

        return (
          <div>
            <DropdownItemAction
              menu={menu}
              title={extraTitle}
              onClick={onExtraClick}
              withExtra={withExtra}
              icon={extraIcon}
            />
            <Loader loading={isInfiniteLoading} style={{ bottom: _.isEmpty(options) ? 48 : 40 }} />
          </div>
        )
      },
      [extraIcon, extraTitle, infiniteData, infiniteScroll, onExtraClick, withExtra, options],
    )

    return (
      <Select
        onFocus={focusHandler}
        notFoundContent={
          <div style={{ height: 30, padding: 5 }}>
            {formatMessage({ id: 'suggestion.not_found' })}
          </div>
        }
        showSearch={showSearch}
        defaultActiveFirstOption={defaultActiveFirstOption}
        filterOption={filterOption}
        autoFocus={autoFocus}
        ref={ref}
        popupClassName={popupClassName}
        popupMatchSelectWidth={popupMatchSelectWidth}
        onSearch={onSearch}
        options={options}
        {...props}
        dropdownRender={dropdownRender}
      />
    )
  },
)

const Loader = ({ loading = false, style }) => {
  if (!loading) {
    return null
  }
  return (
    <div
      className="ant-select-item ant-select-item-option infinite-loader"
      style={{ position: 'absolute', width: '98.4%', bottom: 0, ...style }}
    >
      <Space>
        <Spin size="small" />
        <div className="ant-select-item-option-content">Loading...</div>
      </Space>
    </div>
  )
}

export default SearchInput
