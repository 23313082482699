import Cookies from 'js-cookie'

export const setToken = (token) => {
  Cookies.set('token', token, { expires: 30 * 12 }) // 1 tahun
}
export const getToken = () => Cookies.get('token')
export const isTokenExist = () => !!getToken()
export const removeToken = () => Cookies.remove('token')

export const setSavedEmail = (email) => {
  Cookies.set('email', email, { expires: 30 * 12 }) // 1 tahun
}
export const getSavedEmail = () => Cookies.get('email')
export const isEmailSavedExist = () => !!getSavedEmail()
export const removeSavedEmail = () => Cookies.remove('email')
