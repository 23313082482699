import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

class DateMonth extends React.Component {
  state = {
    date: dayjs(),
    pickerOpen: false,
  }

  setDate = (date) => {
    this.setState({ date, pickerOpen: false }, () => this.props.onClick && this.props.onClick(date))
  }

  togglePicker = () => this.setState((prevState) => ({ pickerOpen: !prevState.pickerOpen }))

  renderNavigation = (navigation) => {
    const { disabledDate } = this.props
    let date = this.getDate()
    date = navigation === 'prev' ? date.subtract(1, 'months') : date.add(1, 'months')
    if (typeof disabledDate === 'function') {
      if (disabledDate(date)) {
        return null
      }
    }

    if (navigation === 'prev') {
      return (
        <LeftOutlined onClick={() => this.setDate(dayjs(this.getDate()).subtract(1, 'months'))} />
      )
    }

    return <RightOutlined onClick={() => this.setDate(dayjs(this.getDate()).add(1, 'months'))} />
  }

  getDate = () => {
    let { date } = this.state
    if (dayjs(this.props.date).isValid()) {
      date = dayjs(this.props.date)
    }

    return date
  }

  render() {
    const { 'data-testid': dataTestId, ...props } = this.props
    const date = this.getDate()

    return (
      <div className="picker-month" data-testid={dataTestId}>
        {this.renderNavigation('prev')}
        <span
          onClick={this.togglePicker}
          onKeyDown={this.togglePicker}
          role="button"
          tabIndex="0"
          className="picker-month-title"
        >
          {`  ${dayjs(date).format('MMMM')} ${dayjs(date).format('YYYY')}  `}
        </span>
        <DatePicker.MonthPicker
          value={date}
          open={this.state.pickerOpen}
          onChange={this.setDate}
          onOpenChange={this.togglePicker}
          {...props}
        />
        {this.renderNavigation('next')}
      </div>
    )
  }
}

export default DateMonth
