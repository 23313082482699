import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPusherInstance } from 'utils/pusher'
// import { useAbly } from 'ably/react'

const initialProgress = { invalid: 0, total: 0, valid: 0 }

// Hook untuk handle ably progress
const useNotificationProgress = ({ rawData } = {}) => {
  // const ably = useAbly()
  const channelRef = useRef()
  const pusherRef = useRef()
  //   const [channelTemp, setChannelTemp] = useState({
  //     name: 'private:default',
  //     eventName: 'App\\Events\\DeleteProgress',
  //   })
  const [progress, setProgress] = useState(initialProgress)
  const [statusImported, setStatusImported] = useState(false)

  //   const channelEventCallback = useCallback((event) => {
  //     console.log(event.data)
  //   }, [])

  //   const { channel } = useChannel(channelTemp.name, channelTemp.eventName, channelEventCallback)

  const setChannelHandler = useCallback(
    async (name, eventName = 'App\\Events\\DeleteProgress', callback) => {
      const pusher = createPusherInstance()
      pusherRef.current = pusher

      const channel = pusher.subscribe(name)
      channelRef.current = channel

      channelRef.current.bind(eventName, async (data) => {
        if (rawData) {
          setProgress(data)
        } else {
          setProgress(data?.progress ?? {})
        }
      })

      if (typeof callback === 'function') {
        callback({ channel: channelRef })
      }
    },
    [rawData],
  )

  const progressData = useMemo(() => {
    if (rawData) {
      return progress
    }

    const imported = progress.invalid + progress.valid

    return {
      imported,
      total: progress.total,
      percentage:
        progress.total > 0 && imported > 0 ? Math.floor((imported / progress.total) * 100) : 0,
      data: progress,
    }
  }, [progress, rawData])

  const disconnectHandler = useCallback(() => {
    if (channelRef.current) {
      channelRef.current.unbind_all()
      channelRef.current.unsubscribe()
    }
    if (pusherRef.current) {
      pusherRef.current.disconnect()
    }
  }, [])

  const resetProgressHandler = useCallback(() => {
    setProgress(initialProgress)
    setStatusImported(false)
  }, [])

  useEffect(() => {
    if (progress.total > 0 && progress.valid > 0) {
      if (progressData.percentage >= 100) {
        setTimeout(() => {
          setStatusImported(true)
        }, 300)
      }
    }
  }, [progress.total, progress.valid, progressData.percentage])

  return {
    onSetChannel: setChannelHandler,
    progress: progressData,
    onDisconnect: disconnectHandler,
    onResetProgress: resetProgressHandler,
    statusImported,
  }
}

export default useNotificationProgress
