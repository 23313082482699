import axios from 'axios'

export const getInits = () => {
  return axios.get('/inits', { params: { v: '1' } })
}

export const getInitsPublic = () => {
  return axios.get('/inits/public', { headers: { Authorization: false } })
}

export const getInitsFinance = () => {
  return axios.get('/inits/finance')
}
