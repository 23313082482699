import * as actionTypes from 'redux/actionTypes'

export default function maxCompaniesReducer(state = 0, action) {
  switch (action.type) {
    case actionTypes.SET_MAX_COMPANIES:
      return action.payload
    default:
      return state
  }
}
