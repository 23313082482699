import React from 'react'
import { Button } from 'antd'
// import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CloseOutlined } from '@ant-design/icons'

function Cancel({
  type = 'default',
  icon = <CloseOutlined />,
  title = 'button.cancel',
  onClick = () => {},
  id = 'btn-cancel',
  ...props
}) {
  const { formatMessage } = useIntl()
  const newTitle = title === 'button.cancel' ? formatMessage({ id: title }) : title
  return (
    <Button icon={icon} type={type} onClick={onClick} id={id} {...props}>
      {newTitle}
    </Button>
  )
}

// Cancel.propTypes = {
//   type: PropTypes.string,
//   icon: PropTypes.object,
//   title: PropTypes.string,
//   id: PropTypes.string,
//   onClick: PropTypes.func,
// }

// Cancel.defaultProps = {
//   type: 'default',
//   icon: <CloseOutlined />,
//   title: 'button.cancel',
//   onClick: () => {},
//   id: 'btn-cancel',
// }

export default Cancel
