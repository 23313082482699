import { Result } from 'antd'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { loadedInitSelector, maintenanceSelector, userSelector } from 'redux/selectors'

const Maintenance = () => {
  const dispatch = useDispatch()
  const maintenanceMessage = useSelector(maintenanceSelector)
  const loadedInit = useSelector(loadedInitSelector)
  const { id } = useSelector(userSelector)

  useEffect(() => {
    if (id && loadedInit && _.isEmpty(maintenanceMessage)) {
      dispatch(push('/'))
    }
  }, [id, dispatch, loadedInit, maintenanceMessage])
  return (
    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Helmet title="Server Error" />
      <div
        style={{
          maxWidth: '600px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          flex: '1',
          marginBottom: 50,
          paddingTop: 25,
          paddingBottom: 25,
        }}
      >
        <div
          style={{
            maxWidth: '500px',
            margin: '0 auto',
          }}
        >
          <Result status="500" title={maintenanceMessage || 'We are under maintenance'} />
        </div>
      </div>
    </div>
  )
}

export default Maintenance
