import axios from 'axios'

export const getFinanceLayoutInvoice = (type) => {
  return axios.get(`/finance/layoutInvoice?type=${type}`)
}

export const saveFinanceLayoutInvoice = (data) => {
  return axios.put('/finance/layoutInvoice', data)
}

export const getFinanceLayoutInvoiceTemplates = () => {
  return axios.get('/finance/layoutInvoice/templates')
}

export const getFinanceLayoutInvoiceTemplatesStamp = () => {
  return axios.get('/finance/layoutInvoice/templatesStamp')
}

export const getFinanceLayoutInvoiceLabelTemplates = () => {
  return axios.get('/finance/layoutInvoice/templatesLabel')
}

export const resetFinanceLayoutInvoice = (data) => {
  return axios.post('/finance/layoutInvoice/reset', data)
}

export const resetImageFinanceLayoutInvoice = (data) => {
  return axios.post('/finance/layoutInvoice/resetImage', data)
}
