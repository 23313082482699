import React from 'react'
import { Spin, Typography } from 'antd'

import { ChartCardWithFilter } from 'components/UI'
import styles from './ratio-chart.module.scss'

const RatioChart = ({
  description,
  ratio,
  rawRatio,
  loading,
  loadingType = 'spin',
  title,
  params,
  onParamsChange,
  cardProps = {},
  containerClassName,
  ratioSvgWidth = 319 * 0.75,
  ratioSvgHeight = 188 * 0.75,
  needleClassName,
  speedometerClassName,
  extra,
  cardHeaderMarginBottom,
  cardClassName,
  titleProps,
  ratioClassName,
  descriptionClassName,
  chartDescription,
  chartDescriptionClassName
}) => {
  return (
    <ChartCardWithFilter
      title={title}
      params={params}
      onParamsChange={onParamsChange}
      extra={extra}
      cardHeaderMarginBottom={cardHeaderMarginBottom}
      cardClassName={cardClassName}
      loading={loadingType === 'skeleton' ? loading : false}
      titleProps={titleProps}
      {...cardProps}
    >
      <Spin spinning={loadingType === 'spin' ? loading : false}>
        <div className={containerClassName}>
          <div
              className="d-flex justify-content-center"
              style={chartDescription ? { flexDirection: 'column', alignItems: 'center' } : {}}
          >
            <svg
              width={ratioSvgWidth}
              height={ratioSvgHeight}
              viewBox="0 0 319 188"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={speedometerClassName}
            >
              <path
                d="M0 159.247C0 71.297 71.2973 0 159.247 0C247.197 0 318.494 71.297 318.494 159.247V188H0V159.247Z"
                fill="#EEEEEE"
              />
              <path
                d="M159 176C166.732 176 173 169.732 173 162C173 154.268 166.732 148 159 148C151.268 148 145 154.268 145 162C145 169.732 151.268 176 159 176Z"
                fill="#AEB5BC"
              />
              <mask
                id="mask0_12_28"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="15"
                y="18"
                width="288"
                height="170"
              >
                <path d="M303 18H15V188H303V18Z" fill="white" />
              </mask>
              <g mask="url(#mask0_12_28)">
                <path
                  d="M275.498 77.565C293.373 102.253 303 131.985 303 162.5H209.4C209.4 151.82 206.031 141.413 199.774 132.773L275.498 77.565Z"
                  fill="#ED7C62"
                />
                <path
                  d="M203.498 25.0723C232.42 34.502 257.624 52.878 275.498 77.565L199.774 132.773C193.518 124.132 184.697 117.701 174.574 114.4L203.498 25.0723Z"
                  fill="#F9D875"
                />
                <path
                  d="M114.502 25.0723C143.423 15.6426 174.577 15.6426 203.498 25.0723L174.574 114.4C164.452 111.1 153.548 111.1 143.426 114.4L114.502 25.0723Z"
                  fill="#77C3F2"
                />
                <path
                  d="M42.5016 77.565C60.376 52.878 85.5801 34.502 114.502 25.0723L143.426 114.4C133.303 117.701 124.482 124.132 118.226 132.773L42.5016 77.565Z"
                  fill="#9FDCFC"
                />
                <path
                  d="M15 162.5C15 131.985 24.6271 102.252 42.5016 77.565L118.226 132.773C111.969 141.413 108.6 151.82 108.6 162.5H15Z"
                  fill="#DDDDDD"
                />
              </g>
              <g
                id="needle"
                className={needleClassName}
                style={{
                  transformOrigin: '50% calc(100% - 25.5px)',
                  transform: `rotate(${rawRatio}deg)`,
                  transition: 'all 300ms ease-in-out 250ms',
                }}
              >
                <path
                  d="M27.9891 162.398L158.148 153.646C163.471 153.288 168.021 157.521 168.052 162.859C168.083 168.196 163.58 172.4 158.253 172.007L27.9891 162.398Z"
                  fill="#3F5161"
                />
                <path
                  d="M161.459 160.351C162.81 161.684 162.836 163.85 161.515 165.189C160.194 166.528 158.028 166.533 156.677 165.201C155.325 163.868 155.3 161.702 156.621 160.363C157.942 159.024 160.108 159.019 161.459 160.351Z"
                  fill="white"
                />
              </g>
            </svg>
            {chartDescription ?
                <Typography.Paragraph className={[styles.description, chartDescriptionClassName].join(' ')}>
                  {chartDescription}
                </Typography.Paragraph> : null
            }
          </div>
          <div className="ratio-container">
            <Typography.Title className={[styles.percentage, ratioClassName].join(' ')}>
              {ratio}
            </Typography.Title>
            <Typography.Paragraph className={[styles.description, descriptionClassName].join(' ')}>
              {description}
            </Typography.Paragraph>
          </div>
        </div>
      </Spin>
    </ChartCardWithFilter>
  )
}

export default RatioChart
