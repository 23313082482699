import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import { getEmailTemplateCustomerBirthday, revertEmail, updateEmail } from 'utils/apis'

export const useEmailTemplateCustomerBirthday = ({ options = {} }) => {
  return useQuery(
    ['emailTemplateCustomerBirthday'],
    async () => {
      const {
        data: { data },
      } = await getEmailTemplateCustomerBirthday()
      return data
    },
    {
      onError: (error) => message.error(error?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useUpdateEmail = () => {
  return useMutation((payload) => updateEmail(payload))
}

export const useRevertEmail = () => {
  return useMutation((payload) => revertEmail(payload))
}
