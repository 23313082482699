import * as Sentry from '@sentry/react'

export default () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
      normalizeDepth: 5,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Cannot redefine property: googletag',
        'ResizeObserver loop completed with undelivered notifications',
        /Loading chunk [\d]+ failed/,
        /Loading CSS chunk [\d]+ failed/,
        'Unexpected token _ in JSON at position 0',
        'SecurityError: The operation is insecure.',
      ],
    })
  }
}
