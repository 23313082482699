import { Skeleton, Table } from 'antd'
import React from 'react'
import { injectIntl } from 'react-intl'

import { LinkTransType } from 'components/UI'
import { qtyToCurrency } from 'utils/formatting'

const InfoTable = ({ intl, loading, title, info, errors, originTransType, originTitle }) => {
  if (loading) {
    return <Skeleton active />
  }

  const dataSource = [
    {
      key: 'valid',
      label: intl.formatMessage(
        { id: 'financeInvoices.can_be_created_to' },
        { title: title.toLowerCase() },
      ),
      count: info.valid,
    },
    {
      key: 'invalid',
      label: intl.formatMessage(
        { id: 'financeInvoices.cannot_be_created_to' },
        { title: title.toLowerCase() },
      ),
      count: info.invalid,
    },
    ...errors,
  ]

  const columns = [
    {
      dataIndex: 'label',
      className: 'ant-descriptions-item-label',
      width: errors.length > 0 ? 200 : undefined,
      render: (text, row) => {
        if (text) {
          return text
        }

        return (
          <LinkTransType
            transTypeId={originTransType}
            data={row}
            title={row.ref_number ?? originTitle}
          />
        )
      },
    },
    {
      dataIndex: 'count',
      className: 'text-right ant-descriptions-item-content',
      render: (text, row) => {
        if (!row.message) {
          return qtyToCurrency(text)
        }

        return row.message
      },
    },
  ]

  return (
    <Table
      bordered
      showHeader={false}
      pagination={false}
      className="ant-descriptions ant-descriptions-bordered mb-3"
      rowKey={(row) => row.key ?? `${row.id}-${row.ref_number}`}
      dataSource={dataSource}
      columns={columns}
    />
  )
}

export default injectIntl(InfoTable)
