import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'

const initialState = {
  countries: [],
  bookingTypes: [],
  lang: [],
}

const setRefs = (state, action) => {
  return updateObject(state, {
    lang: action.payload.lang,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REFS:
      return setRefs(state, action)
    default:
      return state
  }
}

export default reducer
