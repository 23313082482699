import * as api from 'utils/apis/financeWareHouse'
import * as actionTypes from 'redux/actionTypes'

export const setFinanceWareHouses = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_WAREHOUSES,
    payload,
  }
}

export const addFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.ADD_FINANCE_WAREHOUSE,
    payload,
  }
}

export const editFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.EDIT_FINANCE_WAREHOUSE,
    payload,
  }
}

export const addFinanceWareHouse = (payload) => {
  return (dispatch) => {
    return api.addFinanceWareHouse(payload).then((response) => {
      dispatch(addFinanceWareHouseRedux(response.data.data))
      return response
    })
  }
}

export const editFinanceWareHouse = (payload) => {
  return (dispatch) => {
    return api.editFinanceWareHouse(payload).then((response) => {
      dispatch(editFinanceWareHouseRedux(response.data.data))
      return response
    })
  }
}

export const moveFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.MOVE_FINANCE_WAREHOUSE,
    payload,
  }
}

export const moveFinanceWareHouse = (payload) => {
  return (dispatch) => {
    dispatch(moveFinanceWareHouseRedux(payload))

    return api
      .moveFinanceWareHouse(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveFinanceWareHouseRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const changeStatusFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_FINANCE_WAREHOUSE,
    payload,
  }
}

export const changeStatusFinanceWareHouse = (payload) => {
  return (dispatch) => {
    dispatch(changeStatusFinanceWareHouseRedux(payload))

    if (payload.checked) {
      return api.activateFinanceWareHouse(payload.id)
    }

    return api.deactivateFinanceWareHouse(payload.id)
  }
}

export const filterFinanceWareHouse = (payload) => {
  return {
    type: actionTypes.FILTER_FINANCE_WAREHOUSE,
    payload,
  }
}

export const deleteFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.DELETE_FINANCE_WAREHOUSE,
    payload,
  }
}

export const deleteFinanceWareHouse = (payload) => {
  return (dispatch) => {
    return api.deleteFinanceWareHouse(payload.id).then((response) => {
      dispatch(deleteFinanceWareHouseRedux(payload))
      return response
    })
  }
}

export const archiveFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.ARCHIVE_FINANCE_WAREHOUSE,
    payload,
  }
}

export const archiveFinanceWareHouse = (payload) => {
  return (dispatch) => {
    return api.archiveFinanceWareHouse(payload.id).then((response) => {
      dispatch(archiveFinanceWareHouseRedux(payload))
      return response
    })
  }
}

export const unarchiveFinanceWareHouseRedux = (payload) => {
  return {
    type: actionTypes.UNARCHIVE_FINANCE_WAREHOUSE,
    payload,
  }
}

export const unarchiveFinanceWareHouse = (payload) => {
  return (dispatch) => {
    return api.unarchiveFinanceWareHouse(payload.id).then((response) => {
      dispatch(unarchiveFinanceWareHouseRedux(payload))
      return response
    })
  }
}

export const setFinanceWareHousesRoles = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_WAREHOUSES_ROLES,
    payload,
  }
}
