import axios from 'axios'
import { stringify } from 'query-string'

export const createOrUpdateApproval = (data) => {
  return axios.post('/finance/approvals', data)
}

export const getApprovalSettings = (transTypeId) => {
  return axios.get(`/finance/approvals/${transTypeId}`)
}

export const getApprovable = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/approvals/approveable?${query}`)
}

export const inputMassApproval = (data) => {
  return axios.post('/finance/approvals/inputMassApproval', data)
}

export const executeMassApproval = (data) => {
  return axios.post('/finance/approvals/executeMassApproval', data)
}

export const inputMassApprovalReject = (data) => {
  return axios.post('/finance/approvals/inputMassApprovalReject', data)
}

export const executeMassApprovalReject = (data) => {
  return axios.post('/finance/approvals/executeMassApprovalReject', data)
}
