import React from 'react'
import { useSelector } from 'react-redux'

import { billingSelector, darkModeSelector, optionsSelector } from 'redux/selectors'
import { isFeatureChampion } from 'utils/helper'
import styles from './style.module.scss'

const Footer = () => {
  const billing = useSelector(billingSelector)
  const options = useSelector(optionsSelector)
  const darkMode = useSelector(darkModeSelector)

  const isOnChampionPlan = !isFeatureChampion({
    billing,
    code: 'finance_system_white_label',
  })

  const companyName =
    options?.finance_system_white_label && isOnChampionPlan ? options.property_name : 'Kledo'

  return (
    <div className={styles.footer} style={{ backgroundColor: darkMode ? undefined : '#fff' }}>
      <div className={styles.inner} style={{ backgroundColor: darkMode ? '#141414' : '#fff' }}>
        <div className="d-flex justify-content-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.kledo.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/resources/images/download-gplay.png"
              alt="Google Play"
              className={styles.downloadImg}
            />
          </a>
          <a
            href="https://apps.apple.com/id/app/kledo/id1586502518"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/resources/images/download-appstore.png"
              alt="App Store"
              className={styles.downloadImg}
            />
          </a>
        </div>
        <div className={styles.bottom}>
          <div className="row">
            <div className="col-sm-12">
              <div className={styles.copyright}>
                <span className="d-flex align-items-center justify-content-center flex-wrap">
                  © {new Date().getFullYear()}
                  &nbsp;
                  <a href="https://kledo.com/" target="_blank" rel="noopener noreferrer">
                    {`${companyName} Software v${process.env.REACT_APP_VERSION}`}
                  </a>
                  &nbsp;All rights reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
