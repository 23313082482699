import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import { calculateTaxGroupTotal, getCanDeletedFinanceTax } from 'utils/apis'

export const useCanBeDeletedFinanceTax = ({ options = {} } = {}) => {
  return useQuery(
    ['canBeDeletedFinanceTax'],
    async () => {
      const {
        data: { data },
      } = await getCanDeletedFinanceTax()
      return data
    },
    {
      onError: (error) => message.error(error?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useCalculateTaxGroupTotal = () => {
  return useMutation((payload) => calculateTaxGroupTotal(payload))
}
