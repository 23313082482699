import React from 'react'
import { useIntl } from 'react-intl'

import { Helmet } from 'components/UI'

const ServerError = () => {
  const intl = useIntl()

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Helmet title="Server Error" />
      <div
        style={{
          maxWidth: '560px',
          backgroundColor: '#fff',
          padding: '80px 30px',
          margin: '100px auto',
          borderRadius: '10px',
          flex: '1',
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <h1 className="font-size-36 mb-2">
            {intl.formatMessage({ id: 'notification.server_error' })}
          </h1>
          <p className="mb-3">{intl.formatMessage({ id: 'notification.something_went_wrong' })}</p>
          <h1 className="font-size-80 mb-4 font-weight-bold">500 —</h1>
        </div>
      </div>
    </div>
  )
}

export default ServerError
