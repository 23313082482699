import React, { Component, } from "react";
import { injectIntl } from 'react-intl';
import { message } from 'antd';

import {
  addFinanceTag
} from 'utils/apis';

const withAddTag = (WrappedComponent) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this._isMounted = false;
      this.addFormRef = React.createRef();
      this.state = {
        loading: false,
        showModal: false,
      }
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    toggleModalHandler = () => {
      const form = this.addFormRef.current;
      this.setState(prevState => {
        return {
          showModal: !prevState.showModal
        }
      }, () => {
        if (form) {
          form.resetFields();
        }
      });
    };

    submitHandler = (callBack) => {
      const form = this.addFormRef.current;
      form.validateFields().then(values => {
        this.setState({
          loading: true,
        });

        addFinanceTag(values).then(
          (response) => {
            if (this._isMounted) {
              if (response.data.success) {
                form.resetFields();
                message.success(response.data.message);
                const { data } = response.data;
                if (callBack) {
                  callBack(data);
                }

                this.setState({
                  loading: false,
                });
                this.toggleModalHandler();
              } else {
                this.setState({ loading: false }, message.error(response.data.message));
              }
            }
          }
        ).catch(
          () => {
            if (this._isMounted) {
              message.error(this.props.intl.formatMessage({ id: 'financeTag.error_cannot_add' }));
              this.setState({
                loading: false,
              });
            }
          }
        );
      }).catch(info => {
        if (info.errorFields && info.errorFields[0]) {
          form.scrollToField(info.errorFields[0].name);
        }
      });
    }

    render() {
      const {
        loading, showModal
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loadingTag={loading}
          showTagModal={showModal}
          onToggleTagModal={this.toggleModalHandler}
          tagFormRef={this.addFormRef}
          onSubmitTag={this.submitHandler}
          addFinanceTag={addFinanceTag}
        />
      )
    }
  };

  return injectIntl(HOC);
};

export default withAddTag;
