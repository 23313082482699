import axios from 'axios'
import { stringify } from 'query-string'

import { getOnce } from 'utils/helper'

export const getFinanceContactGroups = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/contactGroups?${query}`)
}

export const addFinanceContactGroup = (data) => {
  return axios.post('/finance/contactGroups', data)
}

export const editFinanceContactGroup = (data) => {
  return axios.put(`/finance/contactGroups/${data.id}`, data)
}

export const deleteFinanceContactGroup = (id) => {
  return axios.delete(`/finance/contactGroups/${id}`)
}

export const moveFinanceContactGroup = (srcId, destId) => {
  return axios.patch(`/finance/contactGroups/${srcId}/move`, { dest_id: destId })
}

// export const activateFinanceContactGroup = (id) => {
//   return axios.patch(`/finance/contactGroups/${id}/activate`)
// }

// export const deactivateFinanceContactGroup = (id) => {
//   return axios.patch(`/finance/contactGroups/${id}/deactivate`)
// }

export const canBeDeletedContactGroup = () => {
  return axios.get('/finance/contactGroups/canBeDeleted')
}

export const getFinanceContactGroupRoles = ({ id }) => {
  return axios.get(`/finance/contactGroups/${id}/roles`)
}

export const addFinanceContactGroupRoles = ({ id, payload }) => {
  return axios.post(`/finance/contactGroups/${id}/roles`, payload)
}
