const colors = {
    background: [
      '#FF6384',
      '#FFCD56',
      '#4BC0C0',
      '#36A2EB',
      '#CB99C9',
      '#EE82EE',
      '#FF2C63',
      '#FF6384',
      '#36A2EB',
      '#4BC0C0',
      '#CB99C9',
      '#EE82EE'
    ],
    hover: [
      '#FF6384',
      '#FFCD56',
      '#4BC0C0',
      '#36A2EB',
      '#CB99C9',
      '#EE82EE',
      '#FF2C63',
      '#FF6384',
      '#36A2EB',
      '#4BC0C0',
      '#CB99C9',
      '#EE82EE'
    ]
  }
  
  export default colors;