import { useQuery } from 'react-query'
import { message } from 'antd'

import { getUnrealizedGain } from 'utils/apis/unrealizedGain'

export function useUnrealizedGain({ id, enabled = false }) {
  return useQuery(
    ['unrealizedGain', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getUnrealizedGain(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}
