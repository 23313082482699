import dayjs from 'dayjs'
import _ from 'lodash'

export const onPrevDateChange = ({
  date,
  monthly,
  yearly,
  params,
  withCustomDaterange,
  setParams,
  dateTypeKey = 'daterange',
}) => {
  let _date = date ? dayjs(date) : null
  if (_.includes(monthly, params[dateTypeKey])) {
    _date = _date ? dayjs(dayjs(_date).format('YYYY-MM-01')) : null
  } else if (_.includes(yearly, params[dateTypeKey])) {
    _date = _date ? dayjs(dayjs(_date).format('YYYY-01-01')) : null
  }
  const newInnerParams = { date_from: _date }
  if (!withCustomDaterange && dayjs(_date).isValid()) {
    if (
      params[dateTypeKey] === 'daily' &&
      Math.abs(dayjs(params.date_to).diff(_date, 'days')) > 31
    ) {
      let nextDate = dayjs(dayjs(_date).add(31, 'days'))
      if (nextDate.isAfter(dayjs())) nextDate = dayjs()

      newInnerParams.date_to = nextDate
    }
    if (
      _.includes(monthly, params[dateTypeKey]) &&
      Math.abs(dayjs(params.date_to).diff(_date, 'months')) > 12
    ) {
      let nextDate = dayjs(dayjs(_date).add(12, 'month').endOf('month'))
      if (nextDate.isAfter(dayjs())) nextDate = dayjs()

      newInnerParams.date_to = nextDate
    }
    if (
      _.includes(yearly, params[dateTypeKey]) &&
      Math.abs(dayjs(params.date_to).diff(_date, 'years')) > 6
    ) {
      let nextDate = dayjs(dayjs(_date).add(6, 'year').endOf('year'))
      if (nextDate.isAfter(dayjs())) nextDate = dayjs()

      newInnerParams.date_to = nextDate
    }
  }

  setParams((prevState) => {
    return { ...prevState, ...newInnerParams }
  })
}

export const onNextDateChange = ({
  date,
  monthly,
  yearly,
  params,
  withCustomDaterange,
  setParams,
  dateTypeKey = 'daterange',
}) => {
  let _date = date ? dayjs(date) : null
  if (_date) {
    if (_.includes(monthly, params[dateTypeKey])) {
      if (dayjs(_date).isBetween(dayjs().startOf('month'), dayjs().endOf('month'))) {
        _date = dayjs()
      } else {
        _date = dayjs(dayjs(_date).endOf('month'))
      }
    } else if (_.includes(yearly, params[dateTypeKey])) {
      if (dayjs(_date).year() === dayjs().year()) {
        _date = dayjs()
      } else {
        _date = dayjs(dayjs(_date).endOf('year'))
      }
    }
  }

  const newInnerParams = { date_to: _date }
  if (!withCustomDaterange && dayjs(_date).isValid()) {
    if (
      params[dateTypeKey] === 'daily' &&
      Math.abs(dayjs(params.date_from).diff(_date, 'days')) > 31
    ) {
      // if (nextDate.isAfter(dayjs())) {
      //   nextDate = dayjs()
      // }
      newInnerParams.date_from = dayjs(dayjs(_date).subtract(31, 'days'))
    }
    if (
      _.includes(monthly, params[dateTypeKey]) &&
      Math.abs(dayjs(params.date_from).diff(_date, 'months')) > 12
    ) {
      // if (nextDate.isAfter(dayjs())) {
      //   nextDate = dayjs()
      // }
      newInnerParams.date_from = dayjs(dayjs(_date).subtract(12, 'month'))
    }
    if (
      _.includes(yearly, params[dateTypeKey]) &&
      Math.abs(dayjs(params.date_from).diff(_date, 'years')) > 6
    ) {
      // if (nextDate.isAfter(dayjs())) {
      //   nextDate = dayjs()
      // }
      newInnerParams.date_from = dayjs(dayjs(_date).subtract(6, 'year'))
    }
  }

  setParams((prevState) => ({
    ...prevState,
    ...newInnerParams,
  }))
}

export const getDefaultCustomDate = ({ daterange, monthly, yearly, dateTypeKey = 'daterange' }) => {
  const newInnerParams = {}
  if (daterange === 'daily') {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs().subtract(31, 'days')
    newInnerParams[dateTypeKey] = 'daily'
  } else if (_.includes(monthly, daterange)) {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs().subtract(12, 'month')
    newInnerParams[dateTypeKey] = 'monthly'
  } else if (_.includes(yearly, daterange)) {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs().subtract(6, 'year')
    newInnerParams[dateTypeKey] = 'yearly'
  } else if (daterange === 'today') {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs()
    newInnerParams[dateTypeKey] = 'daily'
  } else if (daterange === 'yesterday') {
    newInnerParams.date_to = dayjs().subtract(1, 'day')
    newInnerParams.date_from = dayjs().subtract(1, 'day')
    newInnerParams[dateTypeKey] = 'daily'
  } else if (daterange === 'this_month') {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs().startOf('month')
    newInnerParams[dateTypeKey] = 'monthly'
  } else if (daterange === 'last_month') {
    newInnerParams.date_to = dayjs().subtract(1, 'month').endOf('month')
    newInnerParams.date_from = dayjs().subtract(1, 'month').startOf('month')
    newInnerParams[dateTypeKey] = 'monthly'
  } else if (daterange === 'this_year') {
    newInnerParams.date_to = dayjs()
    newInnerParams.date_from = dayjs().startOf('year')
    newInnerParams[dateTypeKey] = 'yearly'
  } else if (daterange === 'last_year') {
    newInnerParams.date_to = dayjs().subtract(1, 'year').endOf('year')
    newInnerParams.date_from = dayjs().subtract(1, 'year').startOf('year')
    newInnerParams[dateTypeKey] = 'yearly'
  }
  return newInnerParams
}
