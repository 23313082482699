import { useQuery } from 'react-query'

import { getQueues } from 'utils/apis/queue'

export function useQueues(options = {}) {
  return useQuery(
    ['queues'],
    async () => {
      let response
      try {
        const { data } = await getQueues()
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    options,
  )
}
