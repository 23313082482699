import axios from 'axios'

export const getWhatsapps = () => {
  return axios.get('/whatsAppTemplates')
}

export const getWhatsapp = (id) => {
  return axios.get(`/whatsAppTemplates/${id}`)
}

export const updateWhatsapp = (data) => {
  return axios.put(`/whatsAppTemplates/${data.id}`, data)
}

export const revertWhatsapp = (id) => {
  return axios.put(`/whatsAppTemplates/${id}/revert`)
}
