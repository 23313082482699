import React from 'react'
import { Col, Row, Spin } from 'antd'
import { injectIntl } from 'react-intl'
import { Line } from 'react-chartjs-2'
import dayjs from 'dayjs'

import { currency, formatDate } from 'utils/formatting'
import colors from 'components/apps/Dashboard/colour'

class LineChart extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !(nextProps.data === this.props.data && nextProps.loading === this.props.loading)
  }

  dateType = () => {
    const { params } = this.props

    const dateFrom = dayjs(params.date_from)
    const dateTo = dayjs(params.date_to)

    const diff = dayjs([dateTo.year(), dateTo.month(), dateTo.date()]).diff(
      dayjs([dateFrom.year(), dateFrom.month(), dateFrom.date()]),
      'day',
      false,
    )

    if (diff <= 90) return 'daily'
    if (diff <= 365) return 'weekly'
    return 'monthly'
  }

  render() {
    const { isMobileView, data, loading, isSmallMobileScreen } = this.props

    const dateType = this.dateType()

    return (
      <Spin spinning={loading}>
        <Row className="mt-3">
          <Col span={24}>
            <Line
              data={{
                labels: Object.keys(data),
                datasets: [
                  {
                    type: 'line',
                    backgroundColor: colors.background[3],
                    borderColor: colors.background[3],
                    label: 'Saldo berjalan',
                    lineTension: 0,
                    fill: 'origin',
                    stack: 'Stack 0',
                    data: Object.values(data),
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { display: false },
                  tooltip: {
                    mode: 'index',
                    intersect: true,
                    callbacks: {
                      label: (tooltipItem) => {
                        return `${tooltipItem.dataset.label}: ${currency(tooltipItem.raw)}`
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      borderWidth: 0,
                    },
                    ticks: {
                      autoSkip: false,
                      callback(tickValue, index, arrayData) {
                        const dataLabel = this.getLabelForValue(tickValue)

                        if (dateType === 'daily') {
                          const day = dayjs(dataLabel)
                          if (day.format('D') % (isSmallMobileScreen ? 3 : 2) === 0)
                            return formatDate(day)
                        }

                        if (dateType === 'weekly' && index % 4 === 0) {
                          return formatDate(dayjs(dataLabel))
                        }

                        if (dateType === 'monthly') {
                          const day = dayjs(dataLabel)
                          const lengthOfData = Math.floor(arrayData.length / 4)
                          const arrayOfNumberLengthData = Array(lengthOfData)
                            .fill(0)
                            .map((__, _index) => (_index + 1) * 4)
                          if (arrayOfNumberLengthData.includes(index)) return day.format('YYYY MMM')
                        }

                        return ''
                      },
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      borderWidth: 0,
                    },
                    beginAtZero: true,
                    ticks: {
                      callback: (value) => (value ? currency(value) : value),
                    },
                  },
                },
                elements: {
                  point: {
                    pointStyle: 'dash',
                  },
                },
              }}
              height={isMobileView ? 300 : null}
            />
          </Col>
        </Row>
      </Spin>
    )
  }
}

export default injectIntl(LineChart)
