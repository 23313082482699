import { updateObject } from 'redux/utility'
import * as actionTypes from 'redux/actionTypes'
import store from "store";

const initialState = {
  options: {
    dark_mode: store.get("app.darkMode")
      ? 1
      : 0,
    property_language: "id-ID",
  },
}

const setOptions = (state, { payload }) => {
  // eslint-disable-next-line
  const options = payload.options
  if (payload.option) {
    options.option = payload.option
  }
  return updateObject(state, {
    options,
  })
}

const editOptions = (state, { payload }) => {
  return updateObject(state, {
    options: {
      ...state.options,
      ...payload,
    },
  })
}

const setup = (state, demo) => {
  // const setupBtn = setup_button ? { setup_button } : {};
  return updateObject(state, {
    options: {
      ...state.options,
      demo,
      // ...setupBtn,
    },
  })
}

const removeLockDate = (state) => {
  const { lock_date, ...prevOptions } = state.options
  return {
    options: prevOptions,
  }
}

const editOption = (state, { payload }) => {
  return updateObject(state, {
    options: {
      ...state.options,
      option: {
        ...state.options.option,
        ...payload,
      },
    },
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPTIONS:
      return setOptions(state, action)

    case actionTypes.SET_LOCK_DATE_OPTION:
    case actionTypes.EDIT_OPTIONS:
      return editOptions(state, action)
    case actionTypes.SETUP_CLEAR_DEMO_DATA:
      return setup(state, 2)
    case actionTypes.SETUP_FINISH:
      return setup(state, 0)
    case actionTypes.REMOVE_LOCK_DATE_OPTION:
      return removeLockDate(state)
    case actionTypes.EDIT_OPTION:
      return editOption(state, action)
    default:
      return state
  }
}

export default reducer
