import axios from 'axios'
import { stringify } from 'query-string'

export const addFinanceClosing = (data) => {
  return axios.post('/finance/closings', data)
}

export const getFinanceClosings = (params) => {
  return axios.get('/finance/closings', {params})
}

export const getFinanceClosing = (id) => {
  return axios.get(`/finance/closings/${id}`)
}

export const getFinanceClosingPreview = (params) => {
  const query = stringify(params)
  return axios.get(`/finance/closings/preview?${query}`)
}

export const getFinanceClosingPreviewDate = () => {
  return axios.get(`/finance/closings/preview/startDate`)
}

export const deleteFinanceClosing = (id) => {
  return axios.delete(`/finance/closings/${id}`)
}
