import React from 'react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import {Tag} from 'antd';
import 'react-multi-email/style.css';

const MultipleEmailInput = (props) => {

  return (
    <ReactMultiEmail
      validateEmail={email => {
        return isEmail(email); // return boolean
      }}
      getLabel={(
        email,
        index,
        removeEmail,
      ) => {
        return (
          <Tag
            key={index}
            color="blue"
            closable
            style={{fontSize: 12}}
            onClose={() => removeEmail(index)}
          >
            {email}
          </Tag>
        );
      }}
      {...props}
    />
  );
};

export default MultipleEmailInput;
