import React from 'react'
import { injectIntl } from 'react-intl'
import { Modal } from 'antd'
import { Add, Cancel } from 'components/UI'
import withTranslation from 'containers/Translation/withTranslation'
import FinanceTagForm from '../FinanceTagForm'

class AddFinanceTag extends React.Component {
  componentDidUpdate(prevProps) {
    const { innerRef, show } = this.props
    if (prevProps.show !== show) {
      if (innerRef.current) {
        innerRef.current.resetFields()
      }
    }
  }

  render() {
    const { intl, show, confirmLoading, hideHandler, submitHandler, innerRef, translation } =
      this.props

    return (
      <Modal
        wrapClassName="tag-modal"
        title={
          translation.menu && `${intl.formatMessage({ id: 'button.add' })} ${translation.menu.tags}`
        }
        open={show}
        onCancel={hideHandler}
        destroyOnClose
        footer={[
          <Cancel key={`_${0}`} onClick={hideHandler} />,
          <Add loading={confirmLoading} form="tagForm" key="submit" htmlType="submit" />,
        ]}
      >
        {show && <FinanceTagForm {...this.props} submitHandler={submitHandler} ref={innerRef} />}
      </Modal>
    )
  }
}

const Component = withTranslation(injectIntl(AddFinanceTag))

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
