import axios from 'axios'

export const getFinanceLayoutBarcode = () => {
  return axios.get('/finance/barcode/layoutBarcode')
}

export const editFinanceLayoutBarcode = ({ data }) => {
  return axios.put('/finance/barcode/layoutBarcode', data)
}

export const resetFinanceLayoutBarcode = () => {
  return axios.post('/finance/barcode/layoutBarcode/reset')
}
