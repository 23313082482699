import React from 'react'
import { injectIntl } from 'react-intl'
import { Empty as AntdEmpty, Typography } from 'antd'

import { Add } from 'components/UI/Button'
import { ReactComponent as EmptyData } from 'assets/images/empty-data.svg'
import styles from './empty.module.scss'

const { Title } = Typography

const Empty = injectIntl(({ title, description, appendDescription, onAdd, intl, name }) => {
  return (
    <AntdEmpty
      description={
        <div>
          <Title level={4} className={styles.emptyTitle}>
            {title}
          </Title>
          {onAdd === undefined && !!description && (
            <Title
              className={[
                styles.emptyDescription,
                appendDescription ? styles.hasAppendDescription : null,
              ].join(' ')}
              level={5}
            >
              {description}
            </Title>
          )}
          {appendDescription}
          {typeof onAdd === 'function' && (
            <>
              <Title
                className={[styles.emptyDescription, styles.hasAppendDescription].join(' ')}
                level={5}
              >
                {description ??
                  intl.formatMessage({ id: 'table.lets_create_your_first_x' }, { name })}
              </Title>

              <Add
                onClick={onAdd}
                title={name && `${intl.formatMessage({ id: 'button.add' })} ${name}`}
                className={styles.emptyAddButton}
              />
            </>
          )}
        </div>
      }
      image={<EmptyData className={styles.emptyImage} />}
      className={styles.empty}
    />
  )
})

export default Empty
