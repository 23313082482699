import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { UnorderedListOutlined } from '@ant-design/icons'

function ShowColumn({
  icon = <UnorderedListOutlined />,
  onClick = () => {},
  id = 'btn-show-column',
  ...props
}) {
  return <Button {...props} icon={icon} onClick={onClick} id={id} />
}

ShowColumn.propTypes = {
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
}

export default injectIntl(ShowColumn)
