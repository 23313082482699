import { message } from 'antd'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import { dislikeFaq, getFaqCategory, getFaqDetail, getFaqs, likeFaq } from 'utils/apis'

export const useFaqCategory = (options) => {
  return useQuery(
    ['faqCategory'],
    async () => {
      const {
        data: { data },
      } = await getFaqCategory()
      return data
    },
    {
      onError: (error) => {
        message.error((error && error.message) || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useFaqs = ({ payload = {}, options = {} }) => {
  return useQuery(
    ['faqs', payload],
    async () => {
      const {
        data: { data },
      } = await getFaqs(payload)
      return data
    },
    {
      onError: (error) => {
        message.error((error && error.message) || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useInfiniteFAQs = (payload, options = {}) => {
  return useInfiniteQuery(
    ['infinite/faqs', payload],
    async ({ pageParam = 0 }) => {
      let response
      try {
        const {
          data: { data },
        } = await getFaqs({ page: pageParam, ...payload })
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined
        }
        return lastPage.current_page < lastPage.last_page ? lastPage.current_page + 1 : undefined
      },
      onError: (error) => message.error(error.message || 'Failed to load data from server!'),
      keepPreviousData: true,
      ...options,
    },
  )
}

export const useFaqDetail = ({ id, options = {} }) => {
  return useQuery(
    ['faqDetail', id],
    async () => {
      const {
        data: { data },
      } = await getFaqDetail(id)
      return data
    },
    {
      onError: (error) => {
        message.error((error && error.message) || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useLikeFaq = ({ options = {} } = {}) => {
  const queryClient = useQueryClient()
  return useMutation((payload) => likeFaq(payload), {
    onSuccess: (response) => {
      queryClient.setQueryData(['faqDetail', `${response.data.data.id}`], () => response.data.data)
      message.success(response.data.message)
    },
    ...options,
  })
}

export const useDislikeFaq = ({ options = {} } = {}) => {
  const queryClient = useQueryClient()
  return useMutation((payload) => dislikeFaq(payload), {
    onSuccess: (response) => {
      queryClient.setQueryData(['faqDetail', `${response.data.data.id}`], () => response.data.data)
      message.success(response.data.message)
    },
    ...options,
  })
}
