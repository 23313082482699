import axios from 'axios'

import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinanceDeliverys = (params) => {
  return getOnce('/finance/deliveries', { params })
}

export const addFinanceDelivery = (data) => {
  return axios.post('/finance/deliveries', data)
}

export const editFinanceDelivery = (data) => {
  return axios.put(`/finance/deliveries/${data.id}`, data)
}

export const deleteOnAddFinanceDeliveryAttachment = (url) => {
  return axios.delete('/finance/deliveries/attachments', { data: { url } })
}

export const deleteOnEditFinanceDeliveryAttachment = (id, url) => {
  return axios.delete(`/finance/deliveries/${id}/attachments`, { data: { url } })
}

export const getFinanceDelivery = (id, params) => {
  return axios.get(`/finance/deliveries/${id}`, { params })
}

export const getFinanceDeliveryLog = (id, params) => {
  return axios.get(`/finance/deliveries/${id}/logs`, { params })
}

// export const changeFinanceDeliveryStatus = (id, data) => {
//   return axios.patch(`/finance/deliveries/${id}/status`, data)
// }

export const deleteFinanceDelivery = (id) => {
  return axios.delete(`/finance/deliveries/${id}`)
}

export const getFinanceDeliveryMerged = (params) => {
  return axios.get(`/finance/deliveries/merged`, { params })
}

export const getFinanceDeliveryPublicUrl = (id) => {
  return axios.get(`/finance/deliveries/${id}/publicUrl`)
}

export const exportFinanceDelivery = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/deliveries/export', { responseType: 'arraybuffer', params })
}

export const getFinanceDeliveryWaTemplate = (id) => {
  return axios.get(`/finance/deliveries/${id}/whatsapp`)
}

export const getFinanceDeliveryEmailTemplate = (id) => {
  return axios.get(`/finance/deliveries/${id}/email`)
}

export const sendFinanceDeliveryEmail = (id, data) => {
  return axios.post(`/finance/deliveries/${id}/email`, data)
}

export const getFinanceDeliverySmsTemplate = (id) => {
  return axios.get(`/finance/deliveries/${id}/sms`)
}

export const sendFinanceDeliverySms = (id, data) => {
  return axios.post(`/finance/deliveries/${id}/sms`, data)
}

export const inputMassDeleteFinanceDelivery = (data) => {
  return axios.post('/finance/deliveries/inputMassDelete', data)
}

export const executeMassDeleteFinanceDelivery = (data) => {
  return axios.delete('/finance/deliveries/executeMassDelete', { data })
}

export const getMassDownloadFinanceDelivery = (params) => {
  return axios.get('/finance/deliveries/massDownload', { params })
}

export const getMassDownloadLabelFinanceDelivery = (params) => {
  return axios.get('/finance/deliveries/massDownloadLabel', { params })
}

export const inputMassCreateFinanceDelivery = (data) => {
  return axios.post('/finance/deliveries/inputMassDelivery', data)
}

export const getMinTransDateFinanceDelivery = (data) => {
  return axios.post('/finance/deliveries/mass/minDate', data)
}

export const executeMassCreateFinanceDelivery = (data) => {
  return axios.post('/finance/deliveries/executeMassDelivery', data)
}
