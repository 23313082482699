import * as api from 'utils/apis/customColumn'
import * as actionTypes from 'redux/actionTypes'

export const setCustomColumns = (payload) => {
  return {
    type: actionTypes.SET_CUSTOM_COLUMNS,
    payload,
  }
}

export const addCustomColumnRedux = (payload) => {
  return {
    type: actionTypes.ADD_CUSTOM_COLUMN,
    payload,
  }
}

export const editCustomColumnRedux = (payload) => {
  return {
    type: actionTypes.EDIT_CUSTOM_COLUMN,
    payload,
  }
}

export const addCustomColumn = (payload) => {
  return (dispatch) => {
    return api.addCustomColumn(payload).then((response) => {
      dispatch(addCustomColumnRedux(response.data.data))
      return response
    })
  }
}

export const editCustomColumn = (payload) => {
  return (dispatch) => {
    return api.editCustomColumn(payload).then((response) => {
      dispatch(editCustomColumnRedux(response.data.data))
      return response
    })
  }
}

export const deleteCustomColumnRedux = (payload) => {
  return {
    type: actionTypes.DELETE_CUSTOM_COLUMN,
    payload,
  }
}

export const deleteCustomColumn = (payload) => {
  return (dispatch) => {
    return api.deleteCustomColumn(payload).then((response) => {
      dispatch(deleteCustomColumnRedux(payload.id))

      return response
    })
  }
}

export const moveCustomColumnRedux = (payload) => {
  return {
    type: actionTypes.MOVE_CUSTOM_COLUMN,
    payload,
  }
}

export const moveCustomColumn = (payload) => {
  return (dispatch) => {
    dispatch(moveCustomColumnRedux(payload))

    return api
      .moveCustomColumn(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveCustomColumnRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const changeStatusCustomColumnRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_CUSTOM_COLUMN,
    payload,
  }
}

export const changeStatusCustomColumn = (payload) => {
  return (dispatch) => {
    dispatch(changeStatusCustomColumnRedux(payload))

    if (payload.checked) {
      return api.activateCustomColumn(payload.id, payload.payload)
    }

    return api.deactivateCustomColumn(payload.id, payload.payload)
  }
}

export const addCustomColumnItemRedux = (payload) => {
  return {
    type: actionTypes.ADD_CUSTOM_COLUMN_ITEM,
    payload,
  }
}

export const editCustomColumnItemRedux = (payload) => {
  return {
    type: actionTypes.EDIT_CUSTOM_COLUMN_ITEM,
    payload,
  }
}

export const addCustomColumnItem = (payload) => {
  return (dispatch) => {
    return api.addCustomColumnItem(payload).then((response) => {
      dispatch(addCustomColumnItemRedux(response.data.data))
      return response
    })
  }
}

export const editCustomColumnItem = (payload) => {
  return (dispatch) => {
    return api.editCustomColumnItem(payload).then((response) => {
      dispatch(editCustomColumnItemRedux(response.data.data))
      return response
    })
  }
}

export const deleteCustomColumnItemRedux = (payload) => {
  return {
    type: actionTypes.DELETE_CUSTOM_COLUMN_ITEM,
    payload,
  }
}

export const deleteCustomColumnItem = (payload) => {
  return (dispatch) => {
    return api.deleteCustomColumnItem(payload).then((response) => {
      dispatch(deleteCustomColumnItemRedux(payload.id))

      return response
    })
  }
}

export const moveCustomColumnItemRedux = (payload) => {
  return {
    type: actionTypes.MOVE_CUSTOM_COLUMN_ITEM,
    payload,
  }
}

export const moveCustomColumnItem = (payload) => {
  return (dispatch) => {
    dispatch(moveCustomColumnItemRedux(payload))

    return api
      .moveCustomColumnItem(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveCustomColumnItemRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const changeStatusCustomColumnItemRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_CUSTOM_COLUMN_ITEM,
    payload,
  }
}

export const changeStatusCustomColumnItem = (payload) => {
  return (dispatch) => {
    dispatch(changeStatusCustomColumnItemRedux(payload))

    if (payload.checked) {
      return api.activateCustomColumnItem(payload.id, payload.payload)
    }

    return api.deactivateCustomColumnItem(payload.id, payload.payload)
  }
}
