import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from 'store'

import { changeLayoutSetting } from 'redux/settings/actions'
import { mobileViewSelector, tabViewSelector } from 'redux/selectors'

const sizes = {
  // <
  xs: 576,
  // >=
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

// Hook untuk handle responsive UI dari redux
const useResponsive = () => {
  const [screenSizes, setScreenSizes] = useState({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  })
  const dispatch = useDispatch()
  const isMobileView = useSelector(mobileViewSelector)
  const isTabView = useSelector(tabViewSelector)

  const isDesktopLayout = useMemo(() => !isMobileView && !isTabView, [isMobileView, isTabView])

  const setLayoutView = useCallback(
    (load = false) => {
      const windowWidth = global.window.innerWidth
      const currentMobileState = windowWidth < 768
      const prevMobileState = store.get('app.settings.isMobileView')
      const currentTabState = windowWidth >= 768 && windowWidth <= 992
      const prevTabState = store.get('app.settings.isTabView')

      if (currentMobileState !== prevMobileState || currentTabState !== prevTabState || load) {
        dispatch(
          changeLayoutSetting({
            isMobileView: currentMobileState,
            isTabView: currentTabState,
          }),
        )
      }
    },
    [dispatch],
  )

  const onResize = useCallback((event) => {
    const windowWidth = event ? event.target.innerWidth : window.innerWidth

    if (windowWidth < sizes.xs) {
      setScreenSizes({
        xs: true,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
      })
    } else if (windowWidth >= sizes.sm && windowWidth < sizes.md) {
      setScreenSizes({
        xs: false,
        sm: true,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
      })
    } else if (windowWidth >= sizes.md && windowWidth < sizes.lg) {
      setScreenSizes({
        xs: false,
        sm: false,
        md: true,
        lg: false,
        xl: false,
        xxl: false,
      })
    } else if (windowWidth >= sizes.lg && windowWidth < sizes.xl) {
      setScreenSizes({
        xs: false,
        sm: false,
        md: false,
        lg: true,
        xl: false,
        xxl: false,
      })
    } else if (windowWidth >= sizes.xl && windowWidth < sizes.xxl) {
      setScreenSizes({
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: true,
        xxl: false,
      })
    } else if (windowWidth >= sizes.xxl) {
      setScreenSizes({
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: true,
      })
    }
  }, [])

  useEffect(() => {
    onResize(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize, screenSizes])

  return { isMobileView, isTabView, isDesktopLayout, setLayoutView, screenSizes }
}

export default useResponsive
