import { message } from 'antd'
import { useQuery } from 'react-query'
import { getCommission, getCommissions, getCommissionTypes } from 'utils/apis'

export const useCommissions = ({ payload = {}, config = {} }) => {
  return useQuery(
    ['commissions', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getCommissions(payload)

        response = data
      } catch (error) {
        message.error((error && error.message) || 'Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
      ...config,
    },
  )
}

export const useCommission = ({ id, intl, config = {} }) => {
  return useQuery(
    ['commission', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getCommission(id)

        response = data
      } catch (error) {
        message.error(
          error?.data?.message ||
            intl.formatMessage({ id: 'salesCommission.cannot_get_commission' }),
        )
        // This condition is for when the commission is not found,
        // maybe in the future we can find the better way to tell that the data is not found
        if (error?.status) {
          response = {
            status: error?.status,
          }
        }
      }
      return response
    },
    {
      keepPreviousData: true,
      onError: () =>
        message.error(intl.formatMessage({ id: 'salesCommission.cannot_get_commission' })),
      ...config,
    },
  )
}

export const useCommissionTypes = ({ intl, config = {} }) => {
  return useQuery(
    ['commission-types'],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getCommissionTypes()

        response = data
      } catch (error) {
        message.error(intl.formatMessage({ id: 'salesCommission.cannot_get_commission_type' }))
      }
      return response
    },
    {
      keepPreviousData: true,
      onError: () =>
        message.error(intl.formatMessage({ id: 'salesCommission.cannot_get_commission_type' })),
      ...config,
    },
  )
}
