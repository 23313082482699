import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const HelpTooltip = ({ title, isMobileView, questionIconProps = {}, ...props }) => {
  return (
    <Tooltip trigger={['hover', 'click']} title={title} {...props}>
      <QuestionCircleOutlined style={isMobileView ? { fontSize: 20 } : {}} {...questionIconProps} />
    </Tooltip>
  )
}

HelpTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

export default React.memo(HelpTooltip)
