import * as actionTypes from 'redux/actionTypes'

export const setDefaultMessage = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_DEFAULT_MESSAGE,
    payload,
  })
}

export const editDefaultMessage = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_DEFAULT_MESSAGE,
    payload,
  })
}
