import Pusher from 'pusher-js'
import { generateBaseUri } from './helper'
import { getToken } from './cookies'

// Pusher.logToConsole = true;

const getAuthorizationHeader = () => {
  const token = getToken() // Mendapatkan token dari cookies
  return {
    Authorization: `Bearer ${token}`,
  }
}

export const createPusherInstance = () => {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    forceTLS: true,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    authEndpoint: `${generateBaseUri()}/broadcasting/auth`,
    auth: {
      headers: getAuthorizationHeader(),
    },
  })
  return pusher
}
