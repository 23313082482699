import * as actionTypes from 'redux/actionTypes'
import { updateObject } from 'redux/utility'

export default function salutationReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_SALUTATIONS:
      return updateObject(state, {
        salutations: action.payload,
      })
    default:
      return state
  }
}
