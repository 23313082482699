import * as actionTypes from 'redux/actionTypes'

export default function salutationReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.SET_REASONS:
      return action.payload
    default:
      return state
  }
}
