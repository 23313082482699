import React from 'react'
import { Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import QueueStats from 'components/apps/Notification/QueueStats'
import Timeline from 'containers/Notification/Timeline'
import { useResponsive } from 'utils/hooks'
import { darkModeSelector } from 'redux/selectors'
import styles from './style.module.scss'

const Timelines = ({ intl }) => {
  const [visible, setVisible] = React.useState(false)
  const darkMode = useSelector(darkModeSelector)

  const { isMobileView } = useResponsive()

  const visibleChangeHandler = (flag) => setVisible(flag)
  const hideHandler = () => setVisible(false)

  const stylePane = { height: 400, overflow: 'auto' }
  if (!isMobileView) {
    stylePane.width = 400
  }

  const menu = (
    <div
      className={`card cui__utils__shadow width-350 overflow-hidden border-0 ${
        darkMode ? 'bg-dark-3 text-light' : 'bg-light'
      }`}
    >
      <div style={stylePane}>
        <QueueStats enabled={visible} />
        <Timeline isMobileView={isMobileView} onHideParent={hideHandler} />
      </div>
    </div>
  )
  return (
    <Tooltip
      open={visible ? false : undefined}
      title={intl.formatMessage({ id: 'topBar.dataChangesTimeline' })}
    >
      <Dropdown
        dropdownRender={() => menu}
        trigger={['click']}
        placement="bottomLeft"
        open={visible}
        onOpenChange={visibleChangeHandler}
        destroyPopupOnHide
      >
        <div className={styles.dropdown}>
          <FontAwesomeIcon icon="clock" className={`${styles.icon}`} />
        </div>
      </Dropdown>
    </Tooltip>
  )
}

export default injectIntl(Timelines)
