import React, { useCallback, useMemo } from 'react'
import { Spin } from 'antd'
import { Bar, Chart as ChartJs, Doughnut, Line, Pie } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { currency } from 'utils/formatting'
import { mobileViewSelector } from 'redux/selectors'

const Chart = ({
  loading,
  type,
  datasets,
  labels,
  onRenderLabelsData,
  onRenderLabelsTooltips,
  onRenderYAxesTicks,
  height,
  legend = { position: 'bottom' },
  wrapperClassName = 'mt-5',
  scalesXAxesProps = {},
  scalesYAxesProps = {},
}) => {
  const isMobileView = useSelector(mobileViewSelector)

  const renderLabelsTooltips = useCallback(
    (tooltipItem) => {
      if (typeof onRenderLabelsTooltips === 'function') {
        return onRenderLabelsTooltips(tooltipItem)
      }
      return `${tooltipItem.dataset.label}: ${currency(tooltipItem.raw)}`
    },
    [onRenderLabelsTooltips],
  )

  const renderTicks = useCallback(
    (value) => {
      if (typeof onRenderYAxesTicks === 'function') return onRenderYAxesTicks(value)
      if (value) return currency(value)
      return value
    },
    [onRenderYAxesTicks],
  )

  const newScalesXAxesProps = useMemo(() => {
    return _.merge(scalesXAxesProps, {
      stacked: true,
    })
  }, [scalesXAxesProps])

  const newScalesYAxesProps = useMemo(() => {
    return _.merge(scalesYAxesProps, {
      ticks: {
        callback: renderTicks,
      },
    })
  }, [scalesYAxesProps, renderTicks])

  const ComponentChart = useMemo(() => {
    switch (type) {
      case 'pie':
        return Pie
      case 'doughnut':
        return Doughnut
      case 'bar':
        return Bar
      case 'line':
        return Line
      default:
        return ChartJs
    }
  }, [type])

  return (
    <Spin spinning={loading} wrapperClassName={wrapperClassName}>
      <ComponentChart
        data={{
          labels: typeof onRenderLabelsData === 'function' ? onRenderLabelsData() : labels,
          datasets,
        }}
        options={{
          responsive: true,
          plugins: {
            legend,
            tooltip: {
              mode: 'index',
              intersect: true,
              callbacks: {
                label: renderLabelsTooltips,
              },
            },
          },
          scales: {
            x: newScalesXAxesProps,
            y: newScalesYAxesProps,
          },
        }}
        height={height && isMobileView ? 300 : null}
      />
    </Spin>
  )
}

export default Chart
