const colors = [
  '#FF6384',
  '#FFCD56',
  '#4BC0C0',
  '#36A2EB',
  '#CB99C9',
  '#EE82EE',
  '#FF2C63',
  '#00ADB5',
  '#08D9D6',
  '#F9ED69',
  '#F08A5D',
  '#B83B5E',
  '#6A2C70',
  '#FF2E63',
  '#112D4E',
  '#F38181',
  '#95E1D3',
  '#3F72AF',
  '#A8D8EA',
  '#AA96DA',
  '#FCBAD3',
  '#364F6B',
  '#3FC1C9',
  '#FC5185',
  '#0F4C75',
  '#3282B8',
  '#AD8B73',
  '#CEAB93',
  '#FF9494',
  '#CCA8E9',
  '#7D5A50',
  '#B4846C',
  '#967E76',
  '#30E3CA',
  '#11999E',
  '#40514E',
  '#F67280',
  '#C06C84',
  '#6C5B7B',
  '#355C7D',
  '#B1B2FF',
  '#E84545',
  '#903749',
  '#0D7377',
  '#14FFEC',
  '#99DDCC',
  '#769FCD',
  '#7895B2',
  '#E23E57',
  '#88304E',
  '#FFCFDF',
  '#8675A9',
  '#FF9292',
  '#7D6E83',
  '#9E7676',
  '#815B5B',
  '#594545',
  '#00B8A9',
  '#F6416C',
  '#FFDE7D',
  '#99A799',
  '#52616B',
  '#A8E6CF',
  '#BE9FE1',
  '#EA5455',
  '#2D4059',
]

export default colors
