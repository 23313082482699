import axios from 'axios'

// export const getPurchaseInvoicePaymentDebitMemos = (params) => {
//   return axios.get('/finance/purchaseInvoicePaymentDebitMemos', { params })
// }

export const addPurchaseInvoicePaymentDebitMemo = (data) => {
  return axios.post('/finance/purchaseInvoicePaymentDebitMemos', data)
}

export const editPurchaseInvoicePaymentDebitMemo = (data) => {
  return axios.put(`/finance/purchaseInvoicePaymentDebitMemos/${data.id}`, data)
}

export const getPurchaseInvoicePaymentDebitMemo = (id) => {
  return axios.get(`/finance/purchaseInvoicePaymentDebitMemos/${id}`)
}

export const deletePurchaseInvoicePaymentDebitMemo = (id) => {
  return axios.delete(`/finance/purchaseInvoicePaymentDebitMemos/${id}`)
}

export const getPurchaseInvoicePaymentDebitMemoLogs = (id, params) => {
  return axios.get(`/finance/purchaseInvoicePaymentDebitMemos/${id}/logs`, { params })
}
