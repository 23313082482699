import _ from 'lodash'
import * as actionTypes from '../actionTypes'

const initialState = []

const addFinanceTransactionFee = (state, action) => {
  return [...state, action.payload]
}

const editFinanceTransactionFee = (state, action) => {
  const newState = _.cloneDeep(state)
  const fees = newState.map((item) => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  return fees
}

const deleteFinanceTransactionFee = (state, { payload }) => {
  const newState = _.cloneDeep(state)
  const fees = newState.filter((item) => item.id !== payload.id)
  return fees
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSACTION_FEES:
      return action.payload
    case actionTypes.ADD_TRANSACTION_FEE:
      return addFinanceTransactionFee(state, action)
    case actionTypes.EDIT_TRANSACTION_FEE:
      return editFinanceTransactionFee(state, action)
    case actionTypes.DELETE_TRANSACTION_FEE:
      return deleteFinanceTransactionFee(state, action)
    default:
      return state
  }
}

export default reducer
