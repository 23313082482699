import { Select } from 'antd'
import React, { useMemo } from 'react'
import { makeYears } from 'utils/helper'

const years = makeYears(2018)

const YearSelector = (props) => {
  const data = useMemo(() => {
    return years.map((year) => ({ value: year, label: year }))
  }, [])
  return <Select options={data} {...props} />
}

export default YearSelector
