import OneSignal from 'react-onesignal'

const init = ({ appId, userId, setInizialized }) => {
  OneSignal.init({
    appId,
    allowLocalhostAsSecureOrigin: true,
    notificationClickHandlerMatch: 'exact',
    notificationClickHandlerAction: 'navigate',
    serviceWorkerUpdaterPath: 'sdk/OneSignalSDKUpdaterWorker.js',
    serviceWorkerPath: 'sdk/OneSignalSDKWorker.js',
    promptOptions: {
      slidedown: {
        enabled: true,
        autoPrompt: true,
        timeDelay: 5,
        pageViews: 1,
      },
    },
  }).then(() => {
    setInizialized()
    OneSignal.Slidedown.promptPush()
    if (userId) {
      OneSignal.login(userId.toString())
    }

    // OneSignal.isPushNotificationsEnabled((isEnabled) => {
    //   if (isEnabled) console.log('Push notifications are enabled!')
    //   else console.log('Push notifications are not enabled yet.')
    // })

    // OneSignal.on('notificationDisplay', (event) => {
    //   console.warn('OneSignal notification displayed:', event)
    // })

    // OneSignal.on('notificationDismiss', (event) => {
    //   console.warn('OneSignal notification dismissed:', event)
    // })
  })

  return OneSignal
}

export default { init }
