import axios from 'axios'
import { stringify } from 'query-string'

const resource = 'options'

export const getOptions = () => {
  return axios.get(`/${resource}`)
}

export const updateOptions = (data) => {
  return axios.put(`/${resource}`, data)
}

export const getPropertyOptions = () => {
  return axios.get(`/${resource}/property`)
}

export const setLockDate = (data) => {
  return axios.put(`/${resource}/lock_date`, data)
}

export const isLockDateDeletable = () => {
  return axios.get('/options/lock_date/deletable')
}

export const deleteLockDate = () => {
  return axios.delete(`/${resource}/lock_date`)
}

export const onBoarding = (data) => {
  return axios.post(`/onboardings`, data)
}

export const sendEmailConfirmation = () => {
  return axios.post(`/${resource}/confirmationEmail/resend`)
}

export const confirmsEmail = (data) => {
  return axios.post(`/${resource}/confirmationEmail`, data)
}

export const getOption = (name) => {
  return axios.get(`/${resource}/${name}`)
}

export const getOptionNotification = () => {
  return axios.get(`/${resource}/notifications`)
}

export const editOptionNotification = (name, data) => {
  return axios.put(`/${resource}/notifications/${name}`, data)
}

export const getOptionsDueReminders = (payload) => {
  const query = stringify(payload)
  return axios.get(`/${resource}/dueReminders?${query}`)
}

export const updateOptionsDueReminders = (data) => {
  return axios.post(`/${resource}/dueReminders`, data)
}

export const getOptionsThemeColors = () => {
  return axios.get('/options/themeColors')
}

export const editOptionsThemeColors = (data) => {
  return axios.patch('/options/themeColors', data)
}

export const addSchedulerLockDate = (data) => {
  return axios.put(`/${resource}/scheduler_lock_date`, data)
}

export const getSchedulerLockDate = () => {
  return axios.get(`/${resource}/scheduler_lock_date`)
}

export const putSchedulerLockDateActive = (data) => {
  return axios.put(`/${resource}/scheduler_lock_date/active`, data)
}
