import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import {
  addFinanceWarehouseContactGroups,
  archiveFinanceWareHouse,
  deleteFinanceWareHouse,
  editFinanceWareHouse,
  executeImportFinanceWarehouseTransfer,
  getFinanceWareHouse,
  getFinanceWareHouses,
  getFinanceWareHouseTransfers,
  getWarehousesSummary,
  unarchiveFinanceWareHouse,
  uploadImportFinanceWarehouseTransfer,
} from 'utils/apis'

export const useFinanceWareHouseTransfers = (params = {}, options = {}) => {
  return useQuery(
    ['warehousesTransfer', params],
    async () => {
      let response

      try {
        const {
          data: { data },
        } = await getFinanceWareHouseTransfers(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useUploadImportFinanceWarehouseTransferMutation = () => {
  return useMutation(uploadImportFinanceWarehouseTransfer)
}

export const useExecuteImportFinanceWarehouseTransferMutation = () => {
  return useMutation(executeImportFinanceWarehouseTransfer)
}

export const useAddWarehouseContactGroupsMutation = () => {
  return useMutation((payload) => addFinanceWarehouseContactGroups(payload.id, payload.data))
}

export const useWarehousesSummary = ({ params = {}, options = {} } = {}) => {
  return useQuery(
    ['warehousesSummary', params],
    async () => {
      let response

      try {
        const {
          data: { data },
        } = await getWarehousesSummary(params)
        response = data
      } catch (error) {
        throw new Error(error)
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useWarehouses = ({ payload, options = {} }) => {
  return useQuery(
    ['warehouses', payload],
    async () => {
      let response

      try {
        const {
          data: { data },
        } = await getFinanceWareHouses(payload)
        response = data
      } catch (error) {
        throw new Error(error)
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}
export function useWarehouseById({ id, params = {}, options }) {
  return useQuery(
    ['warehouse', id, params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceWareHouse(id, params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    options,
  )
}

export const useUpdateWarehouse = () => {
  return useMutation((payload) => editFinanceWareHouse(payload))
}
export const useDeleteWarehouse = () => {
  return useMutation(({ id }) => deleteFinanceWareHouse(id))
}
export const useArchiveWarehouse = () => {
  return useMutation(({ id }) => archiveFinanceWareHouse(id))
}
export const useUnarchiveWarehouse = () => {
  return useMutation(({ id }) => unarchiveFinanceWareHouse(id))
}
